/** @format */

import React, { useState } from "react";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import * as yup from "yup";
import uploadIcon from "../../../../assets/images/upload.svg";
import { useForm } from "react-hook-form";
import themeApi from "../../../../apis/api/theme";
import mediaAPI from "../../../../apis/api/media";
import { reqGetThemes } from "../../../../reduxs/cms/action";
import { useDispatch } from "react-redux";

const filmValidationSchema = yup.object().shape({
  file: yup.mixed().required("File is a required field"),
  videoFile: yup.mixed().required("File is a required field"),
});

const AddLogo = ({
  setIsShow,
  theme,
  type = "",
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});

  const [isDragging, setIsDragging] = useState(false);

  const { watch, control } = useForm({
    defaultValues: {},
  });

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleClose = () => {
    setIsShow(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e, forVideo = false) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
  };

  const renderImagePreview = () => {
    if (files.length) {
      return URL.createObjectURL(files[0]);
    }
  };

  const saveLogo = async () => {
    try {
      const file = files[0];

      const uploaded = await mediaAPI.uploadMedia(file);
      if (uploaded?.message) {
        throw new Error(uploaded.message);
      }

      if (uploaded?.data) {
        const variable = {
            name: !type ? 'logo' : `logo-${type}`,
            value: uploaded?.data?.path,
            category: 'logo'
        }
        const res = await themeApi.updateTheme(theme.id, {
            cssVariables: [...theme?.cssVariables, variable],
          });

        if (res && res?.data) {
          toast.success("Add logo successfully!");
          dispatch(reqGetThemes());
          handleClose();
        }
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      } else {
        toast.error(err.message)
      }
    } finally {
        setIsShow(false);
    }
  };

  const onSave = () => {
    saveLogo();
  };


  return (
    <div className="p-[40px] max-h-[90vh]">
        <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
        <div>
            <div>
            <div className="flex justify-between flex-wrap">
                <h2 className="font-400 mb-4 text-xl font-bold text-black">
                    {type.charAt(0).toUpperCase() + type.slice(1) + ' Logo'}
                </h2>
            </div>
            </div>
            <div
            className={`relative min-h-[330px] flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${
                isDragging ? "bg-gray-200" : "bg-white"
            }`}
            style={{
                backgroundImage: 'url("/images/background-input.png")',
                backgroundSize: 'cover'
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            >
            {!isEmpty(files) && (
                <img
                className="absolute top-0 left-0 w-full img-fluid cursor-pointer h-[325px] object-cover"
                src={renderImagePreview()}
                alt=""
                />
            )}
            <input
                type="file"
                multiple
                className="hidden"
                id="file-upload"
                onChange={handleFileSelect}
                accept={".png, .webp"}
            />
            <label htmlFor="file-upload" className="cursor-pointer">
                <div className="text-center flex flex-col items-center">
                {isEmpty(files) && <p className="text-gray-600">
                    Drag & drop your files here or click to upload
                </p>}
                {!isEmpty(files) && (
                    <img
                    src={uploadIcon}
                    alt="Upload"
                    className="w-20 h-20 relative"
                    />
                )}
                {files.length > 0 && (
                    <ul className="mt-4">
                    {files.map((file, index) => (
                        <li key={index} className="text-sm text-gray-800">
                        {file.name}
                        </li>
                    ))}
                    </ul>
                )}
                </div>
            </label>
            </div>
            <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
            {errors?.file}
            </span>
        </div>
        <div
            className="font-poppins cursor-pointer text-[14px] font-[700] text-[#000] mt-[20px] px-[18px] py-[7px] uppercase border-solid border-[1px] border-[#000] w-full text-center"
            onClick={onSave}
        >
            SAVE
        </div>
        </div>
    </div>
  );
};

export default AddLogo;
