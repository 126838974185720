import React from 'react';
import { COMPONENTS_ENUM, SUB_COMPONENTS_TYPES_ENUM } from '../../../constants/modules';
import FilterCheckbox from './sub-components/filter-checkbox';

const FilterAvailability = (props) => {
  const {
    filterValue,
    onChange,
    visible = false,
    componentConfig = null,
    label = 'Filter Availability',
    order = 0,
  } = props;

  if (
    !visible ||
    !componentConfig?.subComponents?.some(c => c?.props?.visible)
  ) return null;

  const renderSubComponents = () => {
    const checkboxes = componentConfig?.subComponents
      ?.filter((sComp) => sComp?.props?.type === SUB_COMPONENTS_TYPES_ENUM.CHECKBOX)
      ?.sort((a, b) => a?.props?.order - b?.props?.order);

    return checkboxes?.map((item, key) => (
      <FilterCheckbox
        item={item}
        filterValues={filterValue}
        onChange={onChange}
        key={key}
        {...(item?.props || {})}
      />
    ))
  }

  return (
    <div className={`filter-group flex flex-col items-start self-stretch gap-[25px]`}>
      <h2 className="font-normal text-primary font-sans text-[14px] leading-[12px] tracking-[1.4px] uppercase mb-0">
        {label}
      </h2>
      <ul className='flex flex-col justify-content-between items-start flex-wrap gap-[5px] self-stretch pl-3'>
        {renderSubComponents()}
      </ul>
    </div>
  );
};
FilterAvailability.displayName = COMPONENTS_ENUM.FILTER_STATUS;

export default FilterAvailability;
