import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getModalDetail = (id, data) =>
  client.get(endPoint.MODAL_DETAIL.replace(":id", id), data);
const getListModal = (data) => client.get(endPoint.MODAL_LIST, data);
const updateModal = (id, data) =>
  defaultClient.put(endPoint.MODAL_DETAIL.replace(":id", id), data);

export default {
  getModalDetail,
  getListModal,
  updateModal,
};
