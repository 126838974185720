import React from "react";
import mediaAPI from "../../../../../apis/api/media";
import { toast } from "react-toastify";
import { Button } from "../../../components/commons";
import { cn } from "../../../../../helper/utils";

const UploadImage = ({ onUpload }) => {
  const [loading, setLoading] = React.useState(false);

  const handleFileChange = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const type = file?.type?.split("/")[1];

      const imageUrl = URL.createObjectURL(file);

      // Create an Image object to check the dimensions
      const img = new Image();
      img.src = imageUrl;

      img.onload = async () => {
        const { width, height } = img;

        // Check for a 2:1 ratio
        if (width / height === 2) {
          const uploaded = await mediaAPI.uploadMedia(file);

          if (uploaded?.message) {
            toast.error(uploaded.message);
          }

          uploaded?.data && onUpload(uploaded?.data?.path); // Proceed with upload if it's equirectangular
          setLoading(false);
        } else {
          toast.warn(
            "Please upload an equirectangular image with a 2:1 aspect ratio."
          );
          setLoading(false);
        }
      };
    }
  };

  return (
    <div className="w-full">
      <input
        type="file"
        onChange={handleFileChange}
        accept=".png, .webp"
        id="upload-input"
        className="hidden" // Tailwind class to hide the input
      />
      <Button isLoading={loading} className="w-full  p-0 " disabled={loading}>
        <label
          htmlFor={loading ? "upload-input-disabled" : "upload-input"}
          className={cn("block w-full text-center px-4 py-2 cursor-pointer", {
            "cursor-not-allowed w-auto": loading,
          })}
        >
          Add Panorama
        </label>
      </Button>
    </div>
  );
};

export default UploadImage;
