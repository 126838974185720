import { useState, useEffect, useMemo } from "react";
import TabContent from "./TabContent";
import {
  reqGetPlaylists,
  reqGetListFolder,
  actSetCMSMediaContent,
} from "../../../../reduxs/cms/action";
import { useDispatch, useSelector } from "react-redux";

function Tabs() {
  const [folderData, setFoldersData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.cms.playlist);
  const folders = useSelector((state) => state.cms.folders);

  const playlistConfig = useMemo(() => {
    return playlists?.data
      ?.map((playlist) => ({
        title: playlist.name,
        path: playlist.id,
        mediaType: playlist.type,
        type: "menu",
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [playlists]);

  useEffect(() => {
    if (folders) {
      const val = [
        {
          title: "Media",
          type: "submenu",
          subItems: [...folders],
        },
      ];
      setFoldersData(val);
    }
  }, [folders]);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const getListPlaylists = async () => {
    dispatch(reqGetPlaylists());
  };

  const getListFolder = async () => {
    dispatch(reqGetListFolder());
  };

  const onSuccess = () => {
    getListPlaylists();
  };

  useEffect(() => {
    getListPlaylists();
    getListFolder();
  }, []);

  //Handle auto select playlist first loading
  useEffect(() => {
    if (playlistConfig && playlistConfig.length > 0 && activeTab === 1) {
      dispatch(
        actSetCMSMediaContent(
          `${playlistConfig[0].path}_${playlistConfig[0].type}`
        )
      );
    } else if (folderData[0]?.subItems?.length > 0 && activeTab === 2) {
      dispatch(
        actSetCMSMediaContent(`media_${folderData[0].subItems[0].value}`)
      );
    }
  }, [playlistConfig, activeTab, folderData]);

  return (
    <div>
      {/* Tab buttons */}
      <div className="flex space-x-4 border-b justify-around">
        <button
          className={`py-2 px-4 ${
            activeTab === 1
              ? "text-blue-500 border-b-2 border-blue-500"
              : "text-gray-500"
          } font-semibold`}
          onClick={() => handleTabClick(1)}
        >
          Playlist
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === 2
              ? "text-blue-500 border-b-2 border-blue-500"
              : "text-gray-500"
          } font-semibold`}
          onClick={() => handleTabClick(2)}
        >
          Folder
        </button>
      </div>

      <div className="mt-4">
        {activeTab === 1 && (
          <TabContent
            name="Playlists"
            config={playlistConfig}
            addButton
            onSuccess={onSuccess}
          />
        )}
        {activeTab === 2 && (
          <TabContent
            folder
            name="Media"
            config={folderData}
            onSuccess={onSuccess}
          />
        )}
      </div>
    </div>
  );
}

export default Tabs;
