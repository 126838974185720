/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqGetListAmenities } from "../../../reduxs/cms/action";
import EditAmenityModal from "./EditAmenityModal";
import CmsPageHeader from "../components/PageHeader";
import dayjs from "dayjs";
import { getS3BEMediaUrl } from "../../../helper/media";
import { Photo } from "../../../components/svgs/icons";
import classNames from "classnames";
import Table from "./Table";

const CMSAmenities = () => {
  const dispatch = useDispatch();

  const [isSortAsc, toggleSortAsc] = useState(true);

  useEffect(() => {
    getListAmenities();
  }, [isSortAsc]);

  useEffect(() => {
    getListAmenities();
  }, []);

  const getListAmenities = () => {
    dispatch(
      reqGetListAmenities({
        sortBy: JSON.stringify({
          name: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  return (
    <div className="page-container" role="tabpanel">
      <CmsPageHeader title={"AMENITIES CMS"} />
      <div role="tabpanel" className="page-body">
        <Table />
      </div>
    </div>
  );
};
export default CMSAmenities;
