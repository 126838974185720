import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { cn } from "../../../../../helper/utils";
import { Button, Modal } from "../../../components/commons";
import TrashIcon from "../../../configuration/components/svgs/TrashIcon";
import playlistApi from "../../../../../apis/api/playlist";
import mediaApi from "../../../../../apis/api/media";
import { reqSetExploreModal } from "../../../../../reduxs/explore-modal/action";

const PanoramaList = ({ virtualTourData, setSceneIndex, sceneIndex }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { virtualId: playlistId, modalId } = useParams();

  const handleRemoveScene = async (sceneIndex, onSuccess) => {
    const newScenes = [...virtualTourData.scenes];

    setLoading(true);
    const res = await playlistApi.removeFilesFromPlayList(playlistId, {
      medias: [newScenes[sceneIndex].id],
    });

    if (res?.data) {
      await mediaApi.updateMedia(newScenes[sceneIndex].id, {
        data: {},
      });

      toast.success("Remove media successfully!");
      setSceneIndex(0);

      dispatch(reqSetExploreModal(modalId));
      setLoading(false);
      onSuccess && onSuccess();
    } else {
      toast.error("Remove media failed!");
      setLoading(false);
    }
  };

  return (
    <div className="mt-4">
      {!!virtualTourData?.scenes?.length && (
        <h3 className="font-medium mb-2">List Panorama</h3>
      )}
      {virtualTourData?.scenes?.map((scene, index) => (
        <div className="flex items-center gap-5" key={scene?.id}>
          <div
            onClick={() => setSceneIndex(index)}
            className={cn(
              "p-2 flex-1 rounded cursor-pointer hover:bg-gray-200 mb-2 transition-colors",
              {
                "bg-blue-100": index === sceneIndex,
              }
            )}
          >
            {scene.name}
          </div>
          <Modal
            maskClassName="bg-opacity-50"
            zIndex="z-[9998]"
            trigger={
              <Button variant="icon" className="text-red-500 p-0">
                <TrashIcon />
              </Button>
            }
            content={({ setIsShow }) => (
              <div>
                <h2 className="text-center text-lg pb-3 font-medium">
                  Are you sure you want to delete this scene ?
                </h2>
                <div className="flex gap-4 justify-center">
                  <Button variant="text" onClick={() => setIsShow(false)}>
                    Cancel
                  </Button>
                  <Button
                    isLoading={loading}
                    onClick={() =>
                      handleRemoveScene(index, () => setIsShow(false))
                    }
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default PanoramaList;
