/** @format */

import { cn } from "../../../../../helper/utils";

const HotspotBase = ({ bgImage, className, type, index }) => {
  const hotspotElement = document.createElement("div");

  hotspotElement.className = cn("w-16 h-16 cursor-pointer relative", className);
  hotspotElement.style.backgroundImage = `url(${bgImage})`;
  hotspotElement.style.backgroundSize = "cover";
  hotspotElement.id = `hotspot-${type}-${index}`;

  return hotspotElement;
};

export default HotspotBase;
