/** @format */

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { cn } from "../../../helper/utils";

const SearchIcon = forwardRef(function SearchIcon(
  { handleInputChange, customStyle },
  ref
) {
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const refSearch = useRef();

  useEffect(() => {
    !isOpenSearch && handleInputChange?.("");
  }, [isOpenSearch]);

  const handleOpenSearch = () => {
    setIsOpenSearch(!isOpenSearch);
  };

  useImperativeHandle(ref, () => ({
    closeSearch() {
      setIsOpenSearch(false);
      refSearch.current.value = "";
    },
  }));

  return (
    <div
      className={cn(
        "bg-black flex h-8 items-center cursor-pointer absolute shrink-0 gap-[10px] right-0 top-2 z-[999] transition-[0.5s] w-[35px] rounded-[45px_0_0_45px] shadow-[0_4px_5px_0_rgba(0,0,0,0.12)]",
        {
          "w-[300px]": isOpenSearch,
        },
        customStyle
      )}
    >
      <img
        className="h-full"
        src="/icons/icSearch.svg"
        alt=""
        onClick={handleOpenSearch}
      />
      <input
        ref={refSearch}
        className={`text-[#2D2927] text-left font-['Proxima_Nova'] text-sm font-normal leading-[150%] border border-black focus:border-black focus:ring-0 h-8 ${
          isOpenSearch ? "w-[90%]" : "w-0"
        }`}
        type="text"
        onChange={(e) => handleInputChange?.(e)}
      />
    </div>
  );
});

export default SearchIcon;
