import React from "react";
import FolderIcon from "../components/svgs/FolderIcon";
import { cn } from "../../../../helper/utils";

const TreeSideCanvas = ({ onSelect, pageState }) => {
  return (
    <div
      className={cn(
        "w-[300px] mx-3 my-1 border border-solid border-gray-300 p-2 rounded hover:bg-gray-300 cursor-pointer text-slate-700",
        {
          "bg-gray-300": pageState?.name === "canvas",
        }
      )}
      onClick={() =>
        onSelect({ name: "canvas", value: "canvas", visible: true })
      }
    >
      <div className="flex items-center gap-2">
        <div>
          <FolderIcon />
        </div>
        <p>3D Canvas</p>
      </div>
    </div>
  );
};

export default TreeSideCanvas;
