import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getSrc } from "../../utils";
import icDragDrop from "../../../../../assets/images/cms/icDragDrop.png";
import LoadingTable from "../../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../../configuration/components/Table/EmptyTable";
import galleryApi from "../../../../../apis/api/gallery";
import {
  refetchGetGallery,
  reqGetGallery,
} from "../../../../../reduxs/gallery/action";
import RightArrowIcon from "../../../../../components/svgs/RightArrowIcon";
import { Button, Modal } from "../../../components/commons";
import playlistApi from "../../../../../apis/api/playlist";
import {
  reqGetListFolder,
  reqGetListMedia,
  reqGetPlaylists,
} from "../../../../../reduxs/cms/action";
import { getS3BEMediaUrl } from "../../../../../helper/media";
import { getUploadParams, MEDIA_TYPE, PAGE_SIZE } from "../../../utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import defaultVideo from "../../../../../assets/images/cms/icVideo.webp";
import defaultImage from "../../../../../assets/images/cms/icImg.webp";
import { useDropzone } from "react-dropzone";
import playlist from "../../../../../apis/api/playlist";
import _ from "lodash";

// type Props = {
//     isLoadingTable: boolean;
//     gallery?: {
//         galleryId: string;
//         name: string;
//     };
//     medias: any;
//     playListRef?: any;
// }

const typeMapping = {
  images: "imagePlaylistOverride",
  floorplans: "floorplanPlaylistOverride",
  viewlines: "viewlinePlaylistOverride",
  "360image": "360ImagePlaylistOverride",
};

const MediasContent = (
  {
    isLoadingTable,
    gallery,
    medias,
    forUnit,
    forAmenity,
    unitId,
    override,
    playlistId,
    mediaType,
  },
  ref
) => {
  const dispatch = useDispatch();
  const { id: galleryId, playlist } = gallery || {};
  const [mediasState, setMediasState] = useState(medias);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [loadingCreateMedia, setLoadingCreateMedia] = useState(false);
  const assets = useSelector((state) => state.cms.folders) || [];

  useEffect(() => {
    if (!assets.length) {
      dispatch(reqGetListFolder());
    }
  }, [assets]);

  useEffect(() => {
    setMediasState(medias);
  }, [medias, override]);

  const onDragEnd = async (result) => {
    if (result && mediasState?.length > 1) {
      const indexDestination = result?.destination?.index;
      const indexSource = result?.source?.index;

      if (
        !result?.destination ||
        !result?.source ||
        indexDestination === indexSource
      )
        return;

      const newMedias = [...mediasState];
      const [reorderedItem] = newMedias.splice(indexSource, 1);
      newMedias.splice(indexDestination, 0, reorderedItem);
      setMediasState(newMedias);

      const playlistOverride = newMedias.map((item) => item.id);

      let res;
      if (forUnit || forAmenity) {
        res = await playlistApi.updateMediaPlaylist(playlistId, {
          medias: playlistOverride,
        });
      } else {
        res = await galleryApi.updatePlaylistForGallery({
          galleryId,
          playlistId: playlist?.id || playlistId,
          playlistOverride,
        });
      }

      if (res?.data) {
        toast.success("Update playlist successfully");
      } else {
        toast.error("Update playlist failed");
      }

      forUnit || forAmenity
        ? dispatch(reqGetPlaylists())
        : dispatch(refetchGetGallery(galleryId));
    }
  };

  //expose onDragEnd for parent component to trigger
  useImperativeHandle(ref, () => ({
    triggerDragEnd: onDragEnd,
  }));
  //--------

  const handleRemoveMedia = async (media, onRemoveSuccess) => {
    if (!media) return;

    setRemoveLoading(true);
    const res = await playlistApi.removeFilesFromPlayList(
      forUnit || forAmenity ? playlistId : playlist?.id,
      {
        medias: [media.id],
      }
    );

    if (res?.data) {
      toast.success("Remove media successfully!");

      onRemoveSuccess && onRemoveSuccess();
      forUnit || forAmenity
        ? dispatch(reqGetPlaylists())
        : dispatch(refetchGetGallery(galleryId));
    } else {
      toast.error("Remove media failed!");
    }

    setRemoveLoading(false);
  };

  const getMediaList = (currentPage = 1) => {
    const params = {
      "type[equal]": mediaType,
      meta: JSON.stringify({ page: currentPage, pageSize: PAGE_SIZE }),
      search: "",
    };
    dispatch(reqGetListMedia(_.omitBy(params, _.isEmpty)));
  };

  const onDrop = async (files) => {
    if (!!files?.length) {
      setLoadingCreateMedia(true);
      const params = await getUploadParams(
        files,
        mediaType,
        assets.find((asset) => asset.value === mediaType).label
      );

      if (params?.length) {
        let completed = 0;

        const newMedias = await Promise.all(
          params.map(async (param, index) => {
            const resMedia = await playlistApi.createMediaPlaylist(param);

            await playlistApi.addFilesToPlayList(
              forUnit || forAmenity ? playlistId : playlist?.id,
              {
                medias: [resMedia?.data?.id],
              }
            );

            completed++;

            return resMedia;
          })
        );
        toast.success("Add new content successfully!");

        const playlistOverride = [
          ...newMedias.map((item) => item.data.id),
          ...medias.map(({ id }) => id),
        ];

        if (forUnit || forAmenity) {
          await playlistApi.updateMediaPlaylist(playlistId, {
            medias: playlistOverride,
          });
        } else {
          await galleryApi.updatePlaylistForGallery({
            galleryId,
            playlistId: playlist?.id || playlistId,
            playlistOverride,
          });
        }

        forUnit || forAmenity
          ? dispatch(reqGetPlaylists())
          : dispatch(refetchGetGallery(galleryId));

        getMediaList();

        setLoadingCreateMedia(false);
        return;
      }
      setLoadingCreateMedia(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      {!isLoadingTable && (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Droppable droppableId="gallery-list">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {!!mediasState?.length &&
                  mediasState?.map((v, index) => {
                    return (
                      <Draggable key={v?.id} draggableId={v?.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            key={v?.id}
                            className={`flex items-center rounded gap-5 bg-[#e4ccfe] mb-2 px-2`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="flex-1">
                              <div className="flex gap-5 items-center">
                                <LazyLoadImage
                                  className="w-16 h-16 rounded-lg m-auto object-cover"
                                  alt={v?.name}
                                  src={getS3BEMediaUrl(v?.thumbnailPath)}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      v?.type === "videos"
                                        ? defaultVideo
                                        : defaultImage;
                                  }}
                                />
                                <div className="flex-1 min-w-0">
                                  <div>{v?.type}</div>
                                  <div className="break-words overflow-wrap-anywhere">
                                    {v?.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Modal
                              maskClassName="bg-opacity-50"
                              zIndex="z-[9999]"
                              trigger={
                                <div className="flex gap-5 items-center">
                                  <span className="underline text-sm">
                                    Remove
                                  </span>
                                  <RightArrowIcon />
                                  <img
                                    className="w-20 cursor-pointer"
                                    src={icDragDrop}
                                    alt=""
                                  />
                                </div>
                              }
                              content={({ setIsShow }) => (
                                <div>
                                  <p className="text-center text-xl">
                                    Do you want to remove this media from
                                    playlist ?
                                  </p>
                                  <div className="flex gap-4 items-center mt-5">
                                    <Button
                                      disabled={removeLoading}
                                      variant="text"
                                      className="flex-1"
                                      onClick={() => setIsShow(false)}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      isLoading={removeLoading}
                                      className="flex-1 bg-red-500 hover:bg-red-400"
                                      onClick={() =>
                                        handleRemoveMedia(v, () =>
                                          setIsShow(false)
                                        )
                                      }
                                    >
                                      Confirm
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {(!mediasState || !mediasState?.length) && (
                  <div className="flex justify-center items-center h-full">
                    <EmptyTable />
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {isDragActive && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-70">
              <p className="text-2xl text-black font-bold">
                Drop the files here ...
              </p>
            </div>
          )}
          {loadingCreateMedia && (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-gray-200 bg-opacity-70">
              <LoadingTable />
            </div>
          )}
        </div>
      )}

      {isLoadingTable && (
        <div className="flex justify-center items-center h-full">
          <LoadingTable />
        </div>
      )}
    </>
  );
};

export default forwardRef(MediasContent);
