import Select from "react-select";
import { toast } from "react-toastify";
import React, { useMemo, useState } from "react";
import configApi from '../../../../apis/api/config';
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleSolid, CheckCircleOutline, Edit } from "../../../../components/svgs/icons";
import classNames from "classnames";

const ConfigCard = ({ config, loadConfigs }) => {
  const dispatch = useDispatch();

  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const canvasProfiles = useSelector((state) => state.cms.canvasProfiles);
  const themes = useSelector((state) => state.cms.theme);

  const [editing, setEditing] = useState(false);
  const [selectedCanvasProfile, setSelectedCanvasProfile] = useState({
    value: config?.canvasProfile?.id,
    label: config?.canvasProfile?.name,
  });
  const [selectedTheme, setSelectedTheme] = useState({
    value: config?.theme?.id,
    label: config?.theme?.name,
  });

  const canvasOptions = useMemo(() => {
    return canvasProfiles?.map((p) => ({
      value: p.id,
      label: p.name,
    })) || [];
  }, [canvasProfiles]);

  const themeOptions = useMemo(() => {
    return themes?.map((t) => ({
      value: t.id,
      label: t.name,
    })) || [];
  }, [themes]);

  const reset = () => {
    setSelectedCanvasProfile({
      value: config?.canvasProfile?.id,
      label: config?.canvasProfile?.name,
    });
    setSelectedTheme({
      value: config?.theme?.id,
      label: config?.theme?.name,
    });
    setEditing(false);
  };

  const editConfig = async (id) => {
    try {
      const res = await configApi.updateConfig(id, {
        canvasProfile: selectedCanvasProfile?.value,
        theme: selectedTheme?.value,
      });
      if (res && res.data) {
        loadConfigs();
        toast.success('Showcase config updated');
        setEditing(false);
      }
    } catch (error) {
      console.log({ error });
      toast.error("Update fail");
      reset();
    }
  };

  const selectConfig = async (id) => {
    try {
      const res = await configApi.updateConfig(id, { isActive: true });
      if (res && res.data) {
        loadConfigs();
        toast.success("New config applied");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Apply config failed");
    }
  };

  return (
    <div className={classNames(
      `flex flex-col gap-4 mx-auto w-full 3xl:max-w-[600px] h-max border-4 bg-gray-50 rounded-2xl p-7`,
      editing ? 'border-black' : (activeConfig?.id === config?.id) ? 'border-green-200/70' : 'border-gray-200',
    )}>
      <div className="flex justify-between mb-2 font-bold text-xl">
        <span>{config.name}</span>
        <div className="flex gap-4">
          {
            !editing &&
            <div onClick={() => setEditing(!editing)}><Edit className="w-7 cursor-pointer" /></div>
          }
          {
            (activeConfig?.id === config?.id)
              ? <div><CheckCircleSolid className="w-7 text-green-500" /></div>
              : <div onClick={() => selectConfig(config?.id)}><CheckCircleOutline className="w-7 cursor-pointer" /></div>
          }
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-32 flex items-center font-normal">Canvas profile</div>
        <Select
          isDisabled={!editing}
          value={selectedCanvasProfile}
          className="w-[calc(100%_-_200px)] cursor-pointer"
          isSearchable={false}
          isClearable={false}
          menuPlacement="auto"
          options={canvasOptions}
          onChange={(e) => setSelectedCanvasProfile(e)}
        />
      </div>
      <div className="flex gap-4">
        <div className="w-32 flex items-center font-normal">Theme</div>
        <Select
          isDisabled={!editing}
          value={selectedTheme}
          className="w-[calc(100%_-_200px)] cursor-pointer"
          isSearchable={false}
          isClearable={false}
          menuPlacement="auto"
          options={themeOptions}
          onChange={(e) => setSelectedTheme(e)}
        />
      </div>
      {
        editing &&
        <div className="flex w-full justify-end gap-3">
          <button className="rounded-xl py-1 px-3 bg-gray-200" onClick={reset}>
            Cancel
          </button>
          <button className="rounded-xl py-1 px-3 bg-black text-white" onClick={() => editConfig(config?.id)}>
            Save
          </button>
        </div>
      }
    </div>
  );
};

export default ConfigCard;
