import {
  reqSetTransportOptions,
  reqSetIsShowExploreModal,
  reqSetActiveTransportOption,
} from '../../reduxs/home/action';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MODULES_ENUM, PAGES_ENUM } from '../../constants/modules';
import { reqSetExploreModal } from '../../reduxs/explore-modal/action';

const TransportOption = ({ handleClickTransportOptions }) => {
  const dispatch = useDispatch();

  const activeTpId = useSelector((state) => state.home.activeTransportOption);
  const transportOptions = useSelector((state) => state.home.transportOptions);

  useEffect(() => {
    dispatch(reqSetTransportOptions());
  }, []);

  const onClick = (transportOptionId) => {
    let newArray = [].slice();
    let newActiveTpIds = activeTpId.slice();
    if (newActiveTpIds.includes(transportOptionId)) {
      let index = newActiveTpIds.indexOf(transportOptionId);
      if (index !== -1) {
        newActiveTpIds.splice(index, 1);
      }
    } else {
      newActiveTpIds.push(transportOptionId);
    }

    let lastTPModal = null;
    let maxIndex = -1;

    transportOptions.forEach(tp => {
      if (newActiveTpIds.includes(tp.id)) {
        let needCheckedIndex = newActiveTpIds.indexOf(tp.id);

        (tp['3d_meshes'] || []).forEach(a => {
          if (!newArray.includes(a)) {
            newArray.push(a);
          }
        });

        if (needCheckedIndex > maxIndex) {
          maxIndex = needCheckedIndex;
          lastTPModal = tp.modal;
        }
      }
    });

    handleClickTransportOptions(newArray);
    dispatch(reqSetExploreModal(lastTPModal?.id || null));
    dispatch(reqSetIsShowExploreModal(newActiveTpIds.length > 0));
    dispatch(reqSetActiveTransportOption(newActiveTpIds));
  }

  const getActiveClass = (tp) => {
    return activeTpId.includes(tp.id) ? '!bg-secondary' : 'bg-primary';
  }

  const getActiveColor = (tp) => {
    return activeTpId.includes(tp.id) ? tp.activeColor : '';
  }

  const fillTransportOptions = () => {
    if (transportOptions.length % 2 !== 0) {
      return (
        <li>
          <div className='rounded-full border-2 border-transparent w-11 h-11 bg-transparent' />
        </li>
      )
    }
  }

  return (
    <div className="bg-transparent absolute z-10 top-[100px] left-8 text-white w-fit overflow-y-auto h-[calc(100vh_-_200px)]">
      <div className="wrap-list-transport">
        <h4 className="text-primary text-sm uppercase mb-7">
          Transport
          <br />
          Options
        </h4>
        <div className="p-0">
          <ul className="p-0 list-none columns-2 gap-x-7">
            {
              transportOptions.map(tp =>
                <li key={`transport-${tp.id}`} className='mb-5'>
                  <div
                    className={`rounded-full border-2 border-primary w-11 h-11 transport-item-${tp.name} ${getActiveClass(tp)}`}
                    style={{
                      backgroundColor: getActiveColor(tp),
                      // borderColor: getActiveColor(tp)
                    }}>
                    <span onClick={() => onClick(tp.id)} >
                      <svg
                        width="47"
                        height="47"
                        viewBox="0 0 47 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='max-w-full max-h-full cursor-pointer'
                        dangerouslySetInnerHTML={{ __html: tp.svgPath }}
                      />
                    </span>
                  </div>
                </li>
              )
            }
            {fillTransportOptions()}
          </ul>
        </div>
      </div>
    </div>
  );
};
TransportOption.displayName = MODULES_ENUM.TRANSPORT_OPTIONS;
TransportOption.pageGroup = PAGES_ENUM.INTERACTIVE_3D;

export default TransportOption;
