import React, { useEffect, useMemo, useState } from "react";
import themeApi from "../../../../apis/api/theme";
import configApi from "../../../../apis/api/config";
import { toast } from "react-toastify";
import EditableItem from "./EditableItem";
import { useDispatch, useSelector } from "react-redux";
import { reqGetActiveConfig, reqGetThemes } from "../../../../reduxs/cms/action";
import { Modal, Button } from "../../components/commons";
import PlusIcon from "../components/svgs/PlusIcon";
import AddModal from "./AddModal";
import { MapStyleInput } from "./MapStyleInput";
import Switch from "../components/switch";
import AddLogo from "./AddLogo";

function organizeData(variables) {
  const organizedData = {};

  variables?.forEach(({ name, value, category, _id }) => {
    if (!organizedData[category]) {
      organizedData[category] = [];
    }
    organizedData[category].push({ name, value, _id });
  });

  return organizedData;
}

const StylesConfiguration = ({ name, disabled = false }) => {
  const dispatch = useDispatch();
  const themes = useSelector((state) => state.cms.theme);
  const activeConfig = useSelector((state) => state.cms.activeConfig);

  const theme = useMemo(
    () => themes?.find((theme) => theme.name === name.toLowerCase()) || {},
    [themes, name]
  );
  const isActive = useMemo(
    () => activeConfig?.theme?.id === theme?.id,
    [theme, activeConfig]
  );

  const loadConfig = async () => {
    dispatch(reqGetActiveConfig());
  };

  const loadThemes = async () => {
    dispatch(reqGetThemes());
  };
  useEffect(() => {
    loadThemes();
    loadConfig();
  }, [name]);

  const data = useMemo(() => organizeData(theme?.cssVariables), [theme]);

  const onThemeActive = async (e) => {
    try {
      const res = await themeApi.updateTheme(theme.id, {
        isActive: !!e?.target?.checked,
      });
      toast.success("Applied theme successfully");
      if (res && res.data) {
        loadThemes();
      }
    } catch (error) {
      console.log({ error });
      toast.error("Applied theme failed");
    }
  };

  const applyTheme = async () => {
    try {
      const res = await configApi.updateConfig(activeConfig?.id, { theme: theme.id });
      toast.success("Applied theme successfully");
      if (res && res.data) {
        loadConfig();
      }
    } catch (error) {
      console.log({ error });
      toast.error("Applied theme failed");
    }
  };

  const onAdd = async (variable) => {
    try {
      const res = await themeApi.updateTheme(theme.id, {
        cssVariables: [...theme?.cssVariables, variable],
      });
      if (res && res.data) {
        loadThemes();
        toast.success("Add variable successfully");
        return;
      }
      throw Error("Add variable failed");
    } catch (error) {
      console.log({ error });
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div className="w-full bg-gray-100">
        {/* Table header */}
        <div className="flex justify-between font-bold">
          <div className="w-[50%] p-4 border-r border-gray-300">Name</div>
          <div className="w-[50%] p-4 flex items-center gap-4">
            {name.toUpperCase()}
            <Switch
              onChange={(e) => onThemeActive(e)}
              defaultChecked={theme?.isActive}
              checked={theme?.isActive}
            />
            <button
              onClick={applyTheme}
              className={`ml-4 border border-gray-300 px-2 py-1 rounded-md text-sm ${
                isActive
                  ? "bg-gray-100 text-gray-500"
                  : "bg-gray-600 text-white"
              }`}
              disabled={isActive}
            >
              {isActive ? "Applied" : "Choose this theme"}
            </button>
          </div>
        </div>

        {Object.keys(data).map((category) => (
          <div key={category}>
            {/* Category heading */}
            <div className="bg-gray-600 text-white p-4 font-bold">
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </div>
            <div className="bg-gray-100">
              {data[category].map((item, idx) => (
                <EditableItem
                  key={idx}
                  item={item}
                  themeId={theme.id}
                  onSuccess={loadThemes}
                  disabled={disabled}
                  theme={theme}
                />
              ))}
            </div>
          </div>
        ))}
        <div className="bg-gray-600 text-white p-4 font-bold">Map Style</div>
        <div className="bg-gray-100">
          <MapStyleInput
            key={theme.id}
            name={"JSON map style"}
            defaultValue={JSON.stringify(theme.mapStyles)}
            onSave={async (value) => {
              try {
                try {
                  JSON.parse(value);
                } catch (error) {
                  toast.error("Please input correct JSON value for Map Style");
                }
                const res = await themeApi.updateTheme(theme.id, {
                  mapStyles: JSON.parse(value),
                });
                if (!res?.data) {
                  throw new Error("Update failed");
                }
                toast.success("Update Map Style successfully");
              } catch (error) {
                toast.error("Update Map Style failed");
              }
            }}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          trigger={
            <Button
              className="bg-white text-black py-1 hover:bg-white hover:text-black mt-2 pl-1.5"
              icon={<PlusIcon />}
            >
              <span className="font-medium text-sm">Add variables</span>
            </Button>
          }
          content={({ setIsShow }) => (
            <AddModal setIsShow={setIsShow} onAdd={onAdd} />
          )}
        />
        {!data.logo?.find(it => it.name === 'logo') && <Modal
          panelClassName="w-[700px]"
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          trigger={
            <Button
              className="bg-white text-black py-1 hover:bg-white hover:text-black mt-2 pl-1.5"
              icon={<PlusIcon />}
            >
              <span className="font-medium text-sm">Add logo</span>
            </Button>
          }
          content={({ setIsShow }) => (
            <AddLogo setIsShow={setIsShow} theme={theme}/>
          )}
        />}
        {!data.logo?.find(it => it.name === 'logo-cms') && <Modal
          panelClassName="w-[700px]"
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          trigger={
            <Button
              className="bg-white text-black py-1 hover:bg-white hover:text-black mt-2 pl-1.5"
              icon={<PlusIcon />}
            >
              <span className="font-medium text-sm">Add CMS logo</span>
            </Button>
          }
          content={({ setIsShow }) => (
            <AddLogo setIsShow={setIsShow} theme={theme} type="cms"/>
          )}
        />}
        {!data.logo?.find(it => it.name === 'logo-favicon') && <Modal
          panelClassName="w-[700px]"
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          trigger={
            <Button
              className="bg-white text-black py-1 hover:bg-white hover:text-black mt-2 pl-1.5"
              icon={<PlusIcon />}
            >
              <span className="font-medium text-sm">Add favicon logo</span>
            </Button>
          }
          content={({ setIsShow }) => (
            <AddLogo setIsShow={setIsShow} theme={theme} type="favicon"/>
          )}
        />}
      </div>
    </div>
  );
};

export default StylesConfiguration;
