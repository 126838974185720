import React, { useEffect, useRef, useState } from "react";
import Marzipano from "marzipano";

import Info from "../commons/Hotspot/Info";
import Link from "../commons/Hotspot/Link";
import { renderInfoHotspots, renderLinkHotspots } from "../utils";

const PanoramaViewer = ({ data }) => {
  const viewerRef = useRef(null);
  const sceneRef = useRef(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);

  const scenes = data.scenes;
  const { levels, url, initialViewParameters, linkHotspots, infoHotspots } =
    scenes[currentSceneIndex];

  // Initialize viewer
  useEffect(() => {
    if (!data) return;

    const viewerElement = document.getElementById("panorama");
    const viewer = new Marzipano.Viewer(viewerElement, {
      controls: { mouseViewMode: "drag" },
    });

    const geometry = new Marzipano.EquirectGeometry(levels);
    const source = Marzipano.ImageUrlSource.fromString(url);
    const view = new Marzipano.RectilinearView(initialViewParameters);

    const scene = viewer.createScene({ source, geometry, view });
    scene.switchTo({ transitionDuration: 1000 });

    viewerRef.current = viewer;
    sceneRef.current = scene;

    // Autorotation
    if (data.settings?.autorotateEnabled) {
      const currentPitch = viewerRef.current.view().pitch();
      const currentFov = viewerRef.current.view().fov();

      const autorotate = new Marzipano.autorotate({
        yawSpeed: -0.1, // speed value
        targetPitch: currentPitch || 0,
        targetFov: currentFov || Math.PI / 2,
      });
      viewer.startMovement(autorotate); // start autorotation

      // Handle autorotation when mouse up
      const handleMouseUp = () => {
        if (data.settings?.autorotateEnabled) {
          viewer.startMovement(autorotate);
        }
      };

      viewerElement.addEventListener("mouseup", handleMouseUp);

      return () => {
        viewer.destroy();
        viewerElement.removeEventListener("mouseup", handleMouseUp);
      };
    }

    return () => {
      viewer.destroy();
    };
  }, [data, currentSceneIndex]);

  // Add hotspots
  useEffect(() => {
    if (sceneRef.current) {
      // Destroy old hotspots
      sceneRef.current
        .hotspotContainer()
        .listHotspots()
        .forEach((hotspot) => {
          sceneRef.current.hotspotContainer().destroyHotspot(hotspot);
        });

      // Add infoHotspots
      renderInfoHotspots({ infoHotspots, sceneRef });

      // Add linkHotspots
      renderLinkHotspots({
        linkHotspots,
        sceneRef,
        isView: true,
        onClick: (data) => {
          const indexScene = scenes.findIndex(
            (scene) => scene.id === data.targetScene
          );

          if (indexScene === -1) return;

          setCurrentSceneIndex(indexScene);
        },
      });
    }
  }, [infoHotspots, linkHotspots]);

  return (
    <div className="w-full h-screen relative">
      <div id="panorama" className="w-full h-full absolute" />
    </div>
  );
};

export default PanoramaViewer;
