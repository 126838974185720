/** @format */

import React, { useEffect } from "react";

import PlaylistContent from "./PlaylistContent";
import AssetContent from "./AssetContent";
import { useDispatch, useSelector } from "react-redux";
import { reqGetPlaylists } from "../../../../reduxs/cms/action";
import { DragDropContext } from "@hello-pangea/dnd";
import playlist from "../../../../apis/api/playlist";
import { toast } from "react-toastify";
import { reqGetGallery } from "../../../../reduxs/gallery/action";
import playlistApi from "../../../../apis/api/playlist";
import galleryApi from "../../../../apis/api/gallery";
import _ from "lodash";
import mediaApi from "../../../../apis/api/media";

const UpsertGalleryPlaylist = (props) => {
  const { mediaType, playlistId, forUnit, forAmenity } = props;
  const dispatch = useDispatch();
  const playListRef = React.useRef(null);

  const mediaState = useSelector((state) => state.cms.mediaState);

  const galleryId = props?.gallery?.galleryId;

  const getPlaylists = () => dispatch(reqGetPlaylists());

  useEffect(() => {
    mediaType && getPlaylists();
  }, [mediaType]);

  const onDragEnd = async (result) => {
    //Change order media in playList
    if (
      result?.source?.droppableId === "gallery-list" &&
      result?.destination?.droppableId === "gallery-list"
    ) {
      playListRef.current && (await playListRef.current.triggerDragEnd(result));
    }

    //add media to playList
    if (
      result?.source?.droppableId === "asset-content" &&
      result?.destination?.droppableId === "gallery-list"
    ) {
      const idMedia = result?.draggableId.split("_")[0];

      if ((galleryId || forUnit || forAmenity) && playlistId) {
        const res = await playlist.addFilesToPlayList(playlistId, {
          medias: [idMedia],
          position: result.destination.index,
        });

        const playlistOverride = mediaState
          .map((media) => media.id)
          .toSpliced(result.destination.index, 0, idMedia);

        galleryId &&
          (await galleryApi.updatePlaylistForGallery({
            galleryId,
            playlistOverride,
          }));

        if (res?.data) {
          toast.success("Add new media successfully!");
          forUnit || forAmenity
            ? getPlaylists()
            : dispatch(reqGetGallery(galleryId));

          forAmenity &&
            (await mediaApi.updateMedia(idMedia, {
              data: {},
            }));
        } else {
          toast.error("Add media failed!");
        }
      } else {
        toast.warn("Please select playlist first");
      }
    }

    //remove media from playList
    if (
      result?.source?.droppableId === "gallery-list" &&
      result?.destination?.droppableId === "asset-content"
    ) {
      const idMedia = result?.draggableId;
      const res = await playlistApi.removeFilesFromPlayList(playlistId, {
        medias: [idMedia],
      });

      if (res?.data) {
        toast.success("Remove media successfully!");
        forUnit || forAmenity
          ? getPlaylists()
          : dispatch(reqGetGallery(galleryId));
      } else {
        toast.error("Remove media failed!");
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="max-h-[85vh] gap-5 flex flex-row ">
        <div className="h-[80vh]  border-solid flex-1 bg-white rounded-[20px] p-5 relative overflow-hidden">
          <PlaylistContent {...props} ref={playListRef} />
        </div>
        <div className="h-[80vh] w-[5px] bg-[rgb(227,227,227)]" />
        <div className="h-[80vh] flex-1 bg-white rounded-[20px] p-5 relative overflow-hidden ">
          <AssetContent {...props} />
        </div>
      </div>
    </DragDropContext>
  );
};

export default UpsertGalleryPlaylist;
