import _ from 'lodash';
import { debounce } from 'lodash';
import SideNav from '../../sidenav';
import UnitFilterFooter from './footer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import socket from '../../../helper/socket';
import UnitList from '../components/unit-list';
import React, { useEffect, useRef } from 'react';
import FilterLotSize from '../components/filter-size';
import FilterPool from '../components/filter-pool';
import FilterPrice from '../components/filter-price';
import { CSSTransition } from "react-transition-group";
import FilterBedroom from '../components/filter-bedroom';
import { FEATURES_ENUM } from '../../../constants/modules';
import FilterBathroom from '../components/filter-bathroom';
import FilterAvailability from '../components/filter-availability';
import * as unitExploreAct from '../../../reduxs/unit-explore/action';
import { WEBSOCKET_CHANNEL, ACTION_NAME } from '../../../constants/options';
import FilterExteriorSize from '../components/filter-size-exterior';
import FilterInteriorSize from '../components/filter-size-interior';

const UnitFilter = (props) => {
  const {
    visible = false,
    featureConfig = null,
    isPresentation = false,
    isTransparent = false,
    handleUnitClick,
  } = props;

  const dispatch = useDispatch();

  const pageRef = useRef(null);

  // unit
  const units = useSelector((state) => state.unitExplore.units);
  const isShowUnitList = useSelector((state) => state.unitExplore.isShowUnitList);
  // filters
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const filterUnitBedroom = useSelector((state) => state.unitExplore.filterUnitBedroom);
  const filterUnitBathroom = useSelector((state) => state.unitExplore.filterUnitBathroom);
  const filterUnitPrice = useSelector((state) => state.unitExplore.filterUnitPrice);
  const filterUnitLotSize = useSelector((state) => state.unitExplore.filterUnitLotSize);
  const filterUnitExteriorSize = useSelector((state) => state.unitExplore.filterUnitExteriorSize);
  const filterUnitInteriorSize = useSelector((state) => state.unitExplore.filterUnitInteriorSize);
  const filterUnitAvailability = useSelector((state) => state.unitExplore.filterUnitAvailability);
  const filterUnitPool = useSelector((state) => state.unitExplore.filterUnitPool);
  const filterUnitAspect = useSelector((state) => state.unitExplore.filterUnitAspect);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  useEffect(() => {
    const handleFilterUnit = debounce(() => {
      if (!units && !units.length) return;
      let filterUnits = [...units];
      filterUnits = filterUnits.filter((unit) => {
        if (filterUnitAspect && unit.aspect != filterUnitAspect.value) return false

        if (filterUnitBedroom.length) {
          const bedroomValue = filterUnitBedroom.map(x => String(x.value))
          if (!bedroomValue.includes(String(unit.bedrooms))) return false
        }

        if (filterUnitBathroom.length) {
          const bathroomValue = filterUnitBathroom.map(x => String(x.value))
          if (!bathroomValue.includes(String(unit.bathrooms))) return false
        }

        if (filterUnitAvailability.length) {
          const availabilityValue = filterUnitAvailability.map(x => x.value)
          if (!availabilityValue.includes(unit.availabilityStatus)) return false
        }

        if (filterUnitPrice) {
          if (filterUnitPrice.min && unit.price < filterUnitPrice.min) return false
          if (filterUnitPrice.max && unit.price > filterUnitPrice.max) return false
        }

        if (filterUnitLotSize) {
          if (filterUnitLotSize.min && unit.areaSqm < filterUnitLotSize.min) return false
          if (filterUnitLotSize.max && unit.areaSqm > filterUnitLotSize.max) return false
        }

        if (filterUnitExteriorSize) {
          if (filterUnitExteriorSize.min && unit.exterior < filterUnitExteriorSize.min) return false
          if (filterUnitExteriorSize.max && unit.exterior > filterUnitExteriorSize.max) return false
        }

        if (filterUnitInteriorSize) {
          if (filterUnitInteriorSize.min && unit.interier < filterUnitInteriorSize.min) return false
          if (filterUnitInteriorSize.max && unit.interier > filterUnitInteriorSize.max) return false
        }

        if (filterUnitPool !== undefined) {
          return String(unit.hasPool) === filterUnitPool
        }

        return unit
      })
      dispatch(unitExploreAct.reqSetFilteredUnit(filterUnits))
    }, 200);

    handleFilterUnit();
  }, [
    filterUnitAspect,
    filterUnitBedroom,
    filterUnitBathroom,
    filterUnitPrice,
    filterUnitLotSize,
    filterUnitExteriorSize,
    filterUnitInteriorSize,
    filterUnitAvailability,
    filterUnitPool,
    units,
  ]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_UNIT_FILTER) {
      handleApplyFilter(content.data);
    } else if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };

  const handleApplyFilter = debounce((data) => {
    const filterName = data.name;
    const item = data?.item;

    switch (filterName) {
      case 'FILTER_BEDROOM':
        onChangeBedroom(data.checked, data.item, data.filterUnitBedroom);
        break;
      case 'FILTER_BATHROOM':
        onChangeBathroom(data.checked, data.item, data.filterUnitBathroom);
        break;
      case 'FILTER_UNIT_POOL':
        onChangeUnitPool(data.value, data.filterUnitPool);
        break;
      case 'FILTER_PRICE':
        onChangePrice(data.price);
        break;
      case 'FILTER_LOT_SIZE':
        onChangeLotSize(data.filterLotSize);
        break;
      case 'FILTER_EXTERIOR_SIZE':
        onChangeExteriorSize(data.filterLotSize);
        break;
      case 'FILTER_INTERIOR_SIZE':
        onChangeInteriorSize(data.filterLotSize);
        break;
      case 'FILTER_STATUS':
        onChangeAvailability(data.checked, item, data.filterUnitAvailability);
        break;
      default:
        break;
    }
  }, 300);

  const onChangeBedroom = (checked, item, filterUnitBedroom) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item,
        checked,
        filterUnitBedroom,
        name: 'FILTER_BEDROOM',
      });
    }
    if (_.find(filterUnitBedroom, (el) => el.id === item.id)) {
      const newBedRoom = filterUnitBedroom.filter((i) => i.id !== item.id);
      dispatch(unitExploreAct.reqFilterUnitBedroom(newBedRoom));
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitBedroom([...filterUnitBedroom, item])
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onChangeBathroom = (checked, item, filterUnitBathroom) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item,
        checked,
        filterUnitBathroom,
        name: 'FILTER_BATHROOM',
      });
    }
    if (_.find(filterUnitBathroom, (el) => el.id === item.id)) {
      const newBathroom = filterUnitBathroom.filter((i) => i.id !== item.id);
      dispatch(unitExploreAct.reqFilterUnitBathroom(newBathroom));
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitBathroom([...filterUnitBathroom, item])
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onChangePrice = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        price: data,
        name: 'FILTER_PRICE',
      });
    }
    dispatch(unitExploreAct.reqFilterUnitPrice(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeLotSize = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        filterLotSize: data,
        name: 'FILTER_LOT_SIZE',
      });
    }
    dispatch(unitExploreAct.reqFilterUnitLotSize(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeExteriorSize = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        filterLotSize: data,
        name: 'FILTER_EXTERIOR_SIZE',
      });
    }
    dispatch(unitExploreAct.reqFilterUnitExteriorSize(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeInteriorSize = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        filterLotSize: data,
        name: 'FILTER_INTERIOR_SIZE',
      });
    }
    dispatch(unitExploreAct.reqFilterUnitInteriorSize(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeUnitPool = (value, filterUnitPool) => {
    let newVal = value === filterUnitPool ? undefined : value;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        value: newVal,
        filterUnitPool: filterUnitPool,
        name: 'FILTER_UNIT_POOL',
      });
    }
    if (newVal !== undefined) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
    dispatch(unitExploreAct.reqFilterUnitPool(newVal));
  };

  const onChangeAvailability = (checked, item, filterUnitAvailability) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitAvailability,
        checked,
        name: 'FILTER_STATUS',
      });
    }
    if (checked) {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability([
          ...filterUnitAvailability,
          item,
        ])
      );
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability(
          filterUnitAvailability.filter((i) => i.id !== item.id)
        )
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  if (
    !visible ||
    !featureConfig?.components?.some(c => c?.props?.visible) ||
    isPresentation
  ) return null;  // BN-1775

  const renderFilterComponents = () => {
    const filterAvailabilityComp = featureConfig?.components?.find((c) => c.name === FilterAvailability.displayName);
    const filterPriceComp = featureConfig?.components?.find((c) => c.name === FilterPrice.displayName);
    const filterSizeComp = featureConfig?.components?.find((c) => c.name === FilterLotSize.displayName);
    const filterExSizeComp = featureConfig?.components?.find((c) => c.name === FilterExteriorSize.displayName);
    const filterInSizeComp = featureConfig?.components?.find((c) => c.name === FilterInteriorSize.displayName);
    const filterBedroomComp = featureConfig?.components?.find((c) => c.name === FilterBedroom.displayName);
    const filterBathroomComp = featureConfig?.components?.find((c) => c.name === FilterBathroom.displayName);
    const filterPoolComp = featureConfig?.components?.find((c) => c.name === FilterPool.displayName);

    return <>
      {
        filterAvailabilityComp &&
        <FilterAvailability
          {...(filterAvailabilityComp?.props || {})}
          filterValue={filterUnitAvailability}
          onChange={onChangeAvailability}
          componentConfig={filterAvailabilityComp}
        />
      }
      {
        filterPriceComp &&
        <FilterPrice
          {...(filterPriceComp?.props || {})}
          filterValue={filterUnitPrice}
          onChange={onChangePrice}
          componentConfig={filterPriceComp}
        />
      }
      {
        filterSizeComp &&
        <FilterLotSize
          {...(filterSizeComp?.props || {})}
          filterValue={filterUnitLotSize}
          onChange={onChangeLotSize}
          componentConfig={filterSizeComp}
        />
      }
      {
        filterExSizeComp &&
        <FilterExteriorSize
          {...(filterExSizeComp?.props || {})}
          filterValue={filterUnitExteriorSize}
          onChange={onChangeExteriorSize}
          componentConfig={filterExSizeComp}
        />
      }
      {
        filterInSizeComp &&
        <FilterInteriorSize
          {...(filterInSizeComp?.props || {})}
          filterValue={filterUnitInteriorSize}
          onChange={onChangeInteriorSize}
          componentConfig={filterInSizeComp}
        />
      }
      {
        filterBedroomComp &&
        <FilterBedroom
          {...(filterBedroomComp?.props || {})}
          filterValue={filterUnitBedroom}
          onChange={onChangeBedroom}
          componentConfig={filterBedroomComp}
        />
      }
      {
        filterBathroomComp &&
        <FilterBathroom
          {...(filterBathroomComp?.props || {})}
          filterValue={filterUnitBathroom}
          onChange={onChangeBathroom}
          componentConfig={filterBathroomComp}
        />
      }
      {
        filterPoolComp &&
        <FilterPool
          {...(filterPoolComp?.props || {})}
          filterValue={filterUnitPool}
          onChange={onChangeUnitPool}
          componentConfig={filterPoolComp}
        />
      }
    </>
  };

  return (
    <div className={`wrap-sidenav flex absolute z-[1001] w-fit h-[calc(100svh_-_60px_-_60px)] top-0 bottom-0 left-0 mt-auto mb-auto`}>
      <CSSTransition in={isShowFilter} timeout={500} classNames="fade-left" unmountOnExit>
        <SideNav
          id='filter'
          isDark
          position='left'
          isTransparent={isTransparent}
          heading={"FILTERS"}
          footer={<UnitFilterFooter isPresentation={isPresentation} />}
          className={`!border-l-0 relative px-[35px] py-[30px] bg-brand ${!isShowUnitList ? 'rounded-r-md' : ''}`}
          bodyClassName={'gap-[25px] overflow-x-hidden '}
        >
          <div
            ref={pageRef}
            className="disable-scroll-bar flex flex-col items-start gap-7 self-stretch pt-[20px] pb-[30px]"
            onScroll={onScroll}
          >
            {renderFilterComponents()}
          </div>
        </SideNav>
      </CSSTransition>
      <CSSTransition in={!isTransparent && isShowUnitList} timeout={500} classNames="fade-left" unmountOnExit>
        <UnitList
          isPresentation={isPresentation}
          handleUnitClick={handleUnitClick}
          componentConfig={featureConfig?.components?.find((c) => c.name === UnitList.displayName)}
          {...(featureConfig?.components?.find((c) => c.name === UnitList.displayName)?.props || {})}
        />
      </CSSTransition>
    </div>
  );
};
UnitFilter.displayName = FEATURES_ENUM.UNIT_FILTERING;

export default UnitFilter;
