import React, { useEffect, useState } from "react";
import imgPlans02 from "../../../../assets/images/plans_example.png";
import iconArrow from "../../../../assets/images/arrow.png";
import Modal from "../../../modal-base";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getS3FEMediaUrl } from "../../../../helper/media";
import {
  FloorIcon,
  IconCloseButton,
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../svgs/icons";
import { AwModal } from "../../../modal-aw/aw-modal";
import Fancybox from "../../../home-gallery/fancy-box";
import { classNames } from "../../../../helper/utils";
import socket from "../../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";

export const ContentFloor = ({
  data,
  onClick,
  numberFloorSelected,
  isModal,
}) => {
  return (
    <div className="h-full w-full flex gap-[50px] p-[100px]">
      <div className="mt-[30px]">
        <div className="text-brand text-5xl font-light">Levels</div>
        <div className="text-brand text-base font-light leading-[140%]">Select a level to continue</div>
      </div>
      <div className="flex-1">
        <div className="flex flex-row gap-[15px] flex-wrap">
          {data.map((i, idx) => {
            return (
              <div
                key={`renders-${idx}`}
                onClick={() => onClick(i)}
                className={`flex flex-col justify-center items-center border w-[125px] h-[125px] cursor-pointer px-1.5 py-[3px] border-solid border-white ${
                  isModal && numberFloorSelected === i.name ? "bg-black" : "bg-white"
                }`}
              >
                <span className={`text-base font-light leading-[140%] ${isModal && numberFloorSelected === i.name ? "text-white" : "text-black"}`}>{i.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ContentPlans = ({
  numberFloorSelected,
  setShowFloorModal,
  onShowGalleryData,
  data,
}) => {
  const selectedItem = data?.find((i) => i.name === numberFloorSelected);
  const selectedItemMedia =
    selectedItem?.media?.length > 0 ? selectedItem?.media[0] : {};
  const _hotspots = selectedItemMedia?.hotspots
    ? Array.isArray(selectedItemMedia?.hotspots)
      ? selectedItemMedia?.hotspots
      : Object.values(selectedItemMedia?.hotspots)
    : [];

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleLoad = (event) => {
    const naturalWidth = event.target?.naturalWidth,
      naturalHeight = event.target?.naturalHeight;
    setImageSize({
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  return (
    <div className="flex flex-row h-full pl-[150px] pr-[70px] py-[100px]">
      <div className="w-[360px] mt-[30px]">
        <div className="text-brand text-5xl font-light w-[360px]">{`Floor ${numberFloorSelected}`}</div>
        <div className="@apply text-brand text-base font-light leading-[140%]">Select a level to continue</div>
        <div
          className="ccursor-pointer flex mt-5"
          onClick={() => setShowFloorModal(true)}
        >
          <FloorIcon style={{ width: 20, height: 20 }} />
          <span className="text-[#c7a446] text-sm font-light ml-2.5">Switch level</span>
        </div>
      </div>
      <div id="aw-image-container" className="flex-1 h-full flex justify-center items-centerr">
        <div id="aw-image-item" className="relative inline-block h-full">
          <img
            onLoad={handleLoad}
            src={getS3FEMediaUrl(selectedItemMedia?.path)}
            alt="plans-floor"
            className="w-full h-auto max-h-full object-containcursor-pointer"
          />
          <div className="absolute inset-0">
            {_hotspots?.map((mk, mki) => {
              const mkx = (
                imageSize?.width
                  ? (Number(mk?.x || 0) / imageSize?.width) * 100
                  : 0
              ).toFixed(4);
              const mky = (
                imageSize?.height
                  ? (Number(mk?.y || 0) / imageSize?.height) * 100
                  : 0
              ).toFixed(4);
              return (
                <div
                  key={`img-hotspots-${mki}`}
                  onClick={() => onShowGalleryData(`im-${mk?.floorplan}`)}
                  style={{
                    position: "absolute",
                    left: `calc(${mkx}% - 20px)`,
                    top: `calc(${mky}% - 25px)`,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={getS3FEMediaUrl(mk?.image)}
                    style={{ width: 40, height: 50 }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ViewPlans = ({ data, numberFloorSelected }) => {
  const idxSelected = data.findIndex((i) => i.name === numberFloorSelected);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={iconArrow} alt="next" className="next-arrow" />,
    prevArrow: <img src={iconArrow} alt="previous" className="pre-arrow" />,
  };
  return (
    <div className="h-full">
      <Slider {...settings} className="slider-floor-plan">
        {data[idxSelected].media.map((item, key) => {
          return (
            <div key={`slider-plans-${key}`} className="h-[calc(100vh_-_50px)] flex">
              <img
                src={imgPlans02}
                alt="plans-floor"
                className="cursor-pointer w-[calc(100vw_-_200px)] object-cover m-auto"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

const FloorPlans = ({
  data,
  isInfinity,
  tabActive,
  subTabActive,
  isPresentation,
}) => {
  const [isShowFloorModal, setShowFloorModal] = useState(false);
  const [numberFloorSelected, setNumberFloorSelected] = useState("0");
  const [isFloorSelected, setIsFloorSelected] = useState(false);
  const [isShowViewPlans, setIsShowViewPlans] = useState(false);
  const [showGalleryData, setShowGalleryData] = useState(null);
  const selectedItem = data?.find((i) => i.name === numberFloorSelected);
  const imgList =
    (Array.isArray(selectedItem?.floorplans)
      ? selectedItem?.floorplans
      : []
    )?.map((currentValue) => {
      return { image: currentValue?.path, key: `im-${currentValue?.name}` };
    }) || [];

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.CHANGE_ACTIVE_FLOORPLAN_LEVEL) {
          onSelectedFloor(content.data.level);
        }

        if (content.action == ACTION_NAME.SHOW_VIEW_FLOORPLAN) {
          handleShowViewPlan();
        }

        if (content.action == ACTION_NAME.CLOSE_VIEW_FLOORPLAN) {
          handleCloseViewPlans();
        }

        if (content.action == ACTION_NAME.CLOSE_FLOOR_MODAL) {
          onHideFloorModal();
        }

        if (content.action == ACTION_NAME.SHOW_GALLERY_DATA) {
          onShowGalleryData(content.data.id);
        }

        if (content.action == ACTION_NAME.JUMP_TO_FLOORPLAN) {
          onJumpToImage(content.data.index);
        }

        if (content.action == ACTION_NAME.GO_NEXT_FLOORPLAN) {
          onNextImage();
        }

        if (content.action == ACTION_NAME.GO_PREV_FLOORPLAN) {
          onPrevImage();
        }

        if (content.action == ACTION_NAME.CLOSE_FLOORPLAN_IMAGE) {
          onCloseImage();
        }
      });
    }
  }, [isPresentation]);

  const onHideFloorModal = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FLOOR_MODAL);
    }
    setShowFloorModal(false);
  };

  const onShowGalleryData = (id = "") => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_DATA, {
        id,
      });
    }
    const el = document.getElementById(id);

    if (el) {
      el.click();
      setShowGalleryData(id);
    }
  };

  useEffect(() => {
    setIsFloorSelected(false);
  }, [tabActive, subTabActive]);

  const onSelectedFloor = (i) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_FLOORPLAN_LEVEL, {
        level: i,
      });
    }
    setIsFloorSelected(true);
    setNumberFloorSelected(i.name);
    if (isShowFloorModal) {
      setShowFloorModal(false);
    }
  };

  const handleShowViewPlan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_VIEW_FLOORPLAN);
    }
    setIsShowViewPlans(true);
  };

  const handleCloseViewPlans = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_VIEW_FLOORPLAN);
    }
    setIsShowViewPlans(false);
  };

  const onNextImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.GO_NEXT_FLOORPLAN);
    }
    const fancyBoxRef = document.getElementsByClassName(
      "fancybox__container"
    )[0]?.Fancybox;
    fancyBoxRef?.next();
  };

  const onPrevImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.GO_PREV_FLOORPLAN);
    }
    const fancyBoxRef = document.getElementsByClassName(
      "fancybox__container"
    )[0]?.Fancybox;
    fancyBoxRef?.prev();
  };

  const onJumpToImage = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_FLOORPLAN, {
        index,
      });
    }
    const fancyBoxRef = document.getElementsByClassName(
      "fancybox__container"
    )[0]?.Fancybox;
    fancyBoxRef?.jumpTo(index);
  };

  const onCloseImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FLOORPLAN_IMAGE);
    }
    const fancyBoxRef = document.getElementsByClassName(
      "fancybox__container"
    )[0]?.Fancybox;
    fancyBoxRef?.close();
  };

  return (
    <div className={`w-full h-full flex flex-col ${isInfinity ? "bg-[#e3e3e3]" : ""}`}>
      {isFloorSelected ? (
        <ContentPlans
          numberFloorSelected={numberFloorSelected}
          handleShowViewPlan={handleShowViewPlan}
          setShowFloorModal={setShowFloorModal}
          onShowGalleryData={onShowGalleryData}
          isPresentation={isPresentation}
          data={data}
        />
      ) : (
        <ContentFloor onClick={onSelectedFloor} data={data} />
      )}
      <div style={{ display: showGalleryData ? "block" : "none" }}>
        <Fancybox
          options={{
            infinite: true,
            mainClass: "pure-fancy-box",
            showNavArrows: false,
            on: {
              destroy: () => setShowGalleryData(null),
              "Carousel.change": (fancybox) => {
                const el = fancybox.getSlide();
                if (!isPresentation) {
                  socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_FLOORPLAN, {
                    index: el.index,
                  });
                }
                setShowGalleryData(el.$trigger.id);
              },
            },
          }}
        >
          {imgList?.map((item, index) => (
            <img
              id={`img-${item?.key}`}
              key={`slider-img-${index}`}
              src={getS3FEMediaUrl(item.image)}
              alt="normal-img"
              data-fancybox="gallery"
            />
          ))}
        </Fancybox>
      </div>
      {!!showGalleryData && (
        <div className="absolute z-[1055] flex flex-row items-center justify-center gap-5 bottom-[45px] inset-x-0">
          <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
            <LeftArrowIcon style={{ width: 21, height: 35 }} fill={"#C7A446"} />
          </div>
          <div className="flex flex-row items-center justify-center gap-3.5">
            {imgList?.map((item, index) => (
              <div
                key={`img-slide-${index}`}
                className={classNames(
                  "w-3 h-3 bg-[#c7a446] cursor-pointer rounded-xl",
                  item?.key === showGalleryData ? "" : "bg-transparent border-[1.5px] border-solid border-[#c7a446]"
                )}
              />
            ))}
          </div>
          <div className="btn-next cursor-pointer" onClick={onNextImage}>
            <RightArrowIcon
              style={{ width: 21, height: 35 }}
              fill={"#C7A446"}
            />
          </div>
        </div>
      )}
      {!!showGalleryData && (
        <div className="absolute z-[1055] right-[45px] top-[45px]">
          <div className="cursor-pointer" onClick={onCloseImage}>
            <IconCloseButton style={{ width: 26 }} fill={"#C7A446"} />
          </div>
        </div>
      )}

      <Modal
        classNames="modal-view-plans"
        open={isShowViewPlans}
        onHide={handleCloseViewPlans}
        isOutSideClose={false}
        currentColor="black"
      >
        <ViewPlans
          data={data}
          numberFloorSelected={numberFloorSelected}
          isPresentation={isPresentation}
        />
      </Modal>
      <AwModal
        style={{ background: "rgba(0, 0, 0, 0.80)" }}
        show={isShowFloorModal}
        onClose={onHideFloorModal}
        isOutSideClose={false}
      >
        <ContentFloor
          isModal
          numberFloorSelected={numberFloorSelected}
          onClick={onSelectedFloor}
          data={data}
        />
      </AwModal>
    </div>
  );
};

export default FloorPlans;
