/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as PANOLENS from "panolens";
import { ImageLoader } from "three";
import classNames from "classnames";
import { Vector3 } from "three";
import socket from "../../../helper/socket";
import { WEBSOCKET_CHANNEL } from "../../../constants/options";

export function Equirectangular({
  isPresentation,
  url,
  onClose,
  onNext,
  onPrev,
  hideArrows,
}) {
  const loader = new ImageLoader();
  const [viewer, setViewer] = useState(undefined);

  const authUser = useSelector((state) => state.user.data);
  useEffect(() => {
    onInitPanorama();
  }, [url]);

  function temporaryStopRotate() {
    viewer.disableAutoRate();
    setTimeout(function () {
      viewer.enableAutoRate();
    }, 3000);
  }

  useEffect(() => {
    if (viewer) {
      window.addEventListener("click", temporaryStopRotate);
      viewer.addUpdateCallback(sendCameraPos);
      return () => {
        viewer.removeUpdateCallback(sendCameraPos);
        window.removeEventListener("click", temporaryStopRotate);
      };
    }
  }, [viewer]);

  useEffect(() => {
    if (!viewer) {
      return;
    }
    if (!isPresentation) {
      return;
    }

    // Prevent receiving camera position before init
    setTimeout(() => {
      socket.on(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, listenerCameraAction);
    }, 1000);
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, listenerCameraAction);
    };
  }, [viewer]);

  const listenerCameraAction = ({ content }) => {
    const camera = viewer?.camera;
    if (!camera) {
      return;
    }

    let position1 = new Vector3();
    position1.x = content.position.x;
    position1.y = content.position.y;
    position1.z = content.position.z;

    camera.position.copy(content.position);
    camera.quaternion.copy(content.quaternion);
    camera.fov = content.fov;
    viewer.panorama?.rotation &&
      (viewer.panorama.rotation.y = content.rotationY);
    camera.updateProjectionMatrix();
  };

  function sendCameraPos() {
    if (isPresentation) {
      return;
    }
    const camera = viewer?.camera;
    if (!camera) {
      return;
    }

    socket.emit(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, {
      content: {
        position: camera.position,
        quaternion: camera.quaternion,
        rotationY: viewer.panorama?.rotation?.y,
        fov: camera.fov,
      },
      to: authUser?.id,
      from: authUser?.id,
    });
  }

  const onInitPanorama = () => {
    let selector = document.querySelector("#wrap-panorama");
    if (selector != undefined) {
      selector.replaceChildren();
    }

    loader.load(
      url,
      function (image) {
        const pano = new PANOLENS.ImagePanorama(image);
        viewer.add(pano);
        viewer.setPanorama(pano);
      },
      undefined,
      function (error) {
        console.error("An error happened.", error);
      }
    );

    let viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama"),
      controlButtons: [],
      autoRotateSpeed: 0.5,
      ...(!isPresentation ? { autoRotate: true } : {}),
    });
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    setViewer(viewer);
  };

  return (
    <>
      <div
        className={classNames(
          "w-[100vw] z-[2000] fixed left-0 top-0 h-[100dvh]",
          {
            isPresentation: isPresentation,
          }
        )}
      >
        <div id="wrap-panorama" className="h-full w-full" />
        <div
          className="absolute z-[1002] w-[24px] h-[24px] top-[49px] right-[50px] cursor-pointer"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
          >
            <path
              d="M1 1L25 22.8182M25 1L1 22.8182"
              stroke="white"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {!hideArrows ? (
          <div
            className="cursor-pointer z-[1001] top-0 left-[81px]  absolute  h-full flex items-center justify-center p-0"
            onClick={onPrev}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 80 80"
              fill="none"
              className="absolute"
            >
              <circle cx="40" cy="40" r="40" fill="#242422" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute"
              width="16"
              height="8"
              viewBox="0 0 32 10"
              fill="none"
            >
              <g clipPath="url(#clip0_1226_46867)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.207 5.50048H32V4.50048H2.207L5.354 1.35448L4.646 0.646484L0.646 4.64648L0.292999 5.00048L0.646 5.35448L4.646 9.35448L5.354 8.64648L2.207 5.50048Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1226_46867">
                  <rect
                    width="32"
                    height="10"
                    fill="white"
                    transform="matrix(-1 0 0 1 32 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        ) : null}
        {!hideArrows ? (
          <div
            className="cursor-pointer z-[1001] top-0 right-[81px] absolute h-full flex items-center justify-center p-0"
            onClick={onNext}
            hideArrows={hideArrows}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 80 80"
              fill="none"
              className="absolute"
            >
              <circle cx="40" cy="40" r="40" fill="#242422" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="8"
              viewBox="0 0 32 10"
              fill="none"
              className="absolute"
            >
              <g clipPath="url(#clip0_1226_46863)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29.793 5.50048H0V4.50048H29.793L26.646 1.35448L27.354 0.646484L31.354 4.64648L31.707 5.00048L31.354 5.35448L27.354 9.35448L26.646 8.64648L29.793 5.50048Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1226_46863">
                  <rect width="32" height="10" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        ) : null}
      </div>
    </>
  );
}
