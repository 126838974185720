import React, { useEffect, useState } from "react";

export const MapStyleInput = ({ onSave, name, defaultValue }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState();

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => setValue(defaultValue), [defaultValue]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const handleBlur = () => {
    setValue(defaultValue);
    setIsEditing(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const onSubmit = () => {
    onSave(value);
    setIsEditing(false);
  };
  return (
    <div className="flex justify-between border-t border-b border-gray-300">
      <div className="flex items-center w-[50%] p-4 border-r border-gray-300">
        <span>{name}</span>
      </div>
      <div className="flex items-center w-[50%] p-4">
        <div className="ml-2 max-h-24 overflow-y-auto overflow-x-hidden flex w-full">
          {isEditing ? (
            <>
              <textarea
                type="text"
                value={value}
                onChange={handleInputChange}
                onBlur={handleBlur} // exit edit mode when losing focus
                className="h-20 w-full border border-gray-300 p-1 outline-none focus:outline-none focus:border-gray-500 focus:ring-0"
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <button
                onMouseDown={handleMouseDown}
                type="button"
                onClick={onSubmit}
                className="ml-4 text-gray-500 text-sm"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleBlur}
                className="ml-4 text-gray-500 text-sm"
              >
                Cancel
              </button>
            </>
          ) : (
            <span
              className="inline-block h-20 w-full"
              onClick={() => setIsEditing(true)}
            >
              {value}&nbsp;
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
