import React, { useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Photo } from "../../../../components/svgs/icons";
import { getS3BEMediaUrl } from "../../../../helper/media";
import { Button, Modal } from "../../components/commons";
import EditIcon from "../../configuration/components/svgs/EditIcon";
import UpsertGalleryPlaylist from "../../gallery/upsertGalleryPlaylist";
import { MEDIA_TYPE } from "../../utils";
import {
  reqGetListAmenities,
  reqOnChangePlaylistOfModal,
} from "../../../../reduxs/cms/action";
import LinkIcon from "../../configuration/components/svgs/LinkIcon";

const Row = ({ data, onEditAmenity, head = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const playlistId = data?.modal?.virtualTour?.id;

  if (head) {
    return (
      <tr className="table w-full table-fixed mb-[60px] uppercase">
        <th className="p-2 align-top w-[32px]"></th>
        <th className="p-2 align-top w-[20%] text-left">Name</th>
        <th className="p-2 align-top w-[26%] text-left">Description</th>
        <th className="p-2 align-top w-[20%] min-w-[120px] text-left">Image</th>
        <th className="p-2 align-top w-[15%] text-left">Virtual tour</th>
        <th className="p-2 align-top w-[13%] text-left">Created at</th>
        <th className="p-2 align-top w-[40px]" />
      </tr>
    );
  }

  const onChangePlaylist = async (option) => {
    const { id } = option;

    if (id === playlistId) return;

    dispatch(
      reqOnChangePlaylistOfModal(
        data?.modal?.id,
        {
          virtualTour: id,
        },
        () => {
          toast.success("Change playlist successfully!");
          dispatch(
            reqGetListAmenities({
              sortBy: JSON.stringify({
                name: 1,
              }),
            })
          );
        },
        () => {
          toast.error("Change playlist failed!");
        }
      )
    );
  };

  const onNavigateVirtualTour = () => {
    if (!playlistId) {
      toast.warn("Please select playlist first");
      return;
    }

    navigate(`/cms/virtual-tours/${playlistId}/${data?.modal?.id}`);
  };

  return (
    <tr
      key={data?.id}
      className={`p-3 table w-full table-fixed my-2 border-b-[1px] border-solid border-[#C5C5C566] ${
        !show ? "collapsed" : ""
      }`}
    >
      <td className="p-2 align-top w-[32px]">
        <img
          onClick={() => {
            setShow(!show);
          }}
          src={`/icons/arrow-${show ? "up" : "down"}.svg`}
          alt=""
          className="arrow mt-2"
        />
      </td>
      <td className="p-2 align-top w-[20%] td-name">
        <div
          className={classNames(
            "w-full overflow-hidden",
            show ? "" : "h-5 w-[170px] text-ellipsis whitespace-pre"
          )}
        >
          {data?.modal?.name}
        </div>
      </td>
      <td className="p-2 align-top w-[26%] td-description">
        <div
          className={classNames(
            "w-full overflow-hidden",
            show ? "" : "h-5 w-[285px] text-ellipsis whitespace-pre"
          )}
        >
          {data?.modal?.description}
        </div>
      </td>
      <td className="p-2 align-top w-[20%] min-w-[120px] td-description">
        <div className="flex gap-2 w-full overflow-hidden whitespace-pre-line">
          <Photo className="!h-5 w-4 shrink-0 cursor-pointer" />
          <div>{data?.modal?.image?.name || "No image"}</div>
        </div>
        {data?.modal?.image && show && (
          <img
            className="h-32 mt-3"
            src={getS3BEMediaUrl(data?.modal?.image?.path)}
          />
        )}
      </td>
      <td className="p-2 align-top w-[15%] td-description ">
        <div className="flex gap-2">
          <Modal
            classCustom="overflow-hidden"
            wrapperClassName="wrap-update-content-modal"
            panelClassName="w-[80vw] p-0 rounded-none bg-[rgb(251,251,251)]"
            zIndex="z-[9998]"
            maskClassName="opacity-90"
            trigger={
              <Button
                icon={<EditIcon className="h-4 w-4 shrink-0  cursor-pointer" />}
                variant="text"
                className="text-blue-500 border-blue-500 "
              />
            }
            content={({ setIsShow }) => (
              <UpsertGalleryPlaylist
                mediaType={MEDIA_TYPE.VIRTUALTOURS}
                forAmenity={true}
                callbackOnchangePlaylist={onChangePlaylist}
                playlistId={playlistId}
              />
            )}
          />
          <Button
            onClick={onNavigateVirtualTour}
            icon={<LinkIcon className="h-4 w-4 shrink-0 cursor-pointer" />}
            variant="text"
            className="text-green-500 border-green-500"
          />
        </div>
      </td>
      {/* <td className="p-2 align-top w-[15%] td-description">
        {data?.['3d_mesh']}
      </td> */}
      <td className="p-2 align-top w-[13%]">
        {data?.createdAt
          ? dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")
          : ""}
      </td>
      <td
        className="p-2 align-top w-[40px] td-edit"
        onClick={() => onEditAmenity(data)}
      >
        <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
      </td>
    </tr>
  );
};

export default Row;
