import React from "react";
import useColumns from "../useColumns";
import Table from "../../../components/Table";
import { cn } from "../../../../../../helper/utils";

const ListModels = ({ data}) => {  
  const { _3dColumns } = useColumns();

  return (
    <div className=" mt-5 mb-10">
      <div className="grid grid-cols-1 gap-5">
        <Table
          disableSortBy
          manualPagination
          className="overflow-table"
          bodyClassName="!max-h-96"
          cellClassName="first:bg-gray-200 first:text-black border-l-[1px]  border-stone-200 "
          wrapperClassName={cn(
            "rounded-md mt-1 col-span-1 bg-white overflow-y-auto border-[1px] border-gray-300"
          )}
          classnamethead="sticky top-0 z-0 bg-white"
          data={data || []}
          columns={_3dColumns}
        />
      </div>
    </div>
  );
};

export default ListModels;
