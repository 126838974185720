import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const listConfig = () =>
    defaultClient.get(endPoint.LIST_CONFIGS);

const getConfig = () =>
    defaultClient.get(endPoint.GET_ACTIVE_CONFIG);

const updateConfig = (id, data) =>
    defaultClient.put(`${endPoint.UPDATE_CONFIG}/${id}`, data);

export default {
    listConfig,
    getConfig,
    updateConfig
};
