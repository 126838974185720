import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CmsPageHeader from "../components/PageHeader";
import Setting from "./Setting";
import Viewer from "./Viewer";
import { reqSetExploreModal } from "../../../reduxs/explore-modal/action";
import { transformedScenes } from "../utils";

export const initialData = {
  scenes: [],
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: true,
    fullscreenButton: true,
    viewControlButtons: true,
  },
};

const VirtualTour = () => {
  const { modalId } = useParams();

  const dispatch = useDispatch();
  const modalDetailRes = useSelector((state) => state.exploreModal.modal);

  const data = useMemo(() => {
    const medias = modalDetailRes?.virtualTour?.medias;

    if (medias?.length) {
      return transformedScenes(medias);
    }

    return initialData;
  }, [modalDetailRes]);


  useEffect(() => {
    if (modalId) {
      dispatch(reqSetExploreModal(modalId));
    }
  }, [modalId]);

  const [virtualTourData, setVirtualTourData] = useState(data);
  const [isSetting, setIsSetting] = useState(true);

  useEffect(() => {
    setVirtualTourData(data);
  }, [data]);

  return (
    <div className="page-container">
      <CmsPageHeader
        title={
          <div
            className={`flex gap-5 ${isSetting ? "flex flex-row-reverse" : ""}`}
          >
            <span
              className="cursor-pointer text-[40px]"
              onClick={() => setIsSetting(!isSetting)}
            >
              {isSetting ? " >" : " < "}
            </span>
            Virtual Tour
          </div>
        }
      />
      {isSetting && (
        <Setting
          dataResponse={data}
          virtualTourData={virtualTourData}
          setVirtualTourData={setVirtualTourData}
        />
      )}
      {!isSetting && <Viewer data={virtualTourData} />}
    </div>
  );
};

export default VirtualTour;
