import React from 'react';

// import './sidenav.scss';

const SideNav = (props) => {

  const {
    id,
    heading = null,
    icon = null,
    footer = null,
    isDark = false,
    position,
    isTransparent,
    className = '',
    headingClassName = '',
    bodyClassName = '',
    footerClassName = '',
  } = props;

  const wrapClassName = () => {
    let cls = '';
    // position
    if (position === 'left') cls += 'float-start';
    else cls += 'float-end';
    // is transparent
    if (isTransparent) cls += ' opacity-0';
    return cls;
  }

  return (
    <div
      id={id}
      className={`sidenav ${className} flex flex-col w-[225px] border-l-[1px] border-l-brand-secondary h-100 ${wrapClassName()}`}
    >
      {
        heading &&
        <div className={`sidenav__heading ${headingClassName} p-0 mb-[30px] *:!font-domaine`}>
          <h2 className='tracking-[0.07rem] text-primary text-2xl font-normal leading-none uppercase'>{heading} {icon}</h2>
        </div>
      }
      <div className={`sidenav__body no-scrollbar ${bodyClassName} flex flex-col leading-[2.188] justify-start h-[calc(100vh_*_(800_/_1024)_-_100px)] border-t-[1px] border-t-brand-secondary`}>
        {props.children}
      </div>
      {
        footer &&
        <div className={`sidenav__footer ${footerClassName} pt-[20px] mt-auto`}>
          {footer}
        </div>
      }
    </div>
  );
};

export default SideNav;
