import { toast } from "react-toastify";
import React, { useEffect, useMemo } from "react";
import { MODEL_MEDIA_TYPES } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import canvasProfileApi from "../../../../../../apis/api/canvas-profiles";
import { reqGetModelsMediaList } from "../../../../../../reduxs/media/action";
import { actSetSelectedCanvasProfileId, reqGetActiveConfig, reqGetCanvasProfiles } from "../../../../../../reduxs/cms/action";

export const CellModel = ({ rowComponent, modelType }) => {
  const dispatch = useDispatch();

  const canvasProfiles = useSelector((state) => state.cms.canvasProfiles);
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const selectedCanvasProfileId = useSelector((state) => state.cms.selectedCanvasProfileId);

  const selectedCanvasProfile = useMemo(() => {
    if (selectedCanvasProfileId) {
      return canvasProfiles?.find(t => t.id === selectedCanvasProfileId) || null;
    }
    return null;
  }, [selectedCanvasProfileId, canvasProfiles]);

  useEffect(() => {
    if (!selectedCanvasProfileId) {
      dispatch(actSetSelectedCanvasProfileId(activeConfig?.canvasProfile?.id));
    }
  }, [selectedCanvasProfileId, activeConfig]);

  const toggleVisibleComponent = async (modelMedia, checked) => {
    try {
      if (!modelMedia?.id) return;
      const payload = {};
      if (modelType === MODEL_MEDIA_TYPES.MAIN) {
        payload.modelMedia = checked ? modelMedia.id : null;
      } else if (modelType === MODEL_MEDIA_TYPES.HELPERS) {
        payload.helperMedia = checked ? modelMedia.id : null;
      }
      const res = await canvasProfileApi.updateCanvasProfile(selectedCanvasProfile?.id, payload);
      if (res) {
        if (modelType === MODEL_MEDIA_TYPES.MAIN && checked) {
          toast.success(`Model ${modelMedia.name} set as MAIN for ${selectedCanvasProfile?.name}`);
        } else if (modelType === MODEL_MEDIA_TYPES.HELPERS && checked) {
          toast.success(`Model ${modelMedia.name} set as HELPER for ${selectedCanvasProfile?.name}`);
        } else {
          toast.success(`Model list updated`);
        }
        dispatch(reqGetModelsMediaList());
        dispatch(reqGetCanvasProfiles());
        dispatch(reqGetActiveConfig());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isChecked = useMemo(() => {
    switch (modelType) {
      case MODEL_MEDIA_TYPES.MAIN:
        return rowComponent?.id === selectedCanvasProfile?.modelMedia?.id;
      case MODEL_MEDIA_TYPES.HELPERS:
        return rowComponent?.id === selectedCanvasProfile?.helperMedia?.id;
      default:
        return false;
    }
  }, [selectedCanvasProfile]);

  const isDisabled = useMemo(() => {
    switch (modelType) {
      case MODEL_MEDIA_TYPES.MAIN:
        return rowComponent?.id === selectedCanvasProfile?.helperMedia?.id;
      case MODEL_MEDIA_TYPES.HELPERS:
        return rowComponent?.id === selectedCanvasProfile?.modelMedia?.id;
      default:
        return false;
    }
  }, [selectedCanvasProfile]);

  return (
    <div className="flex justify-center relative">
      <input
        type="checkbox"
        className="w-5 h-5 rounded-full disabled:opacity-40 disabled:cursor-not-allowed disabled:bg-gray-400/30"
        checked={isChecked}
        disabled={isDisabled}
        onChange={(e) =>
          toggleVisibleComponent(
            rowComponent,
            e.target.checked
          )
        }
      />
    </div>
  );
};
