export const IS_SCROLL_UNITS = "IS_SCROLL_UNITS";
export const LOADING = "LOADING";
export const FEATURE_LOADING = "FEATURE_LOADING";
export const GET_PAGES = "GET_PAGE_LIST";
export const GET_MODULES = "GET_MODULE_LIST";
export const GET_MODULE_DETAIL = "GET_MODULE_DETAIL";
export const MODULE_LOADING = "MODULE_LOADING";
export const GET_FEATURES = "GET_FEATURE_LIST";
export const GET_UNIT_LIST = "GET_UNIT_LIST";
export const UNITS_LOADING = "UNITS_LOADING";
export const GET_AMENITY_LIST = "AMENITY_LIST";
export const AMENITY_LOADING = "AMENITY_LOADING";
export const GET_TRANSPORT_OPTION_LIST = "TRANSPORT_OPTION_LIST";
export const TRANSPORT_OPTION_LOADING = "TRANSPORT_OPTION_LOADING";
