import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { InputField } from "../../../components/commons";
import TickIcon from "../../components/svgs/TickIcon";
import CancelIcon from "../../components/svgs/cancelIcon";
import pageConfiguration from "../../../../../apis/api/page-configuration";
import Loading from "../../components/loading";
import { refetchGetFeatureList } from "../../../../../reduxs/page-configuration/action";

const ProjectLabelForm = ({ field }) => {
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    label: yup.string().trim().required("Label is a required field"),
  });

  const { control, formState, reset, handleSubmit } = useForm({
    defaultValues: {
      label: field?.props?.label,
    },
    resolver: yupResolver(validationSchema),
  });

  const updateComponent = async (formValue, onSuccess) => {
    try {
      const res = await pageConfiguration.updateComponent(field.id, {
        name: field.name,
        props: {
          visible: field?.props?.visible,
          label: formValue.label,
          order: field?.props?.order,
        },
      });
      if (res) {
        toast.success("Update label successfully");
        dispatch(refetchGetFeatureList({ idModule: field?.idModule }));
        reset({ label: formValue.label });
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      className="flex gap-4 items-center"
      onSubmit={(e) => {
        e.preventDefault();
        formState?.isDirty && handleSubmit(updateComponent)();
      }}
    >
      <InputField
        controller={{
          control,
          name: "label",
        }}
        inputProps={{
          placeholder: "Label",
        }}
        wrapperClassName="flex-[0_0_80%]"
        inputClassName={"border-t-0 border-r-0 border-l-0 rounded-none"}
      />
      {formState?.isDirty && (
        <div className="flex gap-3 flex-[0_0_20%]">
          <button
            type={formState?.isDirty ? "submit" : "button"}
            disabled={formState?.isSubmitting}
            onClick={handleSubmit(updateComponent)}
            className="text-green-500 p-1 cursor-pointer rounded border-green-500 border-solid border-[1px] "
          >
            {formState?.isSubmitting ? (
              <Loading svgClassName="size-3 m-0" className="size-3" />
            ) : (
              <TickIcon className="size-3" />
            )}
          </button>
          <button
            type="button"
            disabled={formState?.isSubmitting}
            className="text-red-500 p-1 cursor-pointer border-solid rounded border-red-500 border-[1px] "
            onClick={() => reset()}
          >
            <CancelIcon className="size-3" />
          </button>
        </div>
      )}
    </form>
  );
};

export default ProjectLabelForm;
