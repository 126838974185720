import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../components/commons/inputField";
import { Button } from "../../components/commons";

const AddModal = ({ setIsShow, onAdd }) => {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    value: yup.string().required("Value is required"),
    category: yup.string().required("Category is required"),
  });

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleOnSave = async (v) => {
    onAdd({
        name: v.name,
        value: v.value,
        category: v.category
    })
    setIsShow(false)
  };

  return (
    <div>
      <div className="mb-3">
        <InputField
          isRequired
          label="New name"
          controller={{
            name: `name`,
            control,
          }}
          inputProps={{ placeholder: "Variable name" }}
        />
      </div>
      <div className="mb-3">
        <InputField
            className="text-black"
          isRequired
          label="New value"
          controller={{
            name: `value`,
            control,
          }}
          inputProps={{ placeholder: "Variable value" }}
        />
      </div>
      <div className="mb-3">
        <InputField
          isRequired
          label="New category"
          controller={{
            name: `category`,
            control,
          }}
          inputProps={{ placeholder: "Variable category" }}
        />
      </div>
      <div className="flex gap-4 justify-center">
        <Button
          className="w-full"
          variant="text"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={!formState.isDirty}
          className="w-full"
          onClick={handleSubmit(handleOnSave)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddModal;
