/** @format */

import _ from "lodash";
import * as Types from "./type";
import settingModuleApi from "../../apis/api/page-configuration";
import unitApi from "../../apis/api/unit";
import amenitiesApi from "../../apis/api/amenities";
import transportOptionApi from "../../apis/api/transportOption";
import galleryApi from "../../apis/api/gallery";
import { MEDIA_TYPE } from "../../pages/cms/utils";

export const actIsScrollUnits = (data) => ({
  type: Types.IS_SCROLL_UNITS,
  data,
});

const actGetPageList = (data) => ({
  type: Types.GET_PAGES,
  data,
});

const actGetModuleList = (data) => ({
  type: Types.GET_MODULES,
  data,
});

const actGetModuleDetail = (data) => ({
  type: Types.GET_MODULE_DETAIL,
  data,
});

const featureLoading = (data) => ({
  type: Types.FEATURE_LOADING,
  data,
});

const moduleLoading = (data) => ({
  type: Types.FEATURE_LOADING,
  data,
});

const actGetFeatureList = (data) => ({
  type: Types.GET_FEATURES,
  data,
});

const actLoading = (data) => ({
  type: Types.LOADING,
  data,
});

export const actSetListUnits = (data) => ({
  type: Types.GET_UNIT_LIST,
  data,
});

const actAmenitiesLoading = (data) => ({
  type: Types.AMENITY_LOADING,
  data,
});

export const actSetAmenities = (data) => ({
  type: Types.GET_AMENITY_LIST,
  data,
});

const actTransportOptionLoading = (data) => ({
  type: Types.TRANSPORT_OPTION_LOADING,
  data,
});

export const actSetTransportOptionList = (data) => ({
  type: Types.GET_TRANSPORT_OPTION_LIST,
  data,
});

export const setPageList = (data) => (dispatch) => {
  dispatch(actLoading(true));
  dispatch(actGetPageList(data));
  dispatch(actLoading(false));
};

export const reqGetPageList = (query) => (dispatch) => {
  dispatch(actLoading(true));
  return settingModuleApi
    .getPages(query)
    .then((data) => {
      dispatch(actGetPageList(data));
      dispatch(actLoading(false));
    })
    .catch(() => {
      dispatch(actLoading(false));
    });
};

export const reqDeleteModule = (id, onSuccess) => (dispatch) => {
  return settingModuleApi
    .deleteModule(id)
    .then(() => {
      dispatch(reqGetPageList({}));
      onSuccess && onSuccess();
    })
    .catch(() => {});
};

const createThreeComponentsInBGalleryModule = async (idFeature, nameModule) => {
  const results = [];
  for (let i = 1; i <= 6; i++) {
    try {
      let name, label, type;

      if (i <= 3) {
        name = `gallery_images_0${i}`;
        label = `Images${i}`;
        type = MEDIA_TYPE.IMAGE;
      } else {
        switch (i) {
          case 4:
            name = "gallery_films";
            label = "Films";
            type = MEDIA_TYPE.VIDEO;
            break;
          case 5:
            name = "gallery_360_panorama";
            label = "360 Panorama";
            type = MEDIA_TYPE.PARONAMIC;
            break;
          case 6:
            name = "gallery_floorplans";
            label = "Floorplans";
            type = MEDIA_TYPE.FLOORPLANS;
            break;
        }
      }

      const resComponent = await settingModuleApi.createComponent(idFeature, {
        name,
        props: {
          visible: true,
          label,
          order: i,
          type,
        },
      });

      const componentData = resComponent.data;
      const gallery = await galleryApi.createGallery({
        id: componentData.id,
        name: `${nameModule} ${label}`,
        type,
      });
      results.push(resComponent);
    } catch (error) {
      console.error(`Error creating component ${i}:`, error);
    }
  }
  return results;
};

export const reqAddDefaultBGalleyComponent =
  ({ idModule, nameModule, onSuccess }) =>
  async (dispatch) => {
    const res = await settingModuleApi.createFeature(idModule, {
      name: `gallery_feature_${nameModule}`,
      props: {
        visible: true,
      },
    });

    if (res.data) {
      const idFeature = res.data.id;

      const resComponent = await createThreeComponentsInBGalleryModule(
        idFeature,
        nameModule
      );

      if (resComponent?.length === 6) {
        dispatch(reqGetPageList({}));
        onSuccess && onSuccess();
      }
    }
  };

export const reqGetModuleList =
  ({ idPage }) =>
  (dispatch) => {
    return settingModuleApi
      .getModules(idPage)
      .then((data) => {
        dispatch(actGetModuleList(data));
      })
      .catch(() => {});
  };
export const reqGetModuleDetail =
  ({ idModule }) =>
  (dispatch) => {
    dispatch(moduleLoading(true));

    return settingModuleApi
      .getModule(idModule)
      .then((data) => {
        dispatch(actGetModuleDetail(data));
        dispatch(moduleLoading(false));
      })
      .catch(() => {
        dispatch(moduleLoading(false));
      });
  };

export const reqGetFeatureList =
  ({ idModule }) =>
  (dispatch) => {
    dispatch(featureLoading(true));
    return settingModuleApi
      .getFeatures(idModule)
      .then((data) => {
        dispatch(actGetFeatureList(data));
        dispatch(featureLoading(false));
      })
      .catch(() => {
        dispatch(featureLoading(false));
      });
  };

export const refetchGetFeatureList =
  ({ idModule }) =>
  (dispatch) => {
    return settingModuleApi
      .getFeatures(idModule)
      .then((data) => {
        dispatch(actGetFeatureList(data));
      })
      .catch(() => {});
  };

//Canvas

const unitLoading = (data) => ({
  type: Types.UNITS_LOADING,
  data,
});

export const reqCreateNewCanvas =
  ({ data, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      //remove old mesh
      await settingModuleApi.purgeMeshes();

      const resCreate = await settingModuleApi.create3DCanvas(data);

      if (resCreate.data) {
        onSuccess && onSuccess();
        await dispatch(getListInfoInThreeDCanvas());
      }
    } catch (error) {
      onError && onError(error);
    }
  };

export const refetchListUnits = (query) => (dispatch) => {
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actSetListUnits(data));
    })
    .catch(() => {});
};

export const reqGetListUnits = (query) => (dispatch) => {
  dispatch(unitLoading(true));
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actSetListUnits(data));
      dispatch(unitLoading(false));
    })
    .catch(() => {
      dispatch(unitLoading(false));
    });
};

export const reqGetListAmenity = (query) => (dispatch) => {
  dispatch(actAmenitiesLoading(true));
  return amenitiesApi
    .getAmenitiesList(query)
    .then((data) => {
      dispatch(actSetAmenities(data));
      dispatch(actAmenitiesLoading(false));
    })
    .catch(() => {
      dispatch(actAmenitiesLoading(false));
    });
};

export const reqGetListTransportOption = (query) => (dispatch) => {
  dispatch(actTransportOptionLoading(true));
  return transportOptionApi
    .getTransportOptions(query)
    .then((data) => {
      dispatch(actSetTransportOptionList(data));
      dispatch(actTransportOptionLoading(false));
    })
    .catch(() => {
      dispatch(actTransportOptionLoading(false));
    });
};

export const getListInfoInThreeDCanvas = () => async (dispatch) => {
  return await Promise.all([
    dispatch(
      reqGetListUnits({
        autogen: true,
      })
    ),
    dispatch(
      reqGetListAmenity({
        autogen: true,
      })
    ),
    dispatch(
      reqGetListTransportOption({
        autogen: true,
      })
    ),
  ]);
};

export const updateUnit = (data, onSuccess, onError) => (dispatch) => {
  return unitApi
    .putUnitUpdate(data.id, _.omit(data, "id"))
    .then((res) => {
      onSuccess && onSuccess();
    })
    .catch(() => {
      onError && onError();
    });
};

export const updateLabelAmenity = (data, onSuccess, onError) => (dispatch) => {
  return amenitiesApi
    .updateAmenity(data.id, _.omit(data, "id"))
    .then((res) => {
      onSuccess && onSuccess();
    })
    .catch(() => {
      onError && onError();
    });
};

export const updateLabelTransportOption =
  (data, onSuccess, onError) => (dispatch) => {
    return transportOptionApi
      .editTransportOptions(data.id, _.omit(data, "id"))
      .then((res) => {
        onSuccess && onSuccess();
      })
      .catch(() => {
        onError && onError();
      });
  };
