import React from "react";
import { useSelector } from "react-redux";

import SearchIcon from "../Search";
import LoadingTable from "../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../configuration/components/Table/EmptyTable";
import { Button } from "../../components/commons";
import pencil from "../../../../assets/images/cms/edit.png";

const UnitContent = ({ onSearch }) => {
  const { loadingUnits } = useSelector((state) => state.cms);

  return (
    <div className="bg-[rgb(244,244,244)] h-screen p-4">
      <div className="bg-white p-4 rounded-md relative">
        <div className="absolute bottom-[-10px] cursor-pointer right-[-10px] rounded-full bg-gray-500">
          <img src={pencil} className="w-10 h-10" alt="create" />
        </div>
        <div className="flex justify-end items-center mb-2 gap-5">
          {/* <Button size="small" className="py-1">
            Floorplans
          </Button>
          <Button size="small" className="py-1">
            Viewlines
          </Button> */}
          <SearchIcon
            callbackOnInputChange={onSearch}
            wrapperClassName="flex justify-end"
          />
        </div>
        <div className=" h-[calc(100vh-260px)] overflow-y-scroll">
          <table
            className="w-full border-collapse table-fixed"
            id="unit-content-table"
          >
            <thead>
              <tr className="text-sm font-semibold">
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  ID
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Type
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Name
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Unit Type
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Level
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Facing
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Additive Pick Units
                </th>
              </tr>
            </thead>
            {loadingUnits && (
              <tbody className="h-[500px]">
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <LoadingTable />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!loadingUnits && (
              <tbody className="h-[500px]">
                <tr>
                  <td
                    colSpan="7"
                    className="text-center py-4 border border-t-0 border-gray-300"
                  >
                    <div className="flex justify-center items-center">
                      <EmptyTable />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {/*{!loadingUnits && (*/}
            {/*  <tbody>*/}
            {/*    {(units || [])?.map((item, index) => {*/}
            {/*      return (*/}
            {/*        <>*/}
            {/*          <tr*/}
            {/*            key={index}*/}
            {/*            className="text-sm transition-all hover:bg-gray-100 cursor-grab"*/}
            {/*          >*/}
            {/*            <td className={thCss}>*/}
            {/*              {_.get(item, "3d_mesh")?.split("_")[1]}*/}
            {/*            </td>*/}
            {/*            <td className={`${thCss} capitalize`}>*/}
            {/*              {item?.availabilityStatus}*/}
            {/*            </td>*/}
            {/*            <td className={thCss}>{item?.level}</td>*/}
            {/*            <td className={thCss}>-</td>*/}
            {/*            <td className={thCss}>{item?.view?.type}</td>*/}
            {/*            <td className={thCss}>*/}
            {/*              <img*/}
            {/*                onClick={() => setIsShowUnitModal(true)}*/}
            {/*                src={icImg}*/}
            {/*                alt="edit"*/}
            {/*                className="w-20 m-auto"*/}
            {/*              />*/}
            {/*            </td>*/}
            {/*            <td className={thCss}>*/}
            {/*              <img*/}
            {/*                onClick={() => setIsShowUnitModal(true)}*/}
            {/*                src={icImg}*/}
            {/*                alt="edit"*/}
            {/*                className="w-20 m-auto"*/}
            {/*              />*/}
            {/*            </td>*/}
            {/*            <td className={thCss}>*/}
            {/*              <img*/}
            {/*                onClick={() => setIsShowUnitModal(true)}*/}
            {/*                src={icImg}*/}
            {/*                alt="edit"*/}
            {/*                className="w-20 m-auto"*/}
            {/*              />*/}
            {/*            </td>*/}
            {/*            <td className={thCss}>*/}
            {/*              <img*/}
            {/*                onClick={() => setIsShowUnitModal(true)}*/}
            {/*                src={icImg}*/}
            {/*                alt="edit"*/}
            {/*                className="w-20 m-auto"*/}
            {/*              />*/}
            {/*            </td>*/}
            {/*            <td className={thCss}>*/}
            {/*              <img*/}
            {/*                src={icEdit}*/}
            {/*                alt="edit"*/}
            {/*                className="w-24 m-auto"*/}
            {/*                onClick={() => setSelectedData(item)}*/}
            {/*              />*/}
            {/*            </td>*/}
            {/*          </tr>*/}
            {/*        </>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </tbody>*/}
            {/*)}*/}
          </table>
        </div>
      </div>
    </div>
  );
};

const thCss =
  "text-left border whitespace-nowrap  overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

export default UnitContent;
