/** @format */

import React, { useEffect, useState } from "react";
import { cn } from "../../../../helper/utils";
import { usePagination, DOTS } from "./hook";

const Pagination = ({
  total,
  getDataPagination,
  pageSize,
  customStyle,
  resetPage,
}) => {
  const [currentPage, setActive] = useState(1);
  const paginationRange = usePagination({
    currentPage,
    totalCount: total,
    pageSize: pageSize,
  });

  useEffect(() => {
    setActive(1);
    // getDataPagination(1);
  }, [total, resetPage]);

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  let lastPage = paginationRange[paginationRange.length - 1];

  const clickPage = (i) => {
    setActive(i);
    getDataPagination(i);
  };

  const next = () => {
    if (currentPage === total) return;

    setActive(currentPage + 1);
    getDataPagination(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setActive(currentPage - 1);
    getDataPagination(currentPage - 1);
  };

  return (
    <div
      className={cn(
        "flex items-center gap-4 absolute bottom-10 w-full justify-center",
        customStyle
      )}
    >
      <div
        className={cn(
          "flex items-center gap-2 rounded-full underline cursor-pointer text-black",
          {
            "pointer-events-none text-gray-500": currentPage === 1,
          }
        )}
        onClick={prev}
      >
        Prev
      </div>
      <div className="flex items-center gap-4">
        {paginationRange &&
          paginationRange.length > 0 &&
          paginationRange.map((i) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (i === DOTS) {
              return <span className="pagination-item dots">&#8230;</span>;
            }
            return (
              <span
                onClick={() => clickPage(i)}
                key={i}
                className={cn("cursor-pointer text-gray-600 font-normal", {
                  "text-black font-bold": currentPage === i,
                })}
              >
                {i}
              </span>
            );
          })}
      </div>
      <div
        className={cn(
          "flex items-center gap-2 rounded-full underline cursor-pointer text-black",
          {
            "pointer-events-none text-gray-500": currentPage === lastPage,
          }
        )}
        onClick={next}
      >
        Next
      </div>
    </div>
  );
};

export default Pagination;
