import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getThemes = () =>
    defaultClient.get(endPoint.GET_THEMES);

const updateTheme = (id, data) =>
    defaultClient.put(endPoint.UPDATE_THEMES.replace(':themeId', id), data);

const updateVariables = (themeId, variableId, data) =>
    defaultClient.put(endPoint.UPDATE_VARIABLES.replace(':themeId', themeId).replace(':cssVariableId', variableId), data)

export default {
    getThemes,
    updateTheme,
    updateVariables
};
