import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const authClient = new ApiClient(authFetcher);
const defClient = new ApiClient(defaultFetcher);

const get3DSettings = (data) => authClient.get(endPoint._3D_SETTINGS, data);
const update3DSettings = (id, data) => defClient.put(endPoint._3D_SETTINGS_UPDATE.replace(':id', id), data);

export default {
  get3DSettings,
  update3DSettings,
};
