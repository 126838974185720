import React from "react";
import dayjs from "dayjs";
import { MODEL_MEDIA_TYPES } from "../../../utils";
import EditLevelUnit from "./modals/EditLevelUnit";
import { CellModel } from "./list-models/cell-model";
import EditIcon from "../../components/svgs/EditIcon";
import { CellEditModel } from "./list-models/cell-edit";
import { CellEditEnvMap } from "./list-env-maps/cell-edit";
import { Button, Modal } from "../../../components/commons";
import { CellActiveEnvmap } from "./list-env-maps/cell-active-env";

const useColumns = () => {
  const _3dColumns = [
    {
      header: "UID",
      accessorKey: "id",
      size: 50,
      enableSorting: true,
      meta: {
        className: "text-black bg-gray-300 !rounded-bl-none",
      },
      cell: ({ row: { index } }) => {
        return <div className="text-left">{index + 1}</div>;
      },
    },
    {
      header: "File Name",
      accessorKey: "name",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300",
      },
    },
    {
      header: "Main",
      size: 50,
      accessorKey: "main",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
      cell: (props) => {
        return (
          <CellModel
            modelType={MODEL_MEDIA_TYPES.MAIN}
            rowComponent={props.row.original}
          />
        );
      },
    },
    {
      header: "Helpers",
      size: 50,
      accessorKey: "helpers",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
      cell: (props) => {
        return (
          <CellModel
            modelType={MODEL_MEDIA_TYPES.HELPERS}
            rowComponent={props.row.original}
          />
        );
      },
    },
    {
      header: "Uploaded at",
      accessorKey: "createdAt",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
      cell: (props) => {
        return (
          <div>
            {dayjs(props.row.original?.createdAt).format("HH:mm DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      header: "",
      accessorKey: "action",
      enableSorting: false,
      size: 50,
      meta: {
        className: "text-black bg-gray-300",
      },
      cell: (props) => {
        return <CellEditModel rowComponent={props.row.original} />;
      },
    },
  ];

  const unitColumns = [
    {
      header: "UID",
      accessorKey: "id",
      size: 50,
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-bl-none",
      },
      cell: ({ row: { index } }) => {
        return <div className="text-left">{index + 1}</div>;
      },
    },
    {
      header: "UnitLabel",
      accessorKey: "name",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300",
      },
      // cell: ({ getValue, row }) => {
      //   return (
      //     <LabelInput
      //       field={row.original}
      //       onUpdate={({ formValue, onSuccess }) =>
      //         dispatch(
      //           updateUnit(
      //             {
      //               name: formValue.label,
      //               id: row.original.id,
      //             },
      //             () => {
      //               onSuccess();
      //               toast.success("Update unit label successfully");
      //             },
      //             () => toast.error("Update unit label failed")
      //           )
      //         )
      //       }
      //     />
      //   );
      // },
    },
    {
      header: "UnitLevel",
      accessorKey: "level",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 pl-1",
      },
    },
    {
      header: "MeshName",
      accessorKey: "3d_mesh",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
    },
    {
      header: "",
      accessorKey: "action",
      enableSorting: false,
      size: 50,
      meta: {
        className: "text-black bg-gray-300",
      },
      cell: ({ row }) => {
        return (
          <Modal
            title="Update Unit"
            maskClassName="bg-opacity-50"
            zIndex="z-[9998]"
            bodyClassName="px-4"
            triggerClassName="text-center"
            trigger={
              <Button variant="text" className="text-blue-500 border-blue-500">
                <EditIcon />
              </Button>
            }
            content={({ setIsShow }) => (
              <EditLevelUnit field={row?.original} setIsShow={setIsShow} />
            )}
          />
        );
      },
    },
  ];

  const amenitiesColumns = [
    {
      header: "UID",
      accessorKey: "id",
      size: 50,
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-bl-none ",
      },
      cell: ({ row: { index } }) => {
        return <div className="text-left">{index + 1}</div>;
      },
    },
    {
      header: "Label",
      enableSorting: false,
      accessorKey: "name",
      meta: {
        className: "text-black bg-gray-300",
      },
      // cell: ({ getValue, row }) => {
      //   return (
      //     <LabelInput
      //       field={row.original}
      //       onUpdate={({ formValue, onSuccess }) =>
      //         dispatch(
      //           updateLabelAmenity(
      //             {
      //               name: formValue.label,
      //               id: row.original.id,
      //             },
      //             () => {
      //               onSuccess();
      //               toast.success("Update amenities label successfully");
      //             },
      //             () => toast.error("Update amenities label failed")
      //           )
      //         )
      //       }
      //     />
      //   );
      // },
    },
    {
      header: "MeshName",
      enableSorting: false,
      accessorKey: "3d_mesh",
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
    },
  ];

  const transportOptionColumns = [
    {
      header: "UID",
      accessorKey: "id",
      enableSorting: false,
      size: 50,
      meta: {
        className: "text-black bg-gray-300 !rounded-bl-none",
      },
      cell: ({ row: { index } }) => {
        return <div className="text-left">{index + 1}</div>;
      },
    },
    {
      header: "Label (transport)",
      accessorKey: "name",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300",
      },
      size: 250,
      // cell: ({ getValue, row }) => {
      //   return (
      //     <LabelInput
      //       field={row.original}
      //       onUpdate={({ formValue, onSuccess }) =>
      //         dispatch(
      //           updateLabelTransportOption(
      //             {
      //               name: formValue.label,
      //               id: row.original.id,
      //               modal: row.original.modal,
      //             },
      //             () => {
      //               onSuccess();
      //               toast.success("Update transport label successfully");
      //             },
      //             () => toast.error("Update transport label failed")
      //           )
      //         )
      //       }
      //     />
      //   );
      // },
    },
    {
      header: "MeshName",
      accessorKey: "3d_meshes",
      size: 80,
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
      cell: ({ getValue, row }) => {
        return <span>{getValue().join(", ")}</span>;
      },
    },
  ];

  const envMapColumns = [
    {
      header: "UID",
      accessorKey: "id",
      size: 30,
      enableSorting: true,
      meta: {
        className: "text-black bg-gray-300 !rounded-bl-none",
      },
      cell: ({ row: { index } }) => {
        return <div className="text-left">{index + 1}</div>;
      },
    },
    {
      header: "File Name",
      accessorKey: "name",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300",
      },
    },
    {
      header: "Active",
      size: 30,
      accessorKey: "active",
      enableSorting: false,
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
      cell: (props) => {
        return (
          <CellActiveEnvmap
            rowComponent={props.row.original}
          />
        );
      },
    },
    {
      header: "Uploaded at",
      accessorKey: "createdAt",
      enableSorting: true,
      meta: {
        className: "text-black bg-gray-300 !rounded-br-none",
      },
      cell: (props) => {
        return (
          <div>
            {dayjs(props.row.original?.createdAt).format("HH:mm DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      header: "",
      accessorKey: "action",
      enableSorting: false,
      size: 20,
      meta: {
        className: "text-black bg-gray-300",
      },
      cell: (props) => {
        return <CellEditEnvMap rowComponent={props.row.original} />;
      },
    },
  ];

  return {
    _3dColumns,
    unitColumns,
    amenitiesColumns,
    transportOptionColumns,
    envMapColumns,
  };
};

export default useColumns;
