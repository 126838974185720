import {
  reqGetActiveConfig,
  reqGetCanvasProfiles,
  actSetSelectedCanvasProfileId,
} from "../../../../../../reduxs/cms/action";
import { toast } from "react-toastify";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import canvasProfileApi from "../../../../../../apis/api/canvas-profiles";
import { reqGetModelsMediaList } from "../../../../../../reduxs/media/action";

export const CellActiveEnvmap = ({ rowComponent }) => {
  const dispatch = useDispatch();

  const canvasProfiles = useSelector((state) => state.cms.canvasProfiles);
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const selectedCanvasProfileId = useSelector((state) => state.cms.selectedCanvasProfileId);

  const selectedCanvasProfile = useMemo(() => {
    if (selectedCanvasProfileId) {
      return canvasProfiles?.find(t => t.id === selectedCanvasProfileId) || null;
    }
    return null;
  }, [selectedCanvasProfileId, canvasProfiles]);

  useEffect(() => {
    if (!selectedCanvasProfileId) {
      dispatch(actSetSelectedCanvasProfileId(activeConfig?.canvasProfile?.id));
    }
  }, [selectedCanvasProfileId, activeConfig]);

  const toggleVisibleComponent = async (media, checked) => {
    try {
      if (!media?.id) return;
      const payload = {
        envMapMedia: checked ? media.id : null,
      };
      const res = await canvasProfileApi.updateCanvasProfile(selectedCanvasProfile?.id, payload);
      if (res) {
        toast.success(`Image ${media.name} set as EnvMap for ${selectedCanvasProfile?.name}`);
        dispatch(reqGetModelsMediaList());
        dispatch(reqGetCanvasProfiles());
        dispatch(reqGetActiveConfig());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isChecked = useMemo(() => {
    return rowComponent?.id === selectedCanvasProfile?.envMapMedia?.id;
  }, [selectedCanvasProfile]);

  return (
    <div className="flex justify-center relative">
      <input
        type="checkbox"
        className="w-5 h-5 rounded-full disabled:opacity-40 disabled:cursor-not-allowed disabled:bg-gray-400/30"
        checked={isChecked}
        onChange={(e) =>
          toggleVisibleComponent(
            rowComponent,
            e.target.checked
          )
        }
      />
    </div>
  );
};
