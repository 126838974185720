import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import settingModuleApi from "../../../apis/api/page-configuration";
import { reqGetModuleDetail } from "../../../reduxs/page-configuration/action";

export const typeSection = {
  left_image: "left-image",
  right_image: "right-image",
  multiple_left_image: "multi-left-image",
  multiple_right_image: "multi-right-image",
  no_image: "no-image",
};

const imageTypes = ["jpg", "jpeg", "png", "webp"];

const isFormatImage = (value) => {
  if (!value) return true;
  if (value instanceof File) {
    return imageTypes.includes(value?.name.split(".").pop().toLowerCase());
  }
  return imageTypes.includes(value?.path?.split(".").pop().toLowerCase());
};

const useFormStatic = ({ defaultValues }) => {
  const dispatch = useDispatch();

  const [loadingSave, setLoadingSave] = useState(false);
  const { id } = useParams();

  const sectionSchema = yup.object().shape({
    type: yup.string().required("Type is required"),
    title: yup.string().trim().required("Title is required"),
    description: yup.string().trim().required("Description is required"),
    tag: yup
      .string()
      .trim()
      .when("type", {
        is: (value) =>
          [
            typeSection.left_image,
            typeSection.right_image,
            typeSection.multiple_left_image,
            typeSection.multiple_right_image,
          ].includes(value),
        then: (schema) => schema.required("Tag is required"),
        otherwise: (schema) => schema.optional(),
      }),
    media: yup.mixed().when("type", {
      is: (value) =>
        [typeSection.left_image, typeSection.right_image].includes(value),
      then: (schema) =>
        schema
          .required("Media is required")
          .test("fileFormat", "Invalid file format", isFormatImage),
      otherwise: (schema) => schema.optional(),
    }),
    media1: yup.mixed().when("type", {
      is: (value) =>
        [
          typeSection.multiple_left_image,
          typeSection.multiple_right_image,
        ].includes(value),
      then: (schema) =>
        schema
          .required("Media 1 is required")
          .test("fileFormat", "Invalid file format", isFormatImage),
      otherwise: (schema) => schema.optional(),
    }),
    media2: yup.mixed().when("type", {
      is: (value) =>
        [
          typeSection.multiple_left_image,
          typeSection.multiple_right_image,
        ].includes(value),
      then: (schema) =>
        schema
          .required("Media 2 is required")
          .test("fileFormat", "Invalid file format", isFormatImage),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const validateSchema = yup.object().shape({
    sections: yup
      .array()
      .of(sectionSchema)
      .min(1, "At least one section is required"),
  });

  const form = useForm({
    defaultValues: {
      sections: [],
    },
    shouldFocusErrors: true,
    mode: "onSubmit",
    resolver: yupResolver(validateSchema),
  });

  const fieldArrayForm = useFieldArray({
    control: form.control,
    name: "sections",
  });

  useEffect(() => {
    if (defaultValues?.props?.sections) {
      form.reset({
        sections: defaultValues?.props?.sections,
      });
    }
  }, [defaultValues]);

  const addSection = (type) => {
    const commonValue = {
      title: "",
      description: "",
    };

    const commonMultiValue = {
      tag: "",
      media1: null,
      media2: null,
    };

    if (type === typeSection.no_image) {
      fieldArrayForm.append({
        type: typeSection.no_image,
        ...commonValue,
      });
    } else if (type === typeSection.left_image) {
      fieldArrayForm.append({
        type: typeSection.left_image,
        ...commonValue,
        tag: "",
        media: null,
      });
    } else if (type === typeSection.right_image) {
      fieldArrayForm.append({
        type: typeSection.right_image,
        ...commonValue,
        tag: "",
        media: null,
      });
    } else if (type === typeSection.multiple_left_image) {
      fieldArrayForm.append({
        type: typeSection.multiple_left_image,
        ...commonValue,
        ...commonMultiValue,
      });
    } else if (type === typeSection.multiple_right_image) {
      fieldArrayForm.append({
        type: typeSection.multiple_right_image,
        ...commonValue,
        ...commonMultiValue,
      });
    }
  };

  const onSave = async (v) => {
    setLoadingSave(true);
    try {
      const res = await settingModuleApi.updateModules(id, {
        name: defaultValues?.name,
        props: {
          ...defaultValues?.props,
          sections: v.sections,
        },
      });

      if (res.data) {
        dispatch(
          reqGetModuleDetail({
            idModule: id,
          })
        );
        toast.success("Save successfully!");
        setLoadingSave(false);
      }
    } catch (e) {
      toast.error("Save failed!");
      setLoadingSave(false);
    }
  };

  return {
    form,
    fieldArrayForm,
    addSection,
    loadingSave,
    onSave,
  };
};

export default useFormStatic;
