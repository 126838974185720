/** @format */

import {
  reqGetUserProfile,
  reqGetCustomerProfile,
  reqSetCustomerProfile,
} from "../../reduxs/user/action";
import { toast } from "react-toastify";
import socket from "../../helper/socket";
import React, { useEffect } from "react";
import jwtAuth from "../../apis/utils/jwtAuth";
import { classNames } from "../../helper/utils";
import { PAGES_ENUM } from "../../constants/modules";
import preferenceApi from "../../apis/api/preference";
import { useDispatch, useSelector } from "react-redux";
import { ShowcaseSelector } from "./showcase-selector";
// import { reqGetMeshes } from "../../reduxs/scene/action";
import { USER_GROUP } from "../../constants/master-data";
import { useNavigate, useLocation } from "react-router-dom";
// import { reqGetPageList } from "../../reduxs/page-configuration/action";
import { ActiveStarIcon, InActiveStarIcon, StarIcon } from "../svgs/icons";
import { ReactComponent as Logout } from "../../assets/images/icLogout.svg";
import { ReactComponent as Person } from "../../assets/images/iconPerson.svg";
import { reqSetIsShowExploreModal, reqSetPage } from "../../reduxs/home/action";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import {
  reqGetThemes,
  reqGetActiveConfig,
  reqGetConfigs,
} from "../../reduxs/cms/action";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const BottomNavigation = ({
  handleClickEndGuideTenantSession,
  handleClickTenantEndSession,
  isPresentation,
  setActiveObjectIds,
  resetState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerId = useQuery().get("customer");
  const customer = useSelector((state) => state.user.customer);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const preference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const authUser = useSelector((state) => state.user.data);
  const isShowEndGuideTenantSession = useSelector(
    (state) => state.home.isShowEndGuideTenantSession
  );
  const reactUiPage = useSelector((state) => state.home.reactUiPage);
  // const loading = useSelector((state) => state.configuration.loading);
  const pages = useSelector((state) => state.configuration.pages);

  const loadThemes = async () => {
    dispatch(reqGetThemes());
  };

  const loadConfig = async () => {
    dispatch(reqGetActiveConfig());
    dispatch(reqGetConfigs());
  };

  useEffect(() => {
    loadConfig();
    loadThemes();
    dispatch(reqGetUserProfile());
  }, []);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action == ACTION_NAME.CLICK_END_SESSION) {
      handleShowPostSessionPopup();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  useEffect(() => {
    if (customerId) {
      if (customerId !== customer?.id) {
        getCustomerPreference();
        getCustomerDetail();
      }
    } else {
      if (customer && customer?.id) {
        dispatch(reqSetCustomerProfile(""));
      }
    }
  }, [customerId, customer]);

  const onClickUserIcon = () => {
    window.location.href = "/cms";
  };

  // const handleReloadComponents = () => {
  //   loadConfig();
  //   loadThemes();
  //   dispatch(reqGetUserProfile());
  //   dispatch(reqGetPageList());
  //   dispatch(reqGetMeshes());
  // };

  const getCustomerDetail = () => {
    try {
      dispatch(reqGetCustomerProfile(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getCustomerPreference = async () => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleShowPostSessionPopup = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION);
    }
    dispatch(reqSetIsShowExploreModal(false));
    setActiveObjectIds([]);
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetPage(REACTUI_PAGES.SESSION_PAGE));
  };

  const sendToastMessage = (message, type) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
        message,
        type,
      });
    }
    toast[type](message, {
      toastId: "socket-toast",
    });
  };

  const handleSavePreference = () => {
    if (!selectedUnit) {
      return sendToastMessage("Please select an unit!", "error");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getPreferenceUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi.savePreference(data).then(() => {
        sendToastMessage("Saved Preference successfully!", "success");
        getCustomerPreference();
        analytics.track("Agent Saved Property", {
          agentId: authUser?.id,
          clientId: customer?.id,
          clientEmail: customer?.email,
          clientPhone: customer?.mobile,
          propertyId: selectedUnit?.id,
        });
      });
    }
  };

  const handleUnsavePreference = () => {
    if (!selectedUnit) {
      return sendToastMessage("Please select an unit!", "error");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getPreferenceUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi.savePreference(data).then(() => {
        sendToastMessage("Unsaved Preference successfully!", "success");
        getCustomerPreference();
        analytics.track("Agent Unsaved Property", {
          agentId: authUser?.id,
          clientId: customer?.id,
          clientEmail: customer?.email,
          clientPhone: customer?.phone,
          propertyId: selectedUnit?.id,
        });
      });
      // .then((res) => {
      //   // dispatch(unitExploreAct.reqGetUnitList(unitQuery));
      // });
    }
  };

  const getPreferenceUnitIds = () => {
    const units = preference?.units || [];

    return units.map((item) => item.id);
  };

  const handleLogout = async () => {
    setActiveObjectIds([]);
    await jwtAuth.removeToken();
    navigate("/holding", { replace: true });
    dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
  };

  const onEndGuidedSession = async (cb = () => {}) => {
    if (
      pages?.length &&
      pages?.find(
        (item) =>
          item?.name === PAGES_ENUM.POST_SESSION && !!item?.props?.visible
      )
    ) {
      cb();
    } else {
      dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
      navigate(isPresentation ? "/presentation" : "/");
      dispatch(reqSetCustomerProfile(""));
    }
  };

  const renderLogoutBtn = () => {
    if (!authUser) return;

    return (
      <div onClick={handleLogout} className="cursor-pointer logout-btn">
        <Logout className="*:fill-[var(--navigation-text-color)]" />
      </div>
    );
  };

  // const renderReload = () => {
  //   if (authUser?.userGroup?.name === USER_GROUP.ADMIN) {
  //     return (
  //       <span
  //         className="text-navigation text-right text-xs not-italic font-sans font-normal leading-[normal] tracking-[1.2px] uppercase cursor-pointer"
  //         onClick={handleReloadComponents}
  //       >
  //         {!loading ? "REFRESH UI" : ". . ."}
  //       </span>
  //     );
  //   }
  // };

  const renderCustomerName = () => {
    if (reactUiPage !== REACTUI_PAGES.ONBOARD_PAGE) {
      return (
        <span className="text-navigation font-sans text-right text-xs not-italic font-normal leading-[normal] tracking-[1.2px] uppercase">
          {customer ? `${customer?.firstname} ${customer?.surname}` : ``}
        </span>
      );
    }
  };

  const renderUserBtn = () => {
    if (reactUiPage === REACTUI_PAGES.ONBOARD_PAGE) {
      return (
        <span className="h-[50px] flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 pr-10 p-[unset]">
          <Person
            className="cursor-pointer *:fill-[var(--navigation-text-color)]"
            onClick={() => onClickUserIcon()}
          />
        </span>
      );
    }
  };

  const renderStarBtn = () => {
    if (reactUiPage === REACTUI_PAGES.ONBOARD_PAGE) return null;
    const unitIds = getPreferenceUnitIds();

    if (unitIds.includes(selectedUnit?.id)) {
      return (
        <span
          className="h-[50px] flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black"
          onClick={() => handleUnsavePreference()}
        >
          <ActiveStarIcon className="*:fill-[var(--navigation-text-color)]" />
        </span>
      );
    }

    if (selectedUnit) {
      return (
        <span
          className="h-[50px] flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black"
          onClick={() => handleSavePreference()}
        >
          <InActiveStarIcon className="*:fill-[var(--navigation-text-color)]" />
        </span>
      );
    }

    return (
      <span className="h-[50px] flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black">
        <StarIcon className="*:fill-[var(--navigation-text-color)]" />
      </span>
    );
  };

  const renderEndSessionBtn = () => {
    if (!customer || !customerId) return;

    if (
      customer?.userGroup?.name === USER_GROUP.TENANT &&
      !isShowEndGuideTenantSession
    ) {
      return (
        <span
          className="text-navigation font-sans cursor-pointer text-xs not-italic font-normal leading-[normal] tracking-[1.2px] uppercase"
          onClick={() =>
            onEndGuidedSession(() =>
              handleClickEndGuideTenantSession(customerId)
            )
          }
        >
          END GUIDED SESSION
        </span>
      );
    }

    if (
      customer?.userGroup?.name === USER_GROUP.TENANT &&
      isShowEndGuideTenantSession
    ) {
      return (
        <span
          className="text-navigation font-sans cursor-pointer text-xs not-italic font-normal leading-[normal] tracking-[1.2px] uppercase"
          onClick={() =>
            onEndGuidedSession(() => handleClickTenantEndSession())
          }
        >
          END GUIDED SESSION
        </span>
      );
    }

    return (
      <span
        className="text-navigation font-sans cursor-pointer text-xs not-italic font-normal leading-[normal] tracking-[1.2px] uppercase"
        onClick={() => onEndGuidedSession(() => handleShowPostSessionPopup())}
      >
        END GUIDED SESSION
      </span>
    );
  };

  if (isPresentation) {
    return null;
  }

  return (
    <>
      {/* {isShowFloorplan && <div className="outline" />} */}
      <div
        className={classNames(
          "items-center bg-navigation flex h-11 fixed w-full z-[1000] pl-10 border-t-navigation border-t border-solid bottom-0 justify-between",
          reactUiPage === REACTUI_PAGES.VIEWS_LEVEL ? "linear-bottom" : "",
          reactUiPage === REACTUI_PAGES.SESSION_PAGE && "hidden",
          isShowFloorplan && "bg-[#FCFCFC]"
        )}
      >
        <div className="flex items-center text-[13px] font-[bold]">
          {/* {renderLanguageBtn()} */}
          {/*{renderEyeIcon()}*/}
          {!customer && !customerId && renderLogoutBtn()}
          {/*{false && renderMakeAppointmentBtn()}*/}

          {/* Hide while Session Summary in development */}
          {renderEndSessionBtn()}
        </div>
        <ShowcaseSelector resetState={resetState} />
        <div className="flex items-center text-[13px] font-[bold] gap-[26px]">
          {renderCustomerName()}
          {/* {renderReload()} */}
          {renderStarBtn()}
          {renderUserBtn()}
        </div>
      </div>
    </>
  );
};

export default BottomNavigation;
