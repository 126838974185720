import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputField } from "../../../../components/commons";

const InfoForm = ({ setIsShow, onCreateHotspot }) => {
  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  });
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (formValues) => {
    onCreateHotspot({
      type: "info",
      ...formValues,
    });
    setIsShow(false);
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-5">Info hotspot setting</h2>
      <div className="mb-5">
        <InputField
          controller={{
            control,
            name: "title",
          }}
          inputProps={{
            placeholder: "Title",
          }}
          wrapperClassName="mb-5"
        />
        <InputField
          controller={{
            control,
            name: "description",
          }}
          inputProps={{
            placeholder: "Description",
            className: "w-full",
          }}
        />
      </div>
      <div className="flex gap-5">
        <Button
          onClick={() => setIsShow(false)}
          disabled={formState.isSubmitting}
          className="flex-1"
          variant="text"
        >
          Cancel
        </Button>
        <Button
          disabled={!formState.isDirty || formState.isSubmitting}
          isLoading={formState.isSubmitting}
          className="flex-1"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default InfoForm;
