import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { Button, InputField } from "../../components/commons";
import SelectField from "../../configuration/components/selectField";
import playListApi from "../../../../apis/api/playlist";

import { reqGetPlaylists } from "../../../../reduxs/cms/action";
import { playListTypeOptions } from "./CreatePlayListForm";

const EditPlayListForm = ({ onClose, playList }) => {
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    playlistName: yup.string().required("Playlist name is required"),
    playlistType: yup
      .object()
      .shape({
        id: yup.string().required("Playlist type is required"),
        name: yup.string().required("Playlist type name is required"),
      })
      .nullable()
      .required("Playlist type is required"),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      playlistName: playList?.title,
      playlistType:
        playListTypeOptions?.find((item) => item?.value === playList?.type) ||
        null,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleSave = async (v) => {
    const res = await playListApi.updateMediaPlaylist(playList?.id, {
      name: v.playlistName,
      type: v.playlistType.value,
    });

    if (res.data) {
      toast.success("Update playlist successfully");
      dispatch(reqGetPlaylists());
    } else {
      toast.error("Update playlist failed");
    }

    onClose();
  };

  return (
    <div className="bg-white rounded-lg  relative">
      <div className="mb-5">
        <InputField
          required
          label="Playlist name"
          controller={{ control, name: "playlistName" }}
          inputProps={{ placeholder: "Please input name of playlist" }}
        />
      </div>
      <SelectField
        disabled
        wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000]"
        labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
        controller={{ control, name: "playlistType" }}
        required
        label="Playlist type"
        options={playListTypeOptions}
        placeholder="Please select type of playlist"
      />
      <div className="flex gap-3 mt-8">
        <Button
          disabled={formState?.isSubmitting}
          variant="text"
          onClick={onClose}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          disabled={formState?.isSubmitting || !formState?.isDirty}
          isLoading={formState?.isSubmitting}
          onClick={handleSubmit(handleSave)}
          className="bg-yellow-200 text-black hover:bg-yellow-300 hover:text-black disabled:hover:bg-yellow-200 disabled:hover:text-black flex-1"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditPlayListForm;
