/** @format */

import React, {
  useState,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import { cn } from "../../../helper/utils";

const SlidingSearchInput = forwardRef(function SearchIcon(
  { wrapperClassName, formClassName, callbackOnInputChange, disabled = false },
  ref
) {
  const [searchValue, setSearchValue] = useState("");
  const refSearch = useRef();

  useImperativeHandle(ref, () => ({
    resetSearch() {
      refSearch.current.value = "";
      handleClearClick();
    },
  }));

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    callbackOnInputChange && callbackOnInputChange(e.target.value);
  };

  const handleClearClick = () => {
    setSearchValue("");
    callbackOnInputChange && callbackOnInputChange("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={cn("relative mb-2", wrapperClassName)}>
      <form onSubmit={handleSubmit} className="flex items-center">
        <div
          className={cn(
            "overflow-hidden w-64 transition-all duration-300",
            formClassName
          )}
        >
          <div className="relative">
            <input
              ref={refSearch}
              disabled={disabled}
              type="text"
              value={searchValue}
              onChange={handleInputChange}
              placeholder="Search..."
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black focus:ring-0 "
            />
            {searchValue && (
              <button
                type="button"
                onClick={handleClearClick}
                className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
});

export default SlidingSearchInput;
