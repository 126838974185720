/** @format */

import SideNav from "../../sidenav";
import UnitDetailFooter from "./footer";
import { useSelector } from "react-redux";
import socket from "../../../helper/socket";
import UnitName from "../components/unit-name";
import React, { useEffect, useRef } from "react";
import { FEATURES_ENUM } from "../../../constants/modules";
import UnitDetailCollapsible from "./unit-detail-collapsible";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";

const UnitDetail = (props) => {
  const {
    visible = false,
    featureConfig = null,
    isPresentation,
    isTransparent,
  } = props;

  const pageRef = useRef(null);

  const unit = useSelector((state) => state.unitExplore.selectedUnit);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollPercentage *
          (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
  };

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  };

  if (!visible || !featureConfig?.components?.some((c) => c?.props?.visible))
    return null;

  return (
    <div
      className={`wrap-sidenav flex absolute z-[1001] h-[calc(100svh_-_60px_-_60px)] top-0 bottom-0 mt-auto mb-auto left-auto right-0 w-[285px] ${
        isTransparent ? "invisible transform translate-x-[-100%]" : ""
      }`}
    >
      <SideNav
        id="sidenavDetail"
        position="right"
        isDark={false}
        footer={
          <UnitDetailFooter
            isPresentation={isPresentation}
            components={featureConfig?.components || []}
          />
        }
        className="!border-l-0 rounded-l-md flex w-[285px] px-[35px] py-[30px] flex-col items-start shrink-0 bg-brand"
        bodyClassName="!border-t-0"
      >
        <div
          ref={pageRef}
          onScroll={onScroll}
          className={"accordion-sidebar overflow-y-scroll disable-scroll-bar"}
        >
          <UnitName
            unit={unit}
            {...(featureConfig?.components?.find(
              (c) => c.name === UnitName.displayName
            )?.props || {})}
          />
          <UnitDetailCollapsible
            components={featureConfig?.components || []}
            isPresentation={isPresentation}
          />
        </div>
      </SideNav>
    </div>
  );
};
UnitDetail.displayName = FEATURES_ENUM.UNIT_DETAIL;

export default UnitDetail;
