import * as Types from './type';
import mediaApi from '../../apis/api/media';

const actGetMediaList = (data) => ({
  type: Types.GET_MEDIA_LIST,
  data,
});

const actGetModelsMediaList = (data) => ({
  type: Types.GET_MODELS_MEDIA_LIST,
  data,
});

const actGetEnvMapsMediaList = (data) => ({
  type: Types.GET_ENVMAPS_MEDIA_LIST,
  data,
});

export const reqGetMediaList = (query) => (dispatch) => {
  return mediaApi
    .getMediaList(query)
    .then((data) => {
      dispatch(actGetMediaList(data));
    })
    .catch(() => {});
};

export const reqGetModelsMediaList = () => (dispatch) => {
  return mediaApi
    .getMediaList({ "type[equal]": "3d_model" })
    .then((data) => {
      dispatch(actGetModelsMediaList(data));
    })
    .catch(() => {});
};

export const reqGetEnvMapsMediaList = () => (dispatch) => {
  return mediaApi
    .getMediaList({ "type[equal]": "env_map" })
    .then((data) => {
      dispatch(actGetEnvMapsMediaList(data));
    })
    .catch(() => {});
};

export default reqGetMediaList;
