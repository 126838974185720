/** @format */

import { Modal } from "../../components/commons";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatePlayListForm from "../../media/left-panel/CreatePlayListForm";
import useDebouncedSearch from "../../../../hooks/useDebouncedSearch";
import {
  reqGetListUnits,
  reqGetPlaylists,
} from "../../../../reduxs/cms/action";
import { cn } from "../../../../helper/utils";
import Search from "../../gallery/Search";

const Sidebar = ({ playlistState, setPlaylistState }) => {
  const dispatch = useDispatch();
  const [search, setSearch, loading] = useDebouncedSearch();

  const playlists = useSelector((state) => state.cms.playlist);

  const getListProperties = () => {
    dispatch(
      reqGetListUnits({
        sortBy: JSON.stringify({
          name: 1,
        }),
      })
    );
  };

  const getListPlaylists = () => {
    dispatch(reqGetPlaylists());
  };

  const playlistConfig = useMemo(() => {
    const result = playlists?.data
      ?.map((playlist) => ({
        title: playlist.name,
        path: playlist.id,
        mediaType: playlist.type,
        type: "menu",
      }))
      ?.sort((a, b) => a.title.localeCompare(b.title));
    if (search) {
      return (
        result?.filter((p) =>
          p?.title?.toLowerCase()?.includes(search?.toLowerCase())
        ) || []
      );
    } else {
      return result;
    }
  }, [playlists, search]);

  useEffect(() => {
    getListProperties();
    getListPlaylists();
  }, []);

  useEffect(() => {
    if (playlistConfig?.length > 0) {
      setPlaylistState((prev) => {
        if (playlistConfig?.some((p) => p?.path === prev?.path)) {
          return prev;
        }
        return playlistConfig[0];
      });
    }
  }, [playlistConfig]);

  return (
    <div className="flex flex-col w-72 text-white rounded-lg bg-white">
      <div className="mt-4">
        <div className="flex items-center justify-between rounded-lg h-12 px-4 bg-white">
          <h1 className="text-xl text-black font-semibold">{"Playlists"}</h1>
          <Modal
            maskClosable
            maskClassName="bg-opacity-50"
            zIndex="z-[9998]"
            title="Create new playlist"
            triggerClassName={"h-fit"}
            trigger={
              <button
                className={`w-full flex items-center p-2 rounded text-black hover:!text-white hover:!bg-black`}
              >
                <div className="border-b border-b-black text-sm font-medium font-sans tracking-normal leading-none">
                  Create
                </div>
              </button>
            }
            content={({ setIsShow }) => (
              <CreatePlayListForm onClose={() => setIsShow(false)} />
            )}
          />
        </div>
        <div className="h-[2px] my-3 mx-4 rounded-xl bg-gray-300" />
        <Search
          wrapperClassName={"w-full px-4"}
          callbackOnInputChange={setSearch}
        />
        <nav className="flex-1 px-4 py-2 overflow-y-auto pb-4 h-[calc(100vh-170px-80px)]">
          <div className="space-y-2">
            {loading ? (
              <div className="text-black w-full text-center">. . .</div>
            ) : (
              playlistConfig?.map((item, index) => {
                if (item.type === "menu") {
                  return (
                    <button
                      key={`playlist-${index}`}
                      className={cn(
                        `w-full text-black flex-wrap flex justify-between items-center p-3 text-sm font-medium text-left bg-purple-200 hover:bg-purple-300 rounded`,
                        {
                          "bg-green-600 hover:bg-green-600 text-white font-semibold":
                            item?.path === playlistState?.path,
                        }
                      )}
                      onClick={() => setPlaylistState(item)}
                    >
                      <div className="flex flex-wrap flex-1">
                        <span>{item?.title}</span>
                      </div>
                    </button>
                  );
                }
              })
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
