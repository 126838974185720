/** @format */

import React, { useEffect } from "react";

import PlaylistContent from "./PlaylistContent";
import AssetContent from "./AssetContent";
import { useDispatch, useSelector } from "react-redux";

import { reqGetDetailPlaylists } from "../../../../reduxs/cms/action";
import { DragDropContext } from "@hello-pangea/dnd";
import playlist from "../../../../apis/api/playlist";
import { toast } from "react-toastify";
import playlistApi from "../../../../apis/api/playlist";

const UpsertPlayList = (props) => {
  const { playlistId } = props;
  const dispatch = useDispatch();
  const playListRef = React.useRef(null);
  const { playlistDetail } = useSelector((state) => state.cms);

  const getPlayListDetail = () => dispatch(reqGetDetailPlaylists(playlistId));

  useEffect(() => {
    playlistId && getPlayListDetail();
  }, [playlistId]);

  const onDragEnd = async (result) => {
    //Change order media in playList
    if (
      result?.source?.droppableId === "gallery-list" &&
      result?.destination?.droppableId === "gallery-list"
    ) {
      playListRef.current && (await playListRef.current.triggerDragEnd(result));
    }

    //add media to playList
    if (
      result?.source?.droppableId === "asset-content" &&
      result?.destination?.droppableId === "gallery-list"
    ) {
      const idMedia = result?.draggableId.split("_")[0];

      if (!playlistId) {
        toast.warn("Please select playlist first");
        return;
      }

      const res = await playlist.addFilesToPlayList(playlistId, {
        medias: [idMedia],
        position: result.destination.index,
      });

      if (res?.data) {
        toast.success("Add new media successfully!");

        getPlayListDetail();
      } else {
        toast.error("Add media failed!");
      }
    }

    //remove media from playList
    if (
      result?.source?.droppableId === "gallery-list" &&
      result?.destination?.droppableId === "asset-content"
    ) {
      const idMedia = result?.draggableId;
      const res = await playlistApi.removeFilesFromPlayList(playlistId, {
        medias: [idMedia],
      });

      if (res?.data) {
        toast.success("Remove media successfully!");
        getPlayListDetail();
      } else {
        toast.error("Remove media failed!");
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="max-h-[85vh] gap-5 flex flex-row ">
        <div className="h-[80vh]  border-solid flex-1 bg-white rounded-[20px] p-5 relative overflow-hidden">
          <PlaylistContent {...props} {...playlistDetail} ref={playListRef} />
        </div>
        <div className="h-[80vh] w-[5px] bg-[rgb(227,227,227)]" />
        <div className="h-[80vh] flex-1 bg-white rounded-[20px] p-5 relative overflow-hidden ">
          <AssetContent
            {...props}
            medias={playlistDetail?.medias}
            mediaType={playlistDetail?.type}
          />
        </div>
      </div>
    </DragDropContext>
  );
};

export default UpsertPlayList;
