import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  actSetGallery,
  reqGetGallery,
} from "../../../../reduxs/gallery/action";
import UpsertGalleryPlaylist from "../upsertGalleryPlaylist";

const GalleryTypeA = ({ typeAEntity }) => {
  const dispatch = useDispatch();
  const { gallery: galleryRes } = useSelector((state) => state.gallery);

  const getGallery = async (id) => {
    await dispatch(reqGetGallery(id));
  };

  useEffect(() => {
    //clear gallery response
    dispatch(
      actSetGallery({
        data: [],
      })
    );
  }, []);

  useEffect(() => {
    typeAEntity?.galleryId && getGallery(typeAEntity?.galleryId);
  }, [typeAEntity]);

  return (
    <div>
      <div className="bg-white rounded-lg py-2 px-4">
        <div className="h-[calc(100vh-180px)] overflow-hidden">
          <UpsertGalleryPlaylist
            mediaType={typeAEntity?.type}
            gallery={typeAEntity}
            playlistId={galleryRes?.playlist?.id}
          />
        </div>
      </div>
    </div>
  );
};

export default GalleryTypeA;
