import React from "react";
import { cn } from "../../../../helper/utils";

const SkeletonLoading = (props) => {
  return (
    <div
      className={cn(
        "relative overflow-hidden h-[200px] rounded-lg",
        props?.wrapperClassName
      )}
    >
      <div
        className="absolute inset-0 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-shimmer"
        style={{
          backgroundSize: "200% 100%",
          animation: "shimmer 1.5s infinite",
        }}
      />
    </div>
  );
};

export default SkeletonLoading;
