import HotspotBase from "./Base";
import hotspotImg from "../../../../../assets/images/cms/hotspot.png";

// type Props = {
//     index: number;
//     data: any;
//     onClick?: ({content}: {index: number, isVisible: boolean}) => void; //TODO: Handle with socket when link hotspot
//     className?: string;
// }

const LinkHotspot = ({ index, className, onClick, data }) => {
  const hotspotElement = HotspotBase({
    className,
    type: "link",
    index,
    bgImage: hotspotImg,
  });

  onClick &&
    hotspotElement.addEventListener("click", () => {
      onClick(data);
    });

  return hotspotElement;
};

export default LinkHotspot;
