import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditAmenityModal from "../EditAmenityModal";
import { reqGetListAmenities } from "../../../../reduxs/cms/action";
import Row from "./Row";

const Table = () => {
  const dispatch = useDispatch();
  const amenities = useSelector((state) => state.cms.amenities);

  const [isShowEditAmenityModal, setIsShowEditAmenityModal] = useState(false);
  const [selectedAmenity, setSelectedAmenity] = useState(null);

  const onSaveSuccess = () => {
    dispatch(
      reqGetListAmenities({
        sortBy: JSON.stringify({
          name: 1,
        }),
      })
    );
  };

  const onEditAmenity = (amenity) => {
    setSelectedAmenity(amenity);
    setIsShowEditAmenityModal(true);
  };

  const renderListProperty = () => {
    return (amenities || []).map((item, index) => {
      return (
        <Row
          data={item}
          key={item.id}
          index={index}
          onEditAmenity={onEditAmenity}
        />
      );
    });
  };

  return (
    <div className="table-responsive-custom w-full p-[100px] pb-0">
      <table className="table-custom text-[#1A1A1A] text-sm font-poppins">
        <thead>
          <Row head />
        </thead>
        <tbody
          style={{
            display: "block",
            maxHeight: "calc(100vh - 430px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {renderListProperty()}
        </tbody>
      </table>
      <EditAmenityModal
        show={isShowEditAmenityModal}
        setShow={setIsShowEditAmenityModal}
        amenity={selectedAmenity}
        onSaveSuccess={onSaveSuccess}
      />
    </div>
  );
};

export default Table;
