import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const clienst = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getTransportOptions = (data) =>
  defaultClient.get(endPoint.TRANSPORT_OPTIONS, data);

//interface dataEditTransportOptions = {
//   name?: string,
//   activeColor?: string
//   3d_meshes?: string[]
//   modal?: string
// }
const editTransportOptions = (id, data) =>
  defaultClient.put(`${endPoint.TRANSPORT_OPTIONS}/${id}`, data);

const getTransportOptionDistricts = (data) =>
  defaultClient.get(endPoint.TRANSPORT_OPTION_DISTRICTS, data);
const getAmenitiesOptions = (data) =>
  defaultClient.get(endPoint.AMENITIES_OPTIONS, data);

export default {
  getTransportOptions,
  getTransportOptionDistricts,
  getAmenitiesOptions,
  editTransportOptions,
};
