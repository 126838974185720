import endPoint from '../endPoint';
import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const listCanvasProfiles = () =>
    defaultClient.get(endPoint.LIST_CANVAS_PROFILES);

const updateCanvasProfile = (id, data) =>
    defaultClient.put(endPoint.UPDATE_CANVAS_PROFILE.replace(':id', id), data);

export default {
    listCanvasProfiles,
    updateCanvasProfile,
};
