import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Switch from "../components/switch";
import settingModuleApi from "../../../../apis/api/page-configuration";
import {
  reqDeleteModule,
  reqGetPageList,
} from "../../../../reduxs/page-configuration/action";
import template1 from "../../../../assets/images/template-1.png";
import template2 from "../../../../assets/images/template-2.png";
import { Button, Modal } from "../../components/commons";
import TrashIcon from "../components/svgs/TrashIcon";

const StaticPageTemplate = ({ moduleState, setModuleState }) => {
  const dispatch = useDispatch();
  const typeTemplate = moduleState?.props?.type;

  const template = {
    "template-1": {
      label: "Template 1",
      src: template1,
    },
    "template-2": {
      label: "Template 2",
      src: template2,
    },
  };

  const toggleVisibleModule = async (visible) => {
    try {
      const res = await settingModuleApi.updateModules(moduleState.id, {
        props: {
          visible,
        },
      });

      if (res.data) {
        await dispatch(reqGetPageList({}));
        setModuleState({
          ...moduleState,
          visible,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mx-auto max-w-[1000px] 2xl:max-w-[1200px] my-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-4 items-center">
          <h2>{moduleState?.label}</h2>
          <Switch
            onChange={(e) => toggleVisibleModule(e.target.checked)}
            defaultChecked={moduleState?.visible}
            checked={moduleState?.visible}
            value={moduleState?.value}
            id={`${moduleState?.id}`}
          />
        </div>
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          triggerClassName="text-red-500"
          trigger={
            <Button variant="text" className="text-red-500 border-red-500">
              <TrashIcon />
            </Button>
          }
          content={({ setIsShow }) => (
            <div>
              <h2 className="text-center pb-3 text-lg font-medium">
                Are you sure you want to delete this page ?
              </h2>
              <div className="flex gap-4 justify-center">
                <Button variant="text" onClick={() => setIsShow(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    dispatch(
                      reqDeleteModule(moduleState?.id, () => {
                        setIsShow(false);
                        toast.success("Delete successfully");
                        setModuleState(null);
                      })
                    );
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
        />
      </div>
      <div className="mt-5 pb-20 max-w-[400px] mx-auto">
        <h2 className="text-2xl text-center font-semibold">
          {template[typeTemplate]?.label}
        </h2>
        <div className="h-[550px]">
          <img
            src={template[typeTemplate]?.src}
            alt="template"
            className="h-full mb-10 object-contain w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default StaticPageTemplate;
