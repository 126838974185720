import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import MediasContent from "./MediasContent";
import useDebouncedSearch from "../../../../../hooks/useDebouncedSearch";
import Search from "../../../gallery/Search";
import InputField from "../../../components/commons/inputField";
import { Button, Modal } from "../../../components/commons";
import playListApi from "../../../../../apis/api/playlist";
import { reqGetPlaylists } from "../../../../../reduxs/cms/action";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//Props ={
// playlistId: string;
//medias: any[];
//type: string;
//name: string;
// }

const schema = yup.object().shape({
  name: yup.string().required("Playlist Name is required"),
});

const PlayListContent = ({ playlistId, type: mediaType, ...rest }, ref) => {
  const dispatch = useDispatch();
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const refSearch = useRef();

  const { control, reset, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const [search, setSearch, loading] = useDebouncedSearch();

  const isLoadingTable = loading;

  const medias = useMemo(() => {
    const newMedias = !search
      ? rest?.medias || []
      : (rest?.medias || [])?.filter((media) =>
          media?.name?.toLowerCase()?.includes(search?.toLowerCase())
        );

    return newMedias;
  }, [search, rest?.medias]);

  useEffect(() => {
    refSearch.current?.resetSearch();
  }, [playlistId]);

  useEffect(() => {
    reset({
      name: rest?.name,
    });
  }, [rest?.name]);

  const onDelete = async ({ onSuccess, onError }) => {
    setLoadingDelete(true);
    try {
      const res = await playListApi.deletePlaylist(playlistId);
      if (res) {
        if (!res?.data) {
          toast.warn(
            "Can not delete playlist, playlist is used by other Gallery"
          );
        } else {
          toast.success("Delete playlist successfully");
          dispatch(reqGetPlaylists());
        }
        onSuccess && onSuccess();
        setLoadingDelete(false);
      }
    } catch (error) {
      onError && onError();
      toast.error("Delete playlist failed");
      setLoadingDelete(false);
    }
  };

  const onSave = async (v) => {
    const res = await playListApi.updateMediaPlaylist(playlistId, {
      name: v.name,
      type: rest?.type,
    });

    if (res.data) {
      toast.success("Update playlist successfully");
      dispatch(reqGetPlaylists());
    } else {
      toast.error("Update playlist failed");
    }
  };

  return (
    <section className="h-full flex flex-col">
      <div className="flex flex-row mt-1 mb-2 items-center gap-5">
        <div className="flex-1">
          <InputField
            inputClassName="border-gray-300"
            controller={{
              control,
              name: "name",
            }}
            disabled={!playlistId}
            inputProps={{
              placeholder: "Name of playlist",
            }}
          />
        </div>
        <Modal
          disabled={!playlistId}
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          maskClosable
          triggerClassName="h-auto"
          trigger={
            <Button
              disabled={!playlistId}
              variant="text"
              className="py-[10px] border-transparent underline "
            >
              Delete
            </Button>
          }
          content={({ setIsShow }) => (
            <div className="bg-white  w-full max-w-md">
              <h2 className="text-md text-black font-semibold text-center">
                Are you sure you want to delete this playlist?
              </h2>
              <div className="flex justify-center mt-6 space-x-4">
                <Button
                  disabled={loadingDelete}
                  className="bg-gray-200 px-4 py-2 flex-1 rounded hover:bg-gray-300 text-black"
                  onClick={() => setIsShow(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loadingDelete}
                  isLoading={loadingDelete}
                  className=" text-white flex-1 px-4 py-2 rounded bg-red-500 hover:bg-red-700"
                  onClick={() =>
                    onDelete({
                      onSuccess: () => setIsShow(false),
                      onError: () => setIsShow(false),
                    })
                  }
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
        />

        <Button
          isLoading={formState?.isSubmitting}
          onClick={handleSubmit(onSave)}
          disabled={!formState?.isDirty}
          variant="text"
          className="py-[10px] border-transparent underline"
        >
          {!formState?.isSubmitting && "Save"}
        </Button>
      </div>
      <Search
        callbackOnInputChange={setSearch}
        formClassName="w-full"
        disabled={!rest?.medias?.length}
        ref={refSearch}
      />
      <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
      <div className="content overflow-y-scroll overflow-x-hidden h-[68vh]">
        <MediasContent
          mediaType={mediaType}
          medias={medias}
          isLoadingTable={isLoadingTable}
          playlistId={playlistId}
          ref={ref}
        />
      </div>
    </section>
  );
};

export default forwardRef(PlayListContent);
