import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { Button, InputField } from "../../components/commons";
import SelectField from "../../configuration/components/selectField";
import playListApi from "../../../../apis/api/playlist";

import { reqGetPlaylists } from "../../../../reduxs/cms/action";
import { MEDIA_TYPE } from "../../utils";

export const playListTypeOptions = [
  { id: 1, value: MEDIA_TYPE.IMAGE, name: "Images" },
  { id: 2, value: MEDIA_TYPE.VIDEO, name: "Videos" },
  { id: 3, value: MEDIA_TYPE.VIRTUALTOURS, name: "Virtual Tours" },
  { id: 4, value: MEDIA_TYPE.FLOORPLANS, name: "Floorplans" },
  { id: 5, value: MEDIA_TYPE.PARONAMIC, name: "Paronamic" }, // panoramic (plural panoramics)
  { id: 7, value: MEDIA_TYPE.VIEWLINES, name: "View lines" },
  { id: 8, value: MEDIA_TYPE["360IMAGE"], name: "360 Images" },
];

const CreatePlayListForm = ({ onClose }) => {
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    playlistName: yup.string().required("Playlist name is required"),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      playlistName: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const handleCreate = async (v) => {
    const res = await playListApi.createPlaylist({
      name: v.playlistName,
    });

    if (res.data) {
      toast.success("Create playlist successfully");
      dispatch(reqGetPlaylists());
    } else {
      toast.error("Create playlist failed");
    }
    onClose();
  };

  return (
    <div className="bg-white rounded-lg  relative">
      <div className="mb-5">
        <InputField
          required
          label="Playlist name"
          controller={{ control, name: "playlistName" }}
          inputProps={{ placeholder: "Please input name of playlist" }}
        />
      </div>
      {/*<SelectField*/}
      {/*  wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000]"*/}
      {/*  labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"*/}
      {/*  controller={{ control, name: "playlistType" }}*/}
      {/*  required*/}
      {/*  label="Playlist type"*/}
      {/*  options={playListTypeOptions}*/}
      {/*  placeholder="Please select type of playlist"*/}
      {/*/>*/}
      <div className="flex gap-3 mt-8">
        <Button
          disabled={formState?.isSubmitting}
          variant="text"
          onClick={onClose}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          disabled={formState?.isSubmitting || !formState?.isDirty}
          isLoading={formState?.isSubmitting}
          onClick={handleSubmit(handleCreate)}
          className="bg-yellow-200 text-black hover:bg-yellow-300 hover:text-black disabled:hover:bg-yellow-200 disabled:hover:text-black flex-1"
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreatePlayListForm;
