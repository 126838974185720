import List from "./list";
import Select from "react-select";
import { toast } from "react-toastify";
import ListModels from "./list-models";
import ListEnvMaps from "./list-env-maps";
import ColorInput from "./forms/color-input";
import Switch from "../../components/switch";
import configApi from '../../../../../apis/api/config';
import { useDispatch, useSelector } from "react-redux";
import NewCreatingForm from "./forms/new-creating-form";
import { Add3DModelModal } from "./modals/add-model-modal";
import { AddEnvMapModal } from "./modals/add-envmap-modal";
import { Button, Modal } from "../../../components/commons";
import { fetch3DSettings } from "../../../../../reduxs/scene/action";
import canvasProfileApi from '../../../../../apis/api/canvas-profiles';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getListInfoInThreeDCanvas } from "../../../../../reduxs/page-configuration/action";
import { reqGetEnvMapsMediaList, reqGetModelsMediaList } from "../../../../../reduxs/media/action";
import { actSetSelectedCanvasProfileId, reqGetCanvasProfiles, reqGetActiveConfig } from "../../../../../reduxs/cms/action";

const ContentSideCanvas = ({ pageState }) => {
  const dispatch = useDispatch();
  const models = useSelector((state) => state.media.models);
  const envmaps = useSelector((state) => state.media.envmaps);
  const canvasProfiles = useSelector((state) => state.cms.canvasProfiles);
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const selectedCanvasProfileId = useSelector((state) => state.cms.selectedCanvasProfileId);

  const [isAddingModel, setIsAddingModel] = useState(false);
  const [isAddingEnvMap, setIsAddingEnvMap] = useState(false);

  const canvasProfileOptions = useMemo(() => {
    return canvasProfiles
      ?.filter(t => !!t.isActive)
      ?.map(t => ({
        value: t.id,
        label: t.name,
      }));
  }, [canvasProfiles]);

  const selectedCanvasProfile = useMemo(() => {
    const profile = canvasProfiles?.find(t => t.id === selectedCanvasProfileId);
    return profile || canvasProfiles?.[0] || null;
  }, [selectedCanvasProfileId, canvasProfiles]);

  useEffect(() => {
    if (!selectedCanvasProfileId) {
      dispatch(actSetSelectedCanvasProfileId(activeConfig?.canvasProfile?.id));
    }
  }, [selectedCanvasProfileId, activeConfig]);

  useEffect(() => {
    pageState?.name === "canvas" &&
      (() => {
        dispatch(getListInfoInThreeDCanvas());
        dispatch(reqGetModelsMediaList());
        dispatch(reqGetEnvMapsMediaList());
        dispatch(fetch3DSettings());
      })();
  }, [pageState]);

  useEffect(() => {
    loadCanvasProfiles();
    loadConfig();
  }, []);

  const loadConfig = async () => {
    dispatch(reqGetActiveConfig());
  };

  const loadCanvasProfiles = async () => {
    dispatch(reqGetCanvasProfiles());
  };

  const handleSwitchProfile = useCallback(async () => {
    try {
      const res = await configApi.updateConfig(activeConfig?.id, { canvasProfile: selectedCanvasProfile.id });
      toast.success(`Canvas profile switched to ${selectedCanvasProfile.name}`);
      if (res && res.data) {
        loadConfig();
      }
    } catch (error) {
      console.log({ error });
      toast.error("Canvas profile update failed");
    }
  }, [selectedCanvasProfile]);

  const updateShowDebugWindow = useCallback(async (e) => {
    try {
      const res = await canvasProfileApi.updateCanvasProfile(
        selectedCanvasProfile?.id,
        { showDebug: e?.target?.checked },
      );
      toast.success(`Updated profile ${selectedCanvasProfile.name}: Debug ${e?.target?.value}`);
      if (res && res.data) {
        loadConfig();
        loadCanvasProfiles();
      }
    } catch (error) {
      console.log({ error });
      toast.error(`Update profile ${selectedCanvasProfile.name} failed`);
    }
  }, [selectedCanvasProfile]);

  return (
    <div className=" h-[calc(100vh-132px-80px)]  overflow-y-scroll custom-scrollbar-10">
      <div className="mx-auto max-w-[1000px] 2xl:max-w-[1300px] mt-4 my-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">Canvas Profile</h2>
          <div className="flex items-center gap-3">
            <Select
              value={{ value: selectedCanvasProfile?.id, label: selectedCanvasProfile?.name }}
              styles={{ zIndex: 100000 }}
              isSearchable={false}
              isClearable={false}
              menuPlacement="auto"
              options={canvasProfileOptions}
              onChange={(e) => {
                dispatch(actSetSelectedCanvasProfileId(e.value));
              }}
            />
            {/* <Button
              disabled={activeConfig?.canvasProfile?.id === selectedCanvasProfileId}
              className="w-fit h-fit py-2 px-4 disabled:bg-transparent disabled:text-black disabled:border-2 disabled:opacity-1"
              onClick={handleSwitchProfile}
            >
              {activeConfig?.canvasProfile?.id === selectedCanvasProfileId
                ? 'Profile in use'
                : 'Switch profile'
              }
            </Button> */}
          </div>
        </div>
        <div className="flex items-center gap-4 mt-2">
          <div className="text-base font-semibold">Debug Controller</div>
          <div className="flex items-center gap-3">
            <Switch
              onChange={(e) => updateShowDebugWindow(e)}
              checked={selectedCanvasProfile?.showDebug}
              defaultChecked={selectedCanvasProfile?.showDebug}
            />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-[1000px] 2xl:max-w-[1300px] my-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">3D Model Assets</h2>
          <Button onClick={() => setIsAddingModel(true)}>Add file</Button>
        </div>
        <ListModels data={models} />
      </div>
      <div className="grid grid-cols-5 mt-10 gap-10 mx-auto max-w-[1000px] 2xl:max-w-[1300px]">
        <div className="flex flex-col col-start-1 col-end-3 mx-auto w-full 2xl:max-w-[750px]">
          <div className="flex justify-between items-center h-9">
            <h2 className="text-xl font-semibold">Canvas Background</h2>
          </div>
          
          <div className="w-full mt-5 mb-10">
            <div className="bg-gray-100">
              <ColorInput
                canvasProfile={selectedCanvasProfile}
                themeId={''}
                onSuccess={() => {
                  loadConfig();
                  loadCanvasProfiles();
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col col-start-3 col-end-6 mx-auto w-full max-w-[1000px] 2xl:max-w-[750px]">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">Canvas ENV Map</h2>
            <Button onClick={() => setIsAddingEnvMap(true)}>Add image</Button>
          </div>
          <ListEnvMaps data={envmaps} />
        </div>
      </div>
      <div className="mx-auto max-w-[1000px] 2xl:max-w-[1300px] my-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Canvas Configurator</h2>
          <Modal
            maskClosable
            maskClassName="bg-opacity-50"
            trigger={<Button>GENERATE DATA</Button>}
            content={(props) => <NewCreatingForm {...props} />}
          />
        </div>
        <List />
      </div>
      {isAddingModel && (
        <Add3DModelModal
          mediaType={'3d_model'}
          show={isAddingModel}
          setShow={setIsAddingModel}
          onCreateSuccess={() => { dispatch(reqGetModelsMediaList()) }}
        />
      )}
      {isAddingEnvMap && (
        <AddEnvMapModal
          show={isAddingEnvMap}
          setShow={setIsAddingEnvMap}
          onCreateSuccess={() => { dispatch(reqGetEnvMapsMediaList()) }}
        />
      )}
    </div>
  );
};

export default ContentSideCanvas;
