import * as Types from "./type";
import modalApi from "../../apis/api/modal";

export const actSetExploreModal = (data) => ({
  type: Types.SET_EXPLORE_MODAL,
  data,
});

export const actSetDetailModal = (data) => ({
  type: Types.SET_DETAIL_MODAL,
  data,
});

export const actSetIsShowExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_EXPLORE_MODAL,
  data,
});

export const actSetIsShowExpandAmenity = (data) => ({
  type: Types.SET_IS_SHOW_EXPAND_AMENITY,
  data,
});

export const actSetIsShowAmenityVirtualTour = (data) => ({
  type: Types.SET_IS_SHOW_AMENITY_VIRTUAL_TOUR,
  data,
});

export const reqSetExploreModal =
  (id, modalList = []) =>
  (dispatch) => {
    if (!id) return;

    if (modalList.length) {
      return dispatch(
        actSetExploreModal({
          data: modalList.find((m) => m.id === id) || null,
        })
      );
    }

    return modalApi.getModalDetail(id).then((data) => {
      dispatch(actSetExploreModal(data));
    });
  };

export const reqSetDetailModal = (data) => (dispatch) => {
  return dispatch(actSetDetailModal(data));
};

export const reqSetIsShowExploreModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowExploreModal(data));
};

export const reqSetIsShowExpandAmenity = (data) => (dispatch) => {
  return dispatch(actSetIsShowExpandAmenity(data));
};

export const reqSetIsShowAmenityVirtualTour = (data) => (dispatch) => {
  return dispatch(actSetIsShowAmenityVirtualTour(data));
};
