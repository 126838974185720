/** @format */
import folderApi from "../../../../apis/api/folder";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../components/commons";
import { actSetFolders } from "../../../../reduxs/cms/action";
import { useState } from "react";

const DeleteFolder = ({ onClose, item }) => {
  const folders = useSelector((state) => state.cms.folders);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteFolder = async () => {
    setLoading(true);
    try {
      const res = await folderApi.deleteFolder(item.id);
      if (res.data) {
        const newArray = [...folders];
        const objIndex = newArray.findIndex((obj) => obj.id == item.id);
        newArray.splice(objIndex, 1);
        dispatch(actSetFolders(newArray));
        toast.success("Delete folder successfully");
      } else {
        toast.error(res.toString());
      }
    } catch {
      toast.error("Delete folder failed");
    } finally {
      onClose();
      setLoading(false);
    }
  };

  return (
    <div className="bg-white  w-full max-w-md">
      <h2 className="text-md text-black font-semibold text-center">
        Are you sure you want to delete this folder?
      </h2>
      <div className="flex justify-center mt-6 space-x-4">
        <Button
          className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 text-black"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="bg-yellow-200 text-black px-4 py-2 rounded hover:bg-yellow-300"
          onClick={handleDeleteFolder}
          isLoading={loading}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteFolder;
