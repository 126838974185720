import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../../components/commons";
import EditIcon from "../../../components/svgs/EditIcon";
import { EditEnvmapMediaModal } from "../modals/edit-envmap-modal";
import { reqGetEnvMapsMediaList } from "../../../../../../reduxs/media/action";

export const CellEditEnvMap = ({ rowComponent }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="text"
        className="text-blue-500 border-blue-500"
        onClick={() => setShow(!show)}
      >
        <EditIcon />
      </Button>

      {
        show &&
        <EditEnvmapMediaModal
          content={rowComponent}
          show={show}
          setShow={setShow}
          onSaveSuccess={() => { dispatch(reqGetEnvMapsMediaList()) }}
        />
      }
    </>
  );
};
