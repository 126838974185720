/** @format */

import HotspotBase from "./Base";
import infoImg from "../../../../../assets/images/cms/info.png";

// type Props = {
//     index: number;
//     data: any;
//     onClick?: ({content}: {index: number, isVisible: boolean}) => void; //TODO: Handle with socket when open modal of info hotspot
//     className?: string;
// }

const InfoHotspot = ({ className, index, data, onClick }) => {
  const hotspotElement = HotspotBase({
    className: `${className} w-10 h-10`,
    type: "info",
    index,
    bgImage: infoImg,
  });

  // create popup element when hotspot is hovered
  const popupElement = document.createElement("div");
  popupElement.className =
    "popup absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2  p-2 rounded shadow-md";
  popupElement.style.display = "none";
  popupElement.style.width = "150px";

  popupElement.innerHTML = `
    <div>
     <div class="text-xs bg-black text-white p-2">${data?.title}</div>
     <div class="text-xs bg-white text-black p-2">${data?.description}</div>
    </div>
  `;

  const togglePopup = () => {
    const isVisible = popupElement.style.display === "block";
    popupElement.style.display = isVisible ? "none" : "block";
    onClick && onClick({ content: { isVisible: !isVisible, index } });
  };

  hotspotElement.addEventListener("click", togglePopup);

  hotspotElement.setPopupVisibility = (isVisible) => {
    popupElement.style.display = isVisible ? "block" : "none";
  };

  hotspotElement.appendChild(popupElement);

  return hotspotElement;
};

export default InfoHotspot;
