import ConfigCard from "./config-card";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqGetCanvasProfiles, reqGetActiveConfig, reqGetConfigs, reqGetThemes } from "../../../../reduxs/cms/action";

const ContentSideShowcases = () => {
  const dispatch = useDispatch();

  const configs = useSelector((state) => state.cms.configs);

  useEffect(() => {
    loadConfigs();
    dispatch(reqGetThemes());
    dispatch(reqGetCanvasProfiles());
  }, []);

  const loadConfigs = () => {
    dispatch(reqGetConfigs());
    dispatch(reqGetActiveConfig());
  }

  return (
    <div className=" h-[calc(100vh-132px-80px)]  overflow-y-scroll custom-scrollbar-10">
      <div className="mx-auto max-w-[1000px] 2xl:max-w-[1300px] mt-8 my-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">Showcase Module Settings</h2>
        </div>
      </div>
      <div className="grid grid-cols-2 mt-6 gap-10 mx-auto max-w-[1000px] 2xl:max-w-[1300px]">
        {configs?.map((config, i) => (
          <ConfigCard key={i} config={config} loadConfigs={loadConfigs} />
        ))}
      </div>
    </div>
  );
};

export default ContentSideShowcases;
