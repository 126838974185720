import LeftPanel from "./left-panel";
import { useState } from "react";
import UpsertPlayList from "./upsertPlaylist";

export const CMSPlaylist = () => {
  const [playlistState, setPlaylistState] = useState(null);

  return (
    <section className="flex h-[calc(100vh-80px)] bg-[rgb(244,244,244)] p-4 gap-4">
      <LeftPanel
        playlistState={playlistState}
        setPlaylistState={setPlaylistState}
      />
      <div className="relative bg-white rounded-lg right-content flex-1 pt-5 overflow-hidden">
        <UpsertPlayList playlistId={playlistState?.path} />
      </div>
    </section>
  );
};
