/** @format */

import React, { useEffect, useState } from "react";
import { getS3BEMediaUrl } from "../../../helper/media";
import PopupGallery from "../../media/popup-gallery";
import galleryApi from "../../../apis/api/gallery";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import socket from "../../../helper/socket";
import {
  splitToChunksOfLengthN,
  sortMediasByPlaylistOverride,
} from "../../../helper/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MEDIA_TYPE } from "../../../pages/cms/utils";
import icVideo from "../../../assets/images/cms/icVideo.webp";
import icImage from "../../../assets/images/cms/icImg.webp";

export const Amenities = ({ isPresentation, item }) => {
  const [data, setData] = useState([]);
  const [showGallery, setShowGallery] = useState(null);
  const mediaLayout = splitToChunksOfLengthN(data, 2);

  const loadData = async () => {
    try {
      const res = await galleryApi.getGalleryDetail(item?.id);
      if (res && res.data) {
        const content = res.data?.playlist?.medias.filter(
          (i) => i.type === item?.props.type
        );

        const medias = sortMediasByPlaylistOverride(
          content,
          res.data?.playlistOverride
        );

        setData(medias);
      }
    } catch (e) {
      console.log("error get Amenities");
    }
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_GALLERY) {
      handleShowGallery(content.data.idx);
    }
    if (content.action === ACTION_NAME.CLOSE_GALLERY) {
      handleCloseGallery();
    }
  };

  useEffect(() => {
    loadData();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation, item?.id]);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY, {
        idx,
      });
    }
    setShowGallery(idx);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY);
    }
    setShowGallery(false);
  };

  const getCssMediaItem = (index, mediaIndex) => {
    if (index % 2 === 0 && mediaIndex % 2 === 0) {
      return "w-[518px] h-[363px]";
    }
    if (index % 2 === 0 && mediaIndex % 2 === 1) {
      return "h-[363px] max-w-[627px] flex-[1_0_0]";
    }
    if (index % 2 === 1 && mediaIndex % 2 === 0) {
      return "w-[765px] h-[465px]";
    }
    if (index % 2 === 1 && mediaIndex % 2 === 1) {
      return "w-[378px] h-[465px]";
    }
  };

  return (
    <div className="amenities mx-[108px] my-[100px]">
      <div className="flex flex-col items-center gap-[5px]">
        {mediaLayout?.map((mediaRow, index) => (
          <div
            className={
              "flex flex-row gap-[5px] justify-center items-center w-[1150px]"
            }
            key={index}
          >
            {mediaRow?.map((media, mediaIndex) => {
              return (
                <div
                  key={media.id}
                  onClick={() => handleShowGallery(media?.path)}
                  className={getCssMediaItem(index, mediaIndex)}
                >
                  <LazyLoadImage
                    className="h-full object-cover w-full cursor-pointer"
                    alt={media?.name}
                    src={getS3BEMediaUrl(media?.thumbnailPath || media?.path)}
                    onError={(e) => {
                      if (
                        e.currentTarget.src ===
                        getS3BEMediaUrl(media.thumbnailPath)
                      ) {
                        e.currentTarget.src = getS3BEMediaUrl(media.path);
                      } else {
                        e.currentTarget.src =
                          media?.type === MEDIA_TYPE.VIDEO ? icVideo : icImage;
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <PopupGallery
        isPresentation={isPresentation}
        show={showGallery}
        listImage={data}
        onClose={handleCloseGallery}
      />
    </div>
  );
};
