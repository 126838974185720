/** @format */

import { toast } from "react-toastify";
import React, { useState } from "react";
import { Modal } from "../../../../components/commons";
import mediaAPI from "../../../../../../apis/api/media";
import uploadIcon from "../../../../../../assets/images/upload.svg";
import closeIcon from "../../../../../../assets/images/close-white.svg";

export const EditModelMediaModal = ({
  show,
  setShow,
  content,
  onSaveSuccess,
  handleDelete,
}) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [isShowLoading, setShowLoading] = useState(false);
  const [mediaName, setMediaName] = useState(content?.name);
  const [isShowDeleteContentModal, setIsShowDeleteContentModal] = useState(false);

  const handleCloseDeleteContentModal = () => {
    setIsShowDeleteContentModal(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (!droppedFiles[0].type.startsWith("model/gltf")) {
      return setErrors({ ...errors, file: "Invalid file type" });
    } else {
      setErrors({ ...errors, file: "" });
      return setFiles(droppedFiles);
    }
  };

  const handleDeleteContent = async () => {
    if (!content) return;
    try {
      const deleted = await mediaAPI.deleteMedia(content.id);
      if (deleted.data) {
        toast.success("Content deleted successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleClose = () => {
    setShow(false);
  };

  const onSave = async () => {
    if (validateForm()) {
      return;
    }

    setShowLoading(true);
    try {
      const params = {
        name: mediaName,
      };

      if (files.length > 0) {
        const formData = new FormData();
        formData.append("file", files[0]);

        const uploaded = await mediaAPI.uploadMediaModel(formData);
        if (uploaded?.message) {
          throw new Error(uploaded.message);
        }

        if (!uploaded?.data?.path) {
          throw new Error("Unable to upload file");
        }
        params.path = uploaded.data.path;
        params.fileName = uploaded.data.fileName;
      }

      const updated = await mediaAPI.updateMedia(content.id, params);

      if (updated?.data) {
        handleClose();
        toast.success("Content updated successfully!");
        onSaveSuccess && onSaveSuccess(updated?.data);
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
        handleClose();
      } else {
        toast.error(err.message)
      }
    } finally {
      setShowLoading(false);
    }
  };

  const validateForm = () => {
    let hasErrors = Object.values(errors).find((error) => error);
    if (!mediaName) {
      setErrors({ ...errors, mediaName: "Media name is required" });
      hasErrors = true;
    }

    return !!hasErrors;
  };

  const onChangeMediaName = (value) => {
    if (!value) {
      setErrors({ ...errors, mediaName: "Media name is required" });
    } else {
      setErrors({ ...errors, mediaName: undefined });
    }

    setMediaName(value);
  };

  if (isShowDeleteContentModal) {
    return (
      <Modal
        iconCancelMark={
          <div className="z-[10000] cursor-pointer fixed top-[50px] right-[80px]">
            <img
              src={closeIcon}
              alt="close-icon"
              onClick={handleCloseDeleteContentModal}
            />
          </div>
        }
        wrapperClassName="wrap-delete-content-modal"
        panelClassName="w-[521px] p-0 rounded-none"
        maskClassName="opacity-90"
        zIndex="z-[9998]"
        show={isShowDeleteContentModal}
        setShow={handleCloseDeleteContentModal}
        content={
          <div className="p-[40px] space-y-5">
            <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
              Delete Content?
            </h2>
            <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
              <div className="font-proxima text-[14px] text-[#000] font-[400] leading-[17.5px]">
                Are you sure you want to delete {content?.name}?
              </div>
            </div>
            <div className="mt-5">
              <div
                onClick={handleDeleteContent}
                className="px-[18px] py-[12px] text-center  text-[#E11736] cursor-pointer font-[700] font-poppins text-[14px] border-[1px] border-solid border-[#E11736] uppercase w-full"
              >
                YES, DELETE
              </div>
            </div>
          </div>
        }
      />
    );
  }

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      isLoading={isShowLoading}
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[698px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[90vh] overflow-y-scroll">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Model name *
            </label>
            <div className="mt-2 mb-4">
              <input
                onChange={(e) => onChangeMediaName(e.target.value)}
                value={mediaName}
                id="meida_name"
                name="meida_name"
                placeholder="Media name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <span className="text-red-500">{errors.mediaName}</span>
            </div>
          </div>
          {/* <div
            className={`min-h-[330px] relative flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${
              isDragging ? "bg-gray-200" : "bg-white"
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              multiple
              className="hidden"
              id="file-upload"
              onChange={handleFileSelect}
              accept={".glb,.gltf"}
            />
            <label htmlFor="file-upload" className="cursor-pointer">
              <div className="text-center flex flex-col items-center">
                <p className="text-gray-600">
                  Drag & drop your files here or click to upload
                </p>
                <img
                  src={uploadIcon}
                  alt="Upload"
                  className="w-20 h-20 relative"
                />
                {files.length > 0 && (
                  <ul className="mt-4">
                    {files.map((file, index) => (
                      <li key={index} className="text-sm text-gray-800">
                        {file.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </label>
          </div> */}
          <div className="h-16" />
          <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
            {errors?.file}
          </span>
          <div className="flex justify-between mt-2 items-center">
            <div>
              <button
                onClick={() => setIsShowDeleteContentModal(true)}
                className="font-[700] text-[#E11736]/80 text-sm font-poppins mr-4 underline"
              >
                DELETE
              </button>
            </div>
            <div>
              <button
                onClick={handleClose}
                className="font-[700] text-black/80 font-poppins text-sm mr-4"
              >
                CANCEL
              </button>
              <span
                className="border-solid border-2 font-poppins font-[700] border-black text-center cursor-pointer m-0 text-[14px] px-[18px] py-[12px]"
                onClick={onSave}
              >
                SAVE
              </span>
            </div>
          </div>
        </div>
      }
    />
  );
};
