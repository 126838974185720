import React from "react";
import _ from "lodash";

import { Modal } from "../../../components/commons";
import LinkForm from "./CreatingHotspotForm/LinkForm";
import InfoForm from "./CreatingHotspotForm/InfoForm";
import EditIcon from "../../../configuration/components/svgs/EditIcon";
import UpdateLinkForm from "./UpdatingHotspotForm/LinkForm";
import TrashIcon from "../../../configuration/components/svgs/TrashIcon";
import UpdateInfoForm from "./UpdatingHotspotForm/InfoForm";

const HotspotList = ({ virtualTourData, setVirtualTourData, sceneIndex }) => {
  const currentScene = virtualTourData?.scenes[sceneIndex];

  const handleAddHotspot = (values) => {
    const { type, title, description, targetScene, linkOutSide } = values;

    //type: link or info
    const isLinkHotspot = type === "link";
    const lengthHotspots = isLinkHotspot
      ? currentScene.linkHotspots.length
      : currentScene.infoHotspots.length;

    if (!currentScene) return;

    const newHotspot = _.omitBy(
      {
        id: `hotspot-${lengthHotspots}`,
        yaw: 0,
        pitch: 0,
        type,
        text: `Hotspot ${type} ${lengthHotspots + 1}`,
        targetScene: targetScene,
        linkOutSide: isLinkHotspot ? linkOutSide : "",
        description: isLinkHotspot ? "" : description,
        title: !isLinkHotspot ? title : "",
      },
      _.isNil
    );

    setVirtualTourData(() => {
      const newScenes = [...virtualTourData.scenes];

      newScenes[sceneIndex] = {
        ...currentScene,
        linkHotspots:
          type === "link"
            ? [...currentScene.linkHotspots, newHotspot]
            : currentScene.linkHotspots,
        infoHotspots:
          type === "info"
            ? [...currentScene.infoHotspots, newHotspot]
            : currentScene.infoHotspots,
      };

      localStorage.setItem(
        "virtual-tour",
        JSON.stringify({ ...virtualTourData, scenes: newScenes })
      );
      return {
        ...virtualTourData,
        scenes: newScenes,
      };
    });
  };

  const handleUpdateHotspot = (hotspotIndex, values) => {
    const { title, description, targetScene, linkOutSide, type } = values;

    setVirtualTourData((prevData) => {
      const newScenes = [...prevData.scenes];
      const currentScene = { ...newScenes[sceneIndex] };

      if (type === "link") {
        const updatedLinkHotspots = currentScene.linkHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex
              ? _.omitBy(
                  {
                    ...hotspot,
                    title,
                    targetScene,
                    linkOutSide,
                  },
                  _.isNil
                )
              : hotspot
        );
        currentScene.linkHotspots = updatedLinkHotspots;
      } else if (type === "info") {
        const updatedInfoHotspots = currentScene.infoHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex
              ? _.omitBy(
                  {
                    ...hotspot,
                    title,
                    description,
                  },
                  _.isNil
                )
              : hotspot
        );
        currentScene.infoHotspots = updatedInfoHotspots;
      }

      newScenes[sceneIndex] = currentScene;

      localStorage.setItem(
        "virtual-tour",
        JSON.stringify({ ...prevData, scenes: newScenes })
      );

      return {
        ...prevData,
        scenes: newScenes,
      };
    });
  };

  const handleRemoveHotspot = (hotspotIndex, type) => {
    setVirtualTourData((prevData) => {
      const newScenes = [...prevData.scenes];
      const currentScene = { ...newScenes[sceneIndex] };

      if (type === "link") {
        const newLinkHotspots = currentScene.linkHotspots.filter(
          (_, index) => index !== hotspotIndex
        );
        currentScene.linkHotspots = newLinkHotspots;
      } else if (type === "info") {
        const newInfoHotspots = currentScene.infoHotspots.filter(
          (_, index) => index !== hotspotIndex
        );
        currentScene.infoHotspots = newInfoHotspots;
      }

      newScenes[sceneIndex] = currentScene;

      localStorage.setItem(
        "virtual-tour",
        JSON.stringify({ ...prevData, scenes: newScenes })
      );

      return {
        ...prevData,
        scenes: newScenes,
      };
    });
  };

  return (
    <div className="mt-4">
      <h3 className="font-medium mb-2">Hotspots</h3>
      <div className="flex gap-2">
        <Modal
          maskClassName="bg-opacity-50"
          trigger={
            <button className="mb-4 px-4 text-xs flex-1 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
              Link Hotspot
            </button>
          }
          content={({ setIsShow }) => (
            <LinkForm
              setIsShow={setIsShow}
              onCreateHotspot={handleAddHotspot}
              scenes={virtualTourData?.scenes?.filter(
                (item) => item.id !== currentScene.id
              )}
            />
          )}
        />
        <Modal
          maskClassName="bg-opacity-50"
          trigger={
            <button className="mb-4 px-4 text-xs flex-1 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors">
              Info Hotspot
            </button>
          }
          content={({ setIsShow }) => (
            <InfoForm
              setIsShow={setIsShow}
              onCreateHotspot={handleAddHotspot}
            />
          )}
        />
      </div>

      <div className="space-y-2">
        {currentScene.linkHotspots.map((hotspot, index) => (
          <div key={index} className="p-2 bg-white rounded shadow">
            <div className="flex justify-between items-center mb-2">
              <span className="font-medium">{hotspot.text}</span>
              <div className="flex gap-2 items-center">
                <Modal
                  maskClassName="bg-opacity-50"
                  trigger={
                    <span className="cursor-pointer text-blue-500">
                      <EditIcon />
                    </span>
                  }
                  content={({ setIsShow }) => (
                    <UpdateLinkForm
                      setIsShow={setIsShow}
                      hotspot={hotspot}
                      onUpdateHotspot={(values) =>
                        handleUpdateHotspot(index, values)
                      }
                      scenes={virtualTourData?.scenes?.filter(
                        (item) => item.id !== currentScene.id
                      )}
                    />
                  )}
                />
                <button
                  onClick={() => handleRemoveHotspot(index, hotspot.type)}
                  className="text-red-500 hover:text-red-700"
                >
                  <TrashIcon />
                </button>
              </div>
            </div>

            <div className="text-sm text-gray-500">
              {hotspot?.targetScene && (
                <div>Target Link: {hotspot.targetScene}</div>
              )}
            </div>
          </div>
        ))}
        {currentScene.infoHotspots.map((hotspot, index) => (
          <div key={index} className="p-2 bg-white rounded shadow">
            <div className="flex justify-between items-center mb-2">
              <span className="font-medium">{hotspot.text}</span>
              <div className="flex gap-2 items-center">
                <Modal
                  maskClassName="bg-opacity-50"
                  trigger={
                    <span className="cursor-pointer text-blue-500">
                      <EditIcon />
                    </span>
                  }
                  content={({ setIsShow }) => (
                    <UpdateInfoForm
                      onUpdateHotspot={(values) =>
                        handleUpdateHotspot(index, values)
                      }
                      setIsShow={setIsShow}
                      hotspot={hotspot}
                    />
                  )}
                />
                <button
                  onClick={() => handleRemoveHotspot(index, hotspot.type)}
                  className="text-red-500 hover:text-red-700"
                >
                  <TrashIcon />
                </button>
              </div>
            </div>

            <div className="text-sm text-gray-500">
              {hotspot?.description && (
                <div className="line-clamp-2">
                  Description: {hotspot?.description}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HotspotList;
