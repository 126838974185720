import { MEDIA_TYPE } from "../utils";
import { getS3BEMediaUrl } from "../../../helper/media";
import icImg from "../../../assets/images/cms/icImg.webp";

import _ from "lodash";

export const thCss =
  "text-left border whitespace-nowrap overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

export const processStringTabName = (input) => {
  return _.chain(input)
    .replace("gallery_", "") // Remove 'gallery_'
    .replace(/_/g, "") // Remove all underscores
    .value(); // Return the resulting string
};

export const getSrc = (type, path) => {
  const src = {
    [MEDIA_TYPE.IMAGE]: getS3BEMediaUrl(path),
    [MEDIA_TYPE.FLOORPLANS]: getS3BEMediaUrl(path),
    [MEDIA_TYPE["360IMAGE"]]: getS3BEMediaUrl(path),
    [MEDIA_TYPE.VIRTUALTOURS]: getS3BEMediaUrl(path),
    [MEDIA_TYPE.VIEWLINES]: getS3BEMediaUrl(path),
    [MEDIA_TYPE.MODELS]: getS3BEMediaUrl(path),
    [MEDIA_TYPE.PARONAMIC]: getS3BEMediaUrl(path),
    [MEDIA_TYPE.VIDEO]: getS3BEMediaUrl(path),
  };

  return src[type] || icImg;
};

export const getTypePlayList = (typeGallery) => {
  const type = {
    [MEDIA_TYPE["360IMAGE"]]: "360image",
    [MEDIA_TYPE.FLOORPLANS]: "floorplans",
    [MEDIA_TYPE.MODELS]: "models",
    [MEDIA_TYPE.PARONAMIC]: "paronamics",
    [MEDIA_TYPE.VIDEO]: "videos",
    [MEDIA_TYPE.VIRTUALTOURS]: "virtualTours",
    [MEDIA_TYPE.VIEWLINES]: "viewLines",
  };

  return type[typeGallery] || MEDIA_TYPE.IMAGE;
};

export const getValuesAfterUnderscore = (str) => {
  return _(str).split("_").tail().join("");
};

export const sortMediasByPlaylistOverride = (medias, playlistOverride) => {
  if (!playlistOverride || playlistOverride.length === 0) {
    return medias;
  }

  const overrideIndexMap = new Map(
    playlistOverride.map((id, index) => [id, index])
  );

  const sortedMedias = [...medias];

  sortedMedias.sort((a, b) => {
    const indexA = overrideIndexMap.has(a.id)
      ? overrideIndexMap.get(a.id)
      : Infinity;
    const indexB = overrideIndexMap.has(b.id)
      ? overrideIndexMap.get(b.id)
      : Infinity;

    if (indexA === indexB) {
      return medias.indexOf(a) - medias.indexOf(b);
    }

    return indexA - indexB;
  });

  return sortedMedias;
};
