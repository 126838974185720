import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import canvasProfileApi from "../../../../../../apis/api/canvas-profiles";

export const ColorInput = ({
  canvasProfile,
  onSuccess = () => {},
  disabled = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState();

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(canvasProfile?.canvasBackground);
  }, [canvasProfile])

  const onEditVariable = async () => {
    try {
        const res = await canvasProfileApi.updateCanvasProfile(
          canvasProfile?.id,
          {
            canvasBackground: value,
          },
        );
        toast.success(`Canvas background changed to ${value}`)
        if (res && res.data) {
            onSuccess && onSuccess()
            setIsEditing(false)
        }
    } catch (error) {
        toast.error('Update canvas background failed')
    }
  }


  const handleBlur = () => {
    setValue(canvasProfile?.canvasBackground);
    setIsEditing(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault(); 
  };

  return (
    <div className="flex justify-between border-t border-b border-gray-300">
      <div className="flex items-center w-[30%] p-4 border-r border-gray-300">
        <span>Color</span>
      </div>
      <div className="flex items-center w-[70%] pl-4">
        <input
          type="color"
          value={value}
          onChange={handleInputChange}
          className="w-8 h-8 cursor-pointer disabled:cursor-not-allowed"
          disabled={disabled}
        />
        <div className="ml-2">
          <span>{String(value)}</span>
        </div>
        {value !== canvasProfile?.canvasBackground && <>
          <button onMouseDown={handleMouseDown} type="button" onClick={onEditVariable} className="ml-4 text-gray-500 text-sm">Save</button>
          <button onMouseDown={handleMouseDown} type="button" onClick={handleBlur} className="ml-4 text-gray-500 text-sm">Cancel</button>
        </>}
      </div>
    </div>
  );
};

export default ColorInput;
