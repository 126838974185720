/** @format */

import React, { useEffect, useRef, useState } from "react";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { getS3BEMediaUrl } from "../../../helper/media";
import socket from "../../../helper/socket";
import Fancybox from "../../home-gallery/fancy-box";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import { IconCloseButton, LeftArrowIcon, RightArrowIcon } from "../../svgs/icons";
import classNames from "classnames";

const PanaromaGallery = ({ data, isInfinity, isPresentation }) => {
  const pageRef = useRef(null);
  const [isShowFancyBox, setIsShowFancyBox] = useState(null);

  const onNextImage = () => {
    NativeFancybox.getInstance().next();
  };

  const onPrevImage = () => {
    NativeFancybox.getInstance().prev();
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.SHOW_360_GALLERY) {
          return onShowFancyBox(content.data.item);
        }

        if (content.action == ACTION_NAME.CLOSE_360_GALLERY) {
          return onCloseFancyBox();
        }

        if (content.action == ACTION_NAME.JUMP_TO_RENDER_360) {
          return onJumpToImage(content.data.index);
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL_360) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight;
          }
        }
      });
    }
  }, [isPresentation]);

  const onJumpToImage = (index) => {
    NativeFancybox.getInstance().jumpTo(index);
  };

  const onCloseFancyBox = () => {
    NativeFancybox.close();
  };

  const mediaData = data || [];

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL_360, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  const onShowFancyBox = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_360_GALLERY, {
        item,
      });
    }
    const el = document.getElementById(`im-${item?.id}`);
    if (el) {
      el.click();
      setIsShowFancyBox(`im-${item?.id}`);
    }
  };

  return (
    <div className="overflow-y-auto h-full">
      <div className="grid grid-cols-3 gap-[15px]">
        {mediaData?.map((item, index) => {
          return (
            <div
              key={`renders-${index}`}
              className={"renders-content-item h-[240px] w-full"}
            >
              <Fancybox
                key={`Fancybox-${index}`}
                options={{
                  infinite: true,
                  mainClass: "pure-fancy-box z-fancybox-9999",
                  showNavArrows: false,
                  on: {
                    "Carousel.change": (fancybox) => {
                      const el = fancybox.getSlide();
                      if (!isPresentation) {
                        socket.emitUIActionEvent(
                          ACTION_NAME.JUMP_TO_RENDER_360,
                          {
                            index: el.index,
                          }
                        );
                      }
                      setIsShowFancyBox(el.triggerEl.id);
                    },
                    destroy: () => {
                      if (!isPresentation) {
                        socket.emitUIActionEvent(ACTION_NAME.CLOSE_360_GALLERY);
                      }
                      setIsShowFancyBox(null)
                    },
                  },
                }}
              >
                <div
                  className={`gallery-item h-full card-renders relative ${
                    isInfinity ? "infinity" : ""
                  }`}
                >
                  <img
                    id={`im-${item?.id}`} // Update the ID to use globalIndex
                    onClick={() => onShowFancyBox(item)} // Pass globalIndex to onShowFancyBox
                    src={getS3BEMediaUrl(item.path)}
                    alt="normal-img"
                    data-fancybox="gallery"
                    className="object-cover h-full w-full"
                  />
                  <div className="absolute text-brand text-xs not-italic font-bold leading-[116%] tracking-[0.3px] uppercase left-[15px] bottom-[15px] max-w-[250px] whitespace-nowrap overflow-hidden text-ellipsis">
                    {item.name}
                  </div>
                </div>
              </Fancybox>
            </div>
          );
        })}
      </div>
      {!!isShowFancyBox && (
        <div className="fixed w-full top-0 left-0" style={{ zIndex: 10000 }}>
          <div className="wrap-btn-fancy">
            <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
              <LeftArrowIcon style={{ width: 21, height: 35 }} fill={'#C7A446'} />
            </div>
            <div className="wrap-slide-fancy">
              {mediaData?.map((item, index) => (
                <div
                  key={`slide-${index}`}
                  className={classNames('fancy-dot', `im-${item?.id}` === isShowFancyBox ? '' : 'fancy-dot-o')} />
              ))}
            </div>
            <div className="btn-next cursor-pointer" onClick={onNextImage}>
              <RightArrowIcon style={{ width: 21, height: 35 }} fill={'#C7A446'} />
            </div>
          </div>
          <div className="wrap-fancy-close">
            <div className="cursor-pointer" onClick={onCloseFancyBox}>
              <IconCloseButton style={{ width: 26 }} fill={'#C7A446'} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PanaromaGallery;
