import React, { useMemo } from "react";
import _ from "lodash";

import HeaderFeature from "./header-feature";
import CellFeature from "./cell-feature";
import ActionRow from "./action-row";
import ProjectLabelForm from "./project-label-form";
import { cn } from "../../../../helper/utils";

const useColumn = ({
  features,
  pageState,
  moduleState,
  setGalleryState,
  openLinkPlaylistModal,
}) => {
  const idModule = moduleState?.id;

  const isLayerModule = moduleState?.label?.includes("Layers");
  const isGalleryModule = pageState?.label?.includes("Gallery");
  const isBGalleyModule = pageState?.label?.includes("Gallery Page Type B");

  const getProjectLabel = (field) => {
    if (isLayerModule) return field?.props?.showLayers?.join(" or ");
    if (field?.props?.label && field?.props?.label !== "--")
      return <ProjectLabelForm field={field} />;

    return field?.props?.label;
  };

  const getFeature = () => {
    return features.map((feature) => ({
      header: (
        <div className="flex w-full items-center gap-4">
          {isGalleryModule || isLayerModule ? (
            <div>Visible</div>
          ) : (
            <HeaderFeature feature={feature} idModule={idModule} />
          )}
        </div>
      ),
      accessorKey: feature.name,
      meta: {
        className: "[&>div]:justify-center text-black bg-gray-300",
      },
      size: 100,
      cell: (props) => {
        return (
          <CellFeature
            feature={feature}
            idModule={idModule}
            rowComponent={props.row.original}
          />
        );
      },
    }));
  };

  const columns = [
    {
      header: isGalleryModule ? "SchemaCollectionField" : "Component",
      accessorKey: "name",
      meta: {
        className: "text-black bg-gray-300",
      },
      size: 120,
      cell: (props) => {
        const {
          props: { namePlaylist, playlistId, type, playlist },
          id: galleryId,
        } = props?.row?.original;

        return (
          <div
            className={cn({
              "flex justify-between items-center flex-wrap": isGalleryModule,
            })}
          >
            <p className="bg-gray-200 text-black font-semibold">
              {isGalleryModule
                ? _.upperFirst(_.camelCase(props.getValue()))
                : props.getValue()}
            </p>
            {isGalleryModule && (
              <p
                className="underline cursor-pointer"
                onClick={() => {
                  setGalleryState({
                    namePlaylist: namePlaylist || playlist?.name,
                    playlistId: playlistId || playlist?.id,
                    type,
                    galleryId,
                  });
                  openLinkPlaylistModal();
                }}
              >
                Link playlist
              </p>
            )}
          </div>
        );
      },
    },
    {
      header: isLayerModule ? "Layers" : "Project label",
      accessorKey: "props.label",
      meta: {
        className: "text-black bg-gray-300",
      },
      size: 100,
      cell: ({ row }) => {
        const field = row?.original;

        return getProjectLabel(field);
      },
    },
    ...getFeature(),
  ];

  const addOrderColumn = useMemo(() => {
    if (!isBGalleyModule) return columns;
    return columns?.concat({
      header: "Order",
      accessorKey: "props.order",
      sortDescFirst: false,
      meta: {
        className: "text-black [&>div]:justify-center bg-gray-300",
      },
      size: 100,
      cell: (props) => {
        return <p className="text-center">{props.getValue()}</p>;
      },
    });
  }, [isBGalleyModule, getFeature]);

  const actionColumn = useMemo(() => {
    if (!isBGalleyModule) return addOrderColumn;
    return addOrderColumn?.concat({
      header: "",
      meta: {
        className: "text-black bg-gray-300",
      },
      accessorKey: "action",
      cell: (props) => {
        return <ActionRow field={props.row.original} />;
      },
    });
  }, [isBGalleyModule, getFeature]);

  return { columns: actionColumn };
};

export default useColumn;
