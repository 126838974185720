/** @format */

import React, { Fragment } from "react";
import { UnitDetailsArrowDown, UnitDetailsArrowUp } from "../svgs/icons";

const AccordionSidebar = ({ data, activeKeys, onToggle, id }) => {
  return (
    <div className="py-2">
      {data.map((section, key) => (
        <Fragment key={"list-group-" + key}>
          <button
            onClick={() => onToggle(activeKeys, id)}
            className="flex justify-between w-full text-primary items-center"
          >
            <span>{section.title}</span>
            <div className="">
              {activeKeys.includes(id) ? (
                <div
                  className={`transform origin-center transition duration-500 ease-out ${
                    activeKeys.includes(id) && "!rotate-180"
                  }`}
                >
                  <UnitDetailsArrowUp className="*:fill-[var(--primary-color)]" />
                </div>
              ) : (
                <div
                  className={`transform origin-center rotate-180 transition duration-500 ease-out ${
                    activeKeys.includes(id) && "!rotate-180"
                  }`}
                >
                  <UnitDetailsArrowDown className="*:fill-[var(--primary-color)]" />
                </div>
              )}
            </div>
          </button>
          <div
            className={`grid overflow-hidden transition-all duration-300 ease-in-out text-sm ${
              activeKeys.includes(id)
                ? "grid-rows-[1fr] opacity-100"
                : "grid-rows-[0fr] opacity-0"
            }`}
          >
            <div className="overflow-hidden">
              <ul className="list-group !p-0 gap-[36px]">
                {(section?.items || []).map((item, key2) => (
                  <li
                    key={"list-group-item-" + key2}
                    className="list-group-item p-0 !border-t-[1px] !border-t-brand-secondary pb-3"
                  >
                    <div className="list-group-item-title flex justify-center gap-[14px] py-[15px] text-base leading-none font-normal">
                      <div className="w-[8px] h-[8px] rounded-[4px] bg-primary mt-[2px]" />
                      <span className="me-auto text-primary">{item.title}</span>
                    </div>
                    <div className="list-group-item-body text-sm font-normal leading-none text-secondary">
                      {item.value}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};
export default AccordionSidebar;
