/** @format */

import React, { useEffect, useRef } from "react";
import { getS3BEMediaUrl } from "../../../helper/media";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import socket from "../../../helper/socket";

const PlayVideo = ({
  urlBg,
  urlVideo,
  onStopVideo,
  isPresentation,
  setShowVideo,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      videoRef.current.play();
    }
  }, [isPresentation]);

  const onSkipVideo = () => {
    onStopVideo();
    setShowVideo(false);
    if (!isPresentation) {
      const messageId = Date.now();
      socket.shareMedia({ FG: { FG_0000: "MOV", MSG_ID: messageId } });
    }
  };

  return (
    <div className="fixed z-[1200] top-0 left-0 bg-black h-[100dvh] w-[100dvw]">
      <div className="flex items-center justify-center h-full">
        <video
          loop
          ref={videoRef}
          autoPlay={true}
          muted
          preload="auto"
          id="intro-video-2"
          className="max-h-full max-w-full"
        >
          <source src={getS3BEMediaUrl(urlVideo)} type="video/mp4" />
        </video>
        <div
          className="fixed cursor-pointer right-[45px] top-[80px]"
          onClick={onSkipVideo}
        >
          <Close fill="white" />
        </div>
      </div>
    </div>
  );
};

export default PlayVideo;
