import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getS3BEMediaUrl } from "../../helper/media";
import closeV2 from "../../assets/images/closeV2.svg";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useEffect, useRef } from "react";

const FloorplanModal = ({ floorplan, onClose, isPresentation }) => {
  const transformComponentRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN_SESSION) {
      if (!transformComponentRef.current) return;
      if (content.data.state) {
        const ratioHeight = window.screen.height / content.data.height;
        const ratioWidth = (window.screen.width * 0.7) / content.data.width;
        const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
        const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
        return transformComponentRef.current.setTransform(
          content.data.state.positionX * ratioWidth * marginWidth,
          content.data.state.positionY * ratioHeight * marginHeight,
          content.data.state.scale === 1
            ? 1
            : content.data.state.scale * (1 / marginWidth)
        );
      }
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(
        WEBSOCKET_CHANNEL.SHARE_UI_ACTION,
        listenerSharedUIAction
      );
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  });

  return (
    <TransformWrapper
      ref={transformComponentRef}
      maxScale={2.5}
      minPositionX={window.screen.width * 1.55 * -1}
      minPositionY={window.screen.height * 1.7 * -1}
      maxPositionX={window.screen.width * 1.55}
      maxPositionY={window.screen.height * 1.7}
      wheel={{
        step: 0.1,
      }}
      pinch={{
        step: 0.1,
      }}
      onTransformed={(ref, state) => {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN_SESSION, {
            state,
            height: window.screen.height,
            width: window.screen.width,
          });
        }
      }}
    >
      {() => (
        <>
          <div className="h-screen w-screen absolute top-0 left-0 text-center z-[9999] m-0 p-0">
            <img
              src={closeV2}
              className="absolute right-[2rem] top-[2rem] z-[1002] cursor-pointer"
              onClick={onClose}
            />
            {/*
            .react-transform-wrapper {
               height: 100% !important;
               width: 100vw !important;
                 .react-transform-component {
                     justify-content: center;
                     align-items: center;
                     background: rgba(0, 0, 0, 0.90);
             }*/}
            <TransformComponent
              contentStyle={{ height: "100%", width: "100vw" }}
              wrapperStyle={{
                height: "100%",
                width: "100vw",
                background: "black",
                zIndex: 100,
              }}
            >
              {floorplan ? (
                <img
                  src={getS3BEMediaUrl(floorplan)}
                  alt="floorplan"
                  className="w-full h-full object-contain"
                />
              ) : (
                <div className="h-full w-full !flex items-center justify-center">
                  Image Not Found
                </div>
              )}
            </TransformComponent>
          </div>
        </>
      )}
    </TransformWrapper>
  );
};

export default FloorplanModal;
