import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../components/commons";
import UploadImage from "./Upload";
import { getS3BEMediaUrl } from "../../../../helper/media";
import View from "./View";
import PanoramaList from "./PanoramaList";
import HotspotList from "./HospotList";
import { hasDataChanged } from "../utils";
import playListApi from "../../../../apis/api/playlist";
import { MEDIA_TYPE } from "../../utils";
import playlistApi from "../../../../apis/api/playlist";
import { reqSetExploreModal } from "../../../../reduxs/explore-modal/action";
import mediaApi from "../../../../apis/api/media";

const Setting = ({ setVirtualTourData, virtualTourData, dataResponse }) => {
  const [sceneIndex, setSceneIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { virtualId: playlistId, modalId } = useParams();

  const dispatch = useDispatch();

  const handleImageUpload = async (path) => {
    const newScene = {
      id: `scene-${virtualTourData.scenes.length}`,
      name: `Scene ${virtualTourData.scenes.length + 1}`,
      url: `${getS3BEMediaUrl(path)}`,
      levels: [
        { tileSize: 256, size: 256, fallbackOnly: true },
        { tileSize: 512, size: 512 },
        { tileSize: 512, size: 1024 },
        { tileSize: 512, size: 2048 },
        { tileSize: 512, size: 4096 },
      ],
      faceSize: 4096,
      initialViewParameters: {
        pitch: 0,
        yaw: 0,
        fov: Math.PI / 3,
      },
      linkHotspots: [],
      infoHotspots: [],
    };

    if (path) {
      let params = {
        isActive: true,
        path,
        type: MEDIA_TYPE.VIRTUALTOURS,
        name: newScene?.name,
        fileName: newScene?.name,
        thumbnailPath: path,
      };
      const createMediaRes = await playListApi.createMediaPlaylist(params);
      if (createMediaRes && createMediaRes?.data) {
        const addMediaToPlaylistRes = await playlistApi.addFilesToPlayList(
          playlistId,
          {
            medias: [createMediaRes?.data.id],
          }
        );

        if (addMediaToPlaylistRes?.data) {
          dispatch(reqSetExploreModal(modalId));
          toast.success("Add new virtual tour successfully!");
        }
      } else {
        toast.error("Add media failed!");
      }
    }
  };

  const handleUpdatePositionHotspot = (hotspotIndex, yaw, pitch, type) => {
    setVirtualTourData((prevData) => {
      const newScenes = [...prevData.scenes];
      const currentScene = { ...newScenes[sceneIndex] };

      if (type === "link") {
        const updatedLinkHotspots = currentScene.linkHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex ? { ...hotspot, yaw, pitch } : hotspot
        );
        currentScene.linkHotspots = updatedLinkHotspots;
      } else if (type === "info") {
        const updatedInfoHotspots = currentScene.infoHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex ? { ...hotspot, yaw, pitch } : hotspot
        );
        currentScene.infoHotspots = updatedInfoHotspots;
      }

      newScenes[sceneIndex] = currentScene;

      return {
        ...prevData,
        scenes: newScenes,
      };
    });
  };

  const onSave = async () => {
    if (virtualTourData?.scenes?.length) {
      setLoading(true);
      const updatePromises = virtualTourData.scenes.map((scene) =>
        mediaApi.updateMedia(scene?.id, {
          data: {
            ...scene,
            settings: virtualTourData?.settings,
          },
        })
      );

      const results = await Promise.allSettled(updatePromises);

      const hasFailures = results.some(
        (result) => result.status === "rejected"
      );

      if (hasFailures) {
        toast.error("Some scenes failed to update. Please try again.");
        setLoading(false);
      } else {
        toast.success("All scenes updated successfully!");
        setLoading(false);
        dispatch(reqSetExploreModal(modalId));
      }
    }
  };

  const currentScene = virtualTourData?.scenes[sceneIndex];

  return (
    <>
      <div className="flex gap-4 ">
        <div className="w-64 p-4 bg-gray-50 rounded-lg overflow-y-auto h-[calc(100vh-220px)]">
          <div className="flex justify-between mb-5 items-center">
            <div className="flex gap-5 items-center">
              <input
                type="checkbox"
                id="rotate-checkbox"
                className="rounded"
                hidden
                checked={virtualTourData.settings.autorotateEnabled}
                onChange={(e) =>
                  setVirtualTourData((prevState) => {
                    return {
                      ...prevState,
                      settings: {
                        ...prevState.settings,
                        autorotateEnabled: e.target.checked,
                      },
                    };
                  })
                }
              />
              <label htmlFor="rotate-checkbox" className="cursor-pointer">
                Rotate
              </label>
            </div>
            {!!virtualTourData?.scenes?.length && (
              <Button
                isLoading={loading}
                onClick={onSave}
                disabled={
                  !hasDataChanged(dataResponse, virtualTourData) || loading
                }
                className="px-4 py-2"
              >
                Save
              </Button>
            )}
          </div>
          <UploadImage onUpload={handleImageUpload} />
          <PanoramaList
            virtualTourData={virtualTourData}
            setVirtualTourData={setVirtualTourData}
            setSceneIndex={setSceneIndex}
            sceneIndex={sceneIndex}
          />
          {currentScene && (
            <HotspotList
              sceneIndex={sceneIndex}
              setVirtualTourData={setVirtualTourData}
              virtualTourData={virtualTourData}
            />
          )}
        </div>

        <div className="flex-1">
          {currentScene?.url && (
            <View
              currentScene={currentScene}
              onHotspotUpdate={handleUpdatePositionHotspot}
              settings={virtualTourData.settings}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Setting;
