import ApiClient from '../apiClient';
import {authFetcher, defaultFetcher} from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getMediaList = (data) => client.get(endPoint.MEDIA_GET_LIST, data);
const getMediaDetail = (mediaId, data) =>
  client.get(endPoint.MEDIA_GET_DETAIL.replace(':mediaId', mediaId), data);
const createMedia = (data) =>
  defaultClient.post(endPoint.MEDIA_GET_LIST, data);
const uploadMedia = (file) => new Promise((resolve, reject) => {
  const image = new Image();

  image.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    canvas.getContext('2d').drawImage(image, 0, 0);
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Failed to convert to webp"))
      }
      // Now we have a `blob` containing webp data
      const imageRegex = new RegExp(/\.(png|webp)$/, 'i');

      // Use the file rename trick to turn it back into a file
      const imageFile = new File([blob], file.name.replace(imageRegex, ".webp"), { type: blob.type });
      const formData = new FormData();
      formData.append("file", imageFile);
      resolve(defaultClient.uploadFormData(endPoint.MEDIA_UPLOAD, formData));

    }, 'image/webp');

  };
  image.src = URL.createObjectURL(file);

});
const uploadMediaVideo = (data) =>
  defaultClient.uploadFormData(endPoint.MEDIA_VIDEO_UPLOAD, data);
const uploadMediaModel = (data) =>
  defaultClient.uploadFormData(endPoint.MEDIA_MODEL_UPLOAD, data);
const updateMedia = (id, data) =>
  defaultClient.put(endPoint.MEDIA_UPDATE.replace(':id', id), data);
const deleteMedia = (id) =>
  defaultClient.delete(endPoint.MEDIA_DELETE.replace(':id', id));

export default {
  getMediaList,
  getMediaDetail,
  uploadMedia,
  uploadMediaVideo,
  uploadMediaModel,
  updateMedia,
  deleteMedia,
  createMedia,
};
