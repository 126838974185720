import { ToneMappingOptions } from "./CanvasBox";

export const guiAddSky = (gui, options, onChange = (e) => { }) => {
  const SkyGUI = gui.addFolder("Sky");
  SkyGUI.add(options, "turbidity", 0.0, 20.0, 0.1).onChange((e) => onChange(options));
  SkyGUI.add(options, "rayleigh", 0.0, 4, 0.001).onChange((e) => onChange(options));
  SkyGUI.add(options, "mieCoefficient", 0.0, 0.1, 0.001).onChange((e) => onChange(options));
  SkyGUI.add(options, "mieDirectionalG", 0.0, 1, 0.0001).onChange((e) => onChange(options));
  SkyGUI.add(options, "elevation", 0, 90, 0.1).onChange((e) => onChange(options));
  SkyGUI.add(options, "azimuth", -180, 180, 0.1).onChange((e) => onChange(options));
  SkyGUI.add(options, "exposure", 0, 1, 0.0001).onChange((e) => onChange(options));
  SkyGUI.add(options, "visible").onChange((e) => onChange(options));
}

export const guiAddLighting = (gui, options, onChange = (e) => { }) => {
  const LightingGUI = gui.addFolder("Lights");
  LightingGUI.add(options, "intensity", 0, 1000)
    .name("intensity")
    .onChange(onChange('intensity'));
  LightingGUI.addColor(options, "color")
    .name("color")
    .onChange(onChange('color'));
}

export const guiAddToneMapping = (gui, options, onChange = (e) => { }) => {
  const ToneMappingGUI = gui.addFolder("Tone Mapping");
  ToneMappingGUI.add(options, "toneMapping", Object.keys(ToneMappingOptions))
    .name("toneMapping")
    .onChange(onChange('toneMapping'));
  ToneMappingGUI.add(options, "exposure", 0, 2)
    .name("exposure")
    .onChange(onChange('exposure'));
}

export const guiAddBackground = (gui, options, onChange = (e) => { }) => {
  const BgGUI = gui.addFolder("Background");
  BgGUI.addColor(options, "color")
    .name("color")
    .onChange(onChange);
}

export const guiAddEnvironment = (gui, options, onChange = (e1, e2) => { }) => {
  const EnvironmentGUI = gui.addFolder("Environment");
  EnvironmentGUI.domElement.classList.add("environment-gui");
  EnvironmentGUI.add(options, "hasMap")
    .name("hasMap")
    .onChange(onChange('hasMap'));
  EnvironmentGUI.add(options, "intensity", 0, 1, 0.01)
    .name("intensity")
    .onChange(onChange('intensity'));
  EnvironmentGUI.addImage(options, 'envMapImg')
    .name("envMapImg")
    .onChange(onChange('envMap', options));
}

export const guiAddTheme = (gui, options, onChange = (e) => {}) => {
  const ThemeGUI = gui.addFolder("Theme");
  ThemeGUI.domElement.classList.add("theme-gui");
  Object.keys(options).map(option => {
    ThemeGUI.addColor(options, option)
      .name(option)
      .onChange((e) => onChange(option, e));
  })
};

export const guiAddMeshes = (gui, gltfModels, onChange = (e) => {}) => {
  const MeshesGUI = gui.addFolder("Meshes");
  MeshesGUI.domElement.classList.add("meshes-gui");
  gltfModels?.forEach((m) => {
    const modelMaterial = m.children?.[0]?.material;
    if (modelMaterial) {
      const options = {
        color: modelMaterial?.color
          ? `#${modelMaterial?.color?.getHexString()}`
          : "#ffffff",
        emissive: modelMaterial?.emissive
          ? `#${modelMaterial?.emissive?.getHexString()}`
          : "#000000",
        roughness: modelMaterial?.roughness || 1,
        metalness: modelMaterial?.metalness || 1,
        opacity: modelMaterial?.opacity || 1,
      };
      const MeshesFolder = MeshesGUI.addFolder(m.name);
      MeshesFolder.addColor(options, "color")
        .name("color")
        .onChange(onChange('color', m));
      MeshesFolder.addColor(options, "emissive")
        .name("emissive")
        .onChange(onChange('emissive', m));
      MeshesFolder.add(options, "roughness", 0, 1, 0.01)
        .name("roughness")
        .onChange(onChange('roughness', m));
      MeshesFolder.add(options, "metalness", 0, 1, 0.01)
        .name("metalness")
        .onChange(onChange('metalness', m));
      MeshesFolder.add(options, "opacity", 0, 1, 0.01)
        .name("opacity")
        .onChange(onChange('opacity', m));
    }
  });

}
