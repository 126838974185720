/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqSetIsTransparent } from "../../../reduxs/home/action";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import socket from "../../../helper/socket";
import * as unitExploreAct from "../../../reduxs/unit-explore/action";
import { Equirectangular } from "../../gallery-landing-type-a/components/Equirectangular";
import { getS3BEMediaUrl } from "../../../helper/media";
import { MEDIA_TYPE } from "../../../pages/cms/utils";
import { sortMediasByPlaylistOverride } from "../../../helper/utils";

const Gallery360 = ({ isPresentation, isShow360, isTransparent }) => {
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  const content = selectedUnit?.["360ImagePlaylist"]?.medias.filter(
    (i) => i.type === MEDIA_TYPE["360IMAGE"]
  );

  const medias =
    sortMediasByPlaylistOverride(
      content,
      selectedUnit?.["360ImagePlaylistOverride"]
    ) || [];

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SET_GALLERY_INDEX) {
      setIndex(content.data.index);
    }
    if (content.action === ACTION_NAME.CLOSE_UNIT_360) {
      handleCloseBtn();
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const dispatch = useDispatch();

  const handleCloseBtn = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_360);
    }
    dispatch(unitExploreAct.reqIsShow360(false));
    dispatch(reqSetIsTransparent(false));
  };

  function setIndex(index) {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SET_GALLERY_INDEX, {
        index,
      });
    }
    setCurrentMediaIndex(index);
  }

  return (
    <Equirectangular
      isPresentation={isPresentation}
      url={getS3BEMediaUrl(medias[currentMediaIndex]?.path)}
      onClose={handleCloseBtn}
      onNext={() =>
        currentMediaIndex === medias.length - 1
          ? setIndex(0)
          : setIndex(currentMediaIndex + 1)
      }
      onPrev={() =>
        currentMediaIndex === 0
          ? setIndex(medias.length - 1)
          : setIndex(currentMediaIndex - 1)
      }
      hideArrows={medias.length < 2}
    />
  );
};

export default React.memo(Gallery360);
