import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reqGetGallery } from "../../../../reduxs/gallery/action";
import UpsertGalleryPlaylist from "../upsertGalleryPlaylist";
import { MEDIA_TYPE } from "../../utils";

const GalleryTypeB = ({ typeBEntity }) => {
  const dispatch = useDispatch();
  const { gallery: galleryRes } = useSelector((state) => state.gallery);

  const getGallery = async (id) => {
    await dispatch(reqGetGallery(id));
  };

  useEffect(() => {
    typeBEntity?.galleryId && getGallery(typeBEntity?.galleryId);
  }, [typeBEntity]);

  return (
    <div>
      <div className="bg-white rounded-lg py-2 px-4">
        <div className="h-[calc(100vh-180px)] overflow-hidden">
          <UpsertGalleryPlaylist
            mediaType={typeBEntity?.type || MEDIA_TYPE.IMAGE}
            gallery={typeBEntity}
            playlistId={galleryRes?.playlist?.id}
          />
        </div>
      </div>
    </div>
  );
};

export default GalleryTypeB;
