import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODULES_ENUM, PAGES_ENUM } from '../../constants/modules';
import { reqGetAmenitiesList } from '../../reduxs/amenities/action';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';

const Amenities = ({ handleClickAmenity }) => {
  const dispatch = useDispatch();

  const amenities = useSelector((state) => state.amenities.data);
  const activeAmenity = useSelector((state) => state.amenities.activeAmenity);

  useEffect(() => {
    dispatch(reqGetAmenitiesList());
  }, []);

  const handleAmenityItemClick = (item) => {
    handleClickAmenity(item);
  };

  const clearSelection = () => {
    handleClickAmenity(null);
  }

  return (
    <div className='w-[225px] rounded-r-md px-[25px] py-[30px] bg-brand h-[calc(100vh_-_200px)] absolute top-[100px] flex flex-col'>
      <div className='p-0 mb-[30px]'>
        <h2 className='font-domaine tracking-[0.07rem] text-primary text-2xl font-normal leading-none uppercase'>
          {'AMENITIES'}
        </h2>
      </div>
      <div className='flex flex-col gap-3 py-3 border-t-[1px] border-t-brand-secondary'>
        {amenities.map((amenity) => {
          return (
            <div
              key={amenity.id}
              onClick={() => handleAmenityItemClick(amenity)}
              className='flex items-center cursor-pointer text-primary'
            >
              <div className={`${(activeAmenity?.id === amenity?.id) ? 'underline' : ''} leading-normal`}>
                {amenity?.name}
              </div>
              {
                (activeAmenity?.id === amenity?.id) &&
                <Arrow className='h-fit rotate-180 ml-auto !fill-[var(--primary-color)]' />
              }
            </div>
          );
        })}
      </div>
      {/* <div className='txt-bottom flex h-6 mt-auto cursor-pointer justify-center items-center' onClick={() => {}}>
        <span className='text-primary font-sans text-sm leading-normal tracking-[1.92px] uppercase'>
          {'JUMP TO AMENITIES'}
        </span>
        <Arrow className='ml-2' fill={'#000000'} />
      </div> */}
      <div className='txt-bottom flex h-6 mt-auto cursor-pointer justify-center items-center' onClick={clearSelection}>
        <span className='text-primary font-sans text-sm leading-normal tracking-[1.92px] uppercase'>
          {'CLEAR SELECTION'}
        </span>
        {/* <Arrow className='ml-2 text-primary' fill={'#000000'} /> */}
      </div>
    </div>
  );
};

Amenities.displayName = MODULES_ENUM.AMENITY_EXPLORE;
Amenities.pageGroup = PAGES_ENUM.INTERACTIVE_3D;

export default Amenities;
