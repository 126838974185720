import * as type from './type';

const initialState = {
  data: [],
  models: [],
  envmaps: [],
  metadata: {},
  obj: {},
  isCreate: false,
  isUpdate: false,
  loading: false,
  isDelete: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_MEDIA_LIST:
      return {
        ...state,
        data: action.data.data,
      };
    case type.GET_MODELS_MEDIA_LIST:
      return {
        ...state,
        models: action.data.data,
      };
    case type.GET_ENVMAPS_MEDIA_LIST:
      return {
        ...state,
        envmaps: action.data.data,
      };

    default:
      return state;
  }
};
