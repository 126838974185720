import * as THREE from 'three';

export const threePosition = (data) => {
  return new THREE.Vector3(data.x, data.z, -data.y);
};

export const threePosition2 = (data, vector) => {
  vector.x = data?.x;
  vector.y = data?.z;
  vector.z = -data?.y;
}

export const setColor = (color, alpha, object3d, emissive) => {
  const newColor = new THREE.Color(color != null ? color : '#999999');
  const newEmissive = new THREE.Color(emissive != null ? emissive : '#000000');
  const newAlpha = alpha != null ? alpha / 100.0 : 1.0;

  object3d.traverse(function (child) {
    if (child instanceof THREE.Material) {
      child.color = newColor;
      child.emissive = newEmissive;
    } else if (child.material != null) {
      if (child.material instanceof Array) {
        for (var i = 0; i < child.material.length; i++) {
          child.material[i].color = newColor;
          child.material[i].emissive = newEmissive;
        }
      } else {
        child.material.color = newColor;
        child.material.emissive = newEmissive;
      }
    }
  });

  object3d.userData.newAlpha = newAlpha;
};

export const setColor2 = (color, object3d) => {
  object3d.traverse(function (child) {
    if (child instanceof THREE.Material) {
      child.color.set(color);
    } else if (child.material != null) {
      if (child.material instanceof Array) {
        for (var i = 0; i < child.material.length; i++) {
          child.material[i].color.set(color);
        }
      } else {
        child.material.color.set(color);
      }
    }
  });
};

export const setAlpha = (alpha, object3d) => {
  object3d.traverse(function (child) {
    if (child instanceof THREE.Material) {
      child.opacity = alpha;
    } else if (child.material != null) {
      if (child.material instanceof Array) {
        for (var i = 0; i < child?.material?.length; i++) {
          child.material[i].opacity = alpha;
        }
      } else {
        child.material.opacity = alpha;
      }
    }
  });
}
