/** @format */

import { createPortal } from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { getS3FEMediaUrl, getS3BEMediaUrl } from "../../../helper/media";
import React, { useCallback, useEffect, useState } from "react";
import { Navigation } from "swiper/modules";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import closeIcon from "../../../assets/images/close-v3.svg";
import { MEDIA_TYPE } from "../../../pages/cms/utils";
import VideoPlayer from "../../gallery-landing-type-a/components/VideoPlayer";
import classNames from "classnames";

const PopupGallery = ({
  show,
  listImage,
  onClose,
  isPresentation,
  isClientImages = false,
  children,
  isShowViewLine = false,
  nameUnit = "",
}) => {
  const [swiper, setSwiper] = useState(null);
  const [currentIdx, setCurrentIdx] = useState(0);

  useEffect(() => {
    if (isPresentation && swiper) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation, swiper]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
      handleIndexChange(content.data.index);
    }
  };

  const handleIndexChange = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
        index,
      });
      setCurrentIdx(index);
    } else {
      if (swiper && swiper?.params) swiper.slideToLoop(index, 150);
    }
  };

  const handleNavigation = (direction) => {
    if (direction === "left") {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  const getPosition = useCallback(() => {
    try {
      const str = listImage?.[currentIdx]?.name || "Unit 206 East View.jpg";
      const split02 = str.split(".");
      const split03 = split02[0].split(" ");
      const lastVal = split03[4] || "";
      return split03[2] + " " + split03[3] + " " + lastVal;
    } catch (e) {
      console.log("error split name", e);
      return "";
    }
  }, [currentIdx, swiper]);

  return (
    <>
      {isShowViewLine && (
        <div className="absolute bg-brand z-[1000] w-full h-[49px] px-[40px] top-[49px] border-none">
          <div className=" flex justify-between items-center w-full h-full">
            <div className="unit-name text-primary">RESIDENCE {nameUnit}</div>
            <div className="flex items-center justify-center gap-[40px] h-full">
              {/* <div className="w-[217px] border-x-[1px] border-[#24242226] flex items-center h-full justify-center">
                <span className="font-suisse text-[12px] font-normal leading-[13.9px] text-left tracking-widest">
                  {getPosition()}
                </span>
              </div> */}
              <img
                onClick={onClose}
                src={closeIcon}
                className="close-icon-nav cursor-pointer"
              />
            </div>
          </div>
        </div>
      )}
      {show &&
        createPortal(
          <div
            className={`wrapper-popup-gallery media-popup-gallery fixed inset-0 bg-[black] z-[2000]`}
          >
            <div className="relative h-full w-full">
              <div
                className="absolute z-[1002] w-[24px] h-[24px] top-[60px] right-[50px] cursor-pointer"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                >
                  <path
                    d="M1 1L25 22.8182M25 1L1 22.8182"
                    stroke="white"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <Swiper
                onSwiper={(swiper) => setSwiper(swiper)}
                navigation={true}
                modules={[Navigation]}
                className="w-full h-full"
                initialSlide={listImage?.findIndex(
                  (item) => item.path === show
                )}
                loop={true}
                onRealIndexChange={(s) => handleIndexChange(s.realIndex)}
              >
                {listImage &&
                  listImage.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          key={"item-" + index}
                          className={classNames(
                            "flex items-center justify-center w-full h-full bg-transparent"
                          )}
                        >
                          {item?.type === MEDIA_TYPE.VIDEO ? (
                            <VideoPlayer videoUrl={item?.path} />
                          ) : (
                            <img
                              className="max-h-full max-w-full"
                              alt={item?.name}
                              src={
                                isClientImages
                                  ? getS3FEMediaUrl(item?.path)
                                  : getS3BEMediaUrl(item?.path)
                              }
                            />
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              {listImage.length > 1 ? (
                <div
                  className="cursor-pointer z-[1001] top-0 left-[81px]  absolute  h-full flex items-center justify-center p-0"
                  onClick={() => handleNavigation("left")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 80 80"
                    fill="none"
                    className="absolute"
                  >
                    <circle cx="40" cy="40" r="40" fill="#242422" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute"
                    width="16"
                    height="8"
                    viewBox="0 0 32 10"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1226_46867)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.207 5.50048H32V4.50048H2.207L5.354 1.35448L4.646 0.646484L0.646 4.64648L0.292999 5.00048L0.646 5.35448L4.646 9.35448L5.354 8.64648L2.207 5.50048Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1226_46867">
                        <rect
                          width="32"
                          height="10"
                          fill="white"
                          transform="matrix(-1 0 0 1 32 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              ) : null}

              {listImage.length > 1 ? (
                <div
                  className="cursor-pointer z-[1001] top-0 right-[81px] absolute h-full flex items-center justify-center p-0"
                  onClick={() => {
                    handleNavigation("right");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 80 80"
                    fill="none"
                    className="absolute"
                  >
                    <circle cx="40" cy="40" r="40" fill="#242422" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 32 10"
                    fill="none"
                    className="absolute"
                  >
                    <g clipPath="url(#clip0_1226_46863)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.793 5.50048H0V4.50048H29.793L26.646 1.35448L27.354 0.646484L31.354 4.64648L31.707 5.00048L31.354 5.35448L27.354 9.35448L26.646 8.64648L29.793 5.50048Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1226_46863">
                        <rect width="32" height="10" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              ) : null}
              {children}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
export default PopupGallery;
