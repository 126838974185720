/** @format */

import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getPlaylistList = (query) =>
  defaultClient.get(endPoint.PLAYLIST_GET_LIST, query);
const getPlaylistDetail = (id) =>
  defaultClient.get(endPoint.PLAYLIST_GET_DETAIL.replace(":playlistId", id));
const createPlaylist = (data) =>
  defaultClient.post(endPoint.PLAYLIST_CREATE, data);
const updateMediaPlaylist = (id, data) =>
  defaultClient.put(endPoint.PLAYLIST_UPDATE.replace(":playlistId", id), data);
const createMediaPlaylist = (data) =>
  defaultClient.post(endPoint.MEDIA_GET_LIST, data);
const deletePlaylist = (id) =>
  defaultClient.delete(endPoint.PLAYLIST_DELETE.replace(":playlistId", id));
const deleteMediaPlayList = (id) =>
  defaultClient.delete(endPoint.MEDIA_DELETE.replace(":id", id));
const addFilesToPlayList = (id, data) =>
  defaultClient.put(endPoint.PLAYLIST_FILES.replace(":playlistId", id), data);

//data: {
//   "medias": ["123"]
// }
const removeFilesFromPlayList = (id, data) =>
  defaultClient.put(endPoint.PLAYLIST_REMOVE.replace(":playlistId", id), data);

export default {
  getPlaylistList,
  getPlaylistDetail,
  createMediaPlaylist,
  createPlaylist,
  updateMediaPlaylist,
  deletePlaylist,
  deleteMediaPlayList,
  addFilesToPlayList,
  removeFilesFromPlayList,
};
