/** @format */

import Select from 'react-select';
import { toast } from "react-toastify";
import React, { useMemo } from "react";
import configApi from "../../apis/api/config";
import { CheckCircleSolid } from "../svgs/icons";
import { PAGES_ENUM } from "../../constants/modules";
import { reqSetPage } from "../../reduxs/home/action";
import { useDispatch, useSelector } from "react-redux";
import { REACTUI_PAGES } from "../../constants/options";
import { reqGetConfigs, actSetActiveConfig } from "../../reduxs/cms/action";
import { fetchHotspotsList, receiveGLTFModels, setIsLoading, setIsLoadingModel } from "../../reduxs/scene/action";

export const ShowcaseSelector = ({ resetState }) => {
  const dispatch = useDispatch();
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const configs = useSelector((state) => state.cms.configs);
  const pages = useSelector((state) => state.configuration.pages);

  const configOptions = useMemo(() => {
    return configs
      ?.map(conf => ({
        value: conf.id,
        label: (
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col gap-2">
              <div>
                <b>{conf?.name}</b>
              </div>
              <div className="ml-2 flex flex-col gap-1 opacity-70">
                {conf?.theme && <small>- Theme: {conf?.theme?.name}</small>}
                {conf?.canvasProfile && <small>- Canvas: {conf?.canvasProfile?.name}</small>}
              </div>
            </div>
            {
              conf?.id === activeConfig?.id &&
              <div><CheckCircleSolid className="!text-green-300" /></div>
            }
          </div>
        ),
      })) || [];
  }, [configs, activeConfig]);

  const selectConfig = async (id) => {
    try {
      const res = await configApi.updateConfig(id, { isActive: true });
      if (res && res.data) {
        toast.success("New config applied");
        dispatch(receiveGLTFModels([]));
        resetState();
        dispatch(reqGetConfigs());
        dispatch(actSetActiveConfig(null));
        dispatch(fetchHotspotsList());
        dispatch(setIsLoadingModel(false));
        dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
      }
    } catch (error) {
      console.log({ error });
      toast.error("Apply config failed");
      dispatch(setIsLoadingModel(false));
    }
  };

  if (!pages?.find(p => p.name === PAGES_ENUM.SHOWCASE && !!p?.props?.visible)) {
    return null;
  }

  return (
    <div className="flex flex-row gap-4 absolute left-1/2 bottom-0 -translate-x-1/2">
      {
        (activeConfig && configs?.length) &&
        <Select
          value={{ value: activeConfig?.id, label: activeConfig?.name, }}
          className="w-60 cursor-pointer !z-[100000]"
          classNames={{
            control: () => 'px-2 h-12 !bg-brand !rounded-b-none !rounded-t-lg !border-navigation !border-none !outline-none !shadow-xl !shadow-gray-600 !cursor-pointer',
            singleValue: () => '!text-primary',
            dropdownIndicator: () => '!rotate-180 !text-primary !cursor-pointer',
            indicatorSeparator: () => '!hidden',
            menu: () => '!rounded-md !bg-brand-secondary',
            option: (state) => `${(state.isFocused) ? '!bg-white/10' : '!bg-transparent'} !cursor-pointer !text-primary`
          }}
          isSearchable={false}
          isClearable={false}
          menuPlacement="auto"
          options={configOptions}
          onChange={(e) => {
            dispatch(setIsLoading(true));
            selectConfig(e.value);
          }}
        />
      }
    </div>
  )
};
