/** @format */

export const PAGES_ENUM = {
  SHOWCASE: "showcase_module",
  INTERACTIVE_3D: "interactive_3d_page",
  LOCATION: "location_page",
  GALLERY_LANDING_PAGE: "gallery_lading_page",
  NEIGHBORHOOD_PAGE: "neighborhood_page",
  PPG_PAGE: "team_page",
  PANORAMA_PAGE: "panorama_page",
  GALLERY_TYPE_B: "gallery_page_type_b",
  GALLERY_TYPE_A: "gallery_page_type_a",
  POST_SESSION: "post_session_page",
  STATIC_PAGE: "static_page",
};

export const MODULES_ENUM = {
  CANVAS_BOX: "canvas_box",
  UNIT_EXPLORE: "unit_explore",
  AMENITY_EXPLORE: "amenity_explore",
  TRANSPORT_OPTIONS: "transport_options",
  LOCATION: "location",
  GALLERY: "gallery",
  AMENITIES: "amenities",
  NEIGHBORHOOD: "neighborhood",
  TEAM: "team",
  POST_SESSION: "post_session_module",
};

export const FEATURES_ENUM = {
  UNIT_FILTERING: "unit_filtering",
  UNIT_DETAIL: "unit_detail",
  LANDING_PAGE_HOTSPOT: "landing_page_hotspot",
  RIGHT_PANEL: "right_panel",
  TRANSPORT_RIGHT_PANEL: "transport_right_panel",
  MAP_2D: "map_2d",
  LIST_POIS: "list_pois",
  DETAIL_POI: "detail_poi",
  PREFERENCE_DETAIL: "preference_detail",
};

export const COMPONENTS_ENUM = {
  // unit filters
  FILTER_STATUS: "filter_status",
  FILTER_PRICE: "filter_price",
  FILTER_SIZE: "filter_size",
  FILTER_SIZE_EXTERIOR: "filter_size_exterior",
  FILTER_SIZE_INTERIOR: "filter_size_interior",
  FILTER_BEDROOM: "filter_bedroom",
  FILTER_BATHROOM: "filter_bathroom",
  FILTER_POOL: "filter_pool",
  LIST_UNITS: "list_units",
  // unit detail
  DETAIL_STATUS: "detail_status",
  DETAIL_PRICE: "detail_price",
  DETAIL_SIZE: "detail_size",
  DETAIL_SIZE_EXTERIOR: "detail_size_exterior",
  DETAIL_SIZE_INTERIOR: "detail_size_interior",
  DETAIL_BEDROOM: "detail_bedroom",
  DETAIL_BATHROOM: "detail_bathroom",
  DETAIL_POOL: "detail_pool",
  UNIT_NAME: "unit_name",
  UNIT_IMAGE_GALLERY: "unit_image_gallery",
  UNIT_FLOORPLAN: "unit_floorplan",
  UNIT_360_GALLERY: "unit_360_gallery",
  UNIT_VIEWLINE_GALLERY: "unit_viewline_gallery",
  // 2d map
  MAP_POI_MARKER: "map_point_marker",
  LOCATION_GROUP: "location_group",
  DETAIL_LOCATION: "detail_location",
  // amenity explore
  AMENITY_NAME: "amenity_name",
  AMENITY_DESCRIPTION: "amenity_description",
  AMENITY_IMAGE: "amenity_image",
  VIRTUAL_TOUR_LINK: "virtual_tour_link",
  // transport explore
  TRANSPORT_NAME: "transport_name",
  TRANSPORT_DESCRIPTION: "transport_description",
  TRANSPORT_IMAGE: "transport_image",
  TRANSPORT_VIRTUAL_TOUR_LINK: "transport_virtual_tour_link",
  // post session
  PREFERENCE_NAME: "preference_name",
  PREFERENCE_STATUS: "preference_status",
  PREFERENCE_PRICE: "preference_price",
  PREFERENCE_SIZE: "preference_size",
  PREFERENCE_SIZE_EXTERIOR: "preference_size_exterior",
  PREFERENCE_SIZE_INTERIOR: "preference_size_interior",
  PREFERENCE_BEDROOM: "preference_bedroom",
  PREFERENCE_BATHROOM: "preference_bathroom",
  PREFERENCE_POOL: "preference_pool",
  PREFERENCE_GALLERY: "preference_gallery",
  PREFERENCE_FLOORPLAN: "preference_floorplan",
};

export const SUB_COMPONENTS_TYPES_ENUM = {
  CHECKBOX: "checkbox",
  RANGE: "range",
};

export const TYPE_STATIC_PAGE_ENUM = {
  template_2: "template-2",
  template_1: "template-1",
};

export default {
  PAGES_ENUM,
  MODULES_ENUM,
  FEATURES_ENUM,
  COMPONENTS_ENUM,
  TYPE_STATIC_PAGE_ENUM,
};
