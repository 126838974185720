/** @format */

import React from "react";

const SessionHeader = ({ customer, onSave, customerId }) => {
  return (
    <div className="header-session h-screen w-full relative flex items-center justify-center">
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${require("../../assets/images/image-post-session-0.png")})`,
        }}
        className="w-screen h-[100dvh] absolute flex items-center flex-col !bg-center !bg-cover !bg-no-repeat"
      />
      <span className="scroll-text-menu text-white text-center uppercase tracking-[1.2px] text-[12px] font-[400] leading-[1.2] opacity-60 absolute top-[102px] font-suisse">
        SCROLL TO EXPLORE
      </span>
      <div className="scroll-text-menu absolute text-white text-center text-[52px] font-[400] leading-[95%] font-domaine uppercase tracking-[-0.52px]">
        {customer?.name ? `${customer.name}'s` : "your"} collection of
        <br />
        one-of-a-kind water front
        <br />
        residences that has been
        <br />
        thoughtfully considered
        <br />
        during your experience with
        <br />
        the bay harbor towers
      </div>
      {!customerId && (
        <div
          className="text-white text-[14px] text-right font-[400] leading-[30px] tracking-[1.4px] uppercase bottom-[50px] absolute gap-[10px] flex border-b-[1px] border-white place-items-center font-suisse "
          onClick={onSave}
        >
          SAVE AND CLOSE
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="10"
            viewBox="0 0 33 10"
            fill="none"
          >
            <g clipPath="url(#clip0_1704_3784)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.293 5.50048H0.5V4.50048H30.293L27.146 1.35448L27.854 0.646484L31.854 4.64648L32.207 5.00048L31.854 5.35448L27.854 9.35448L27.146 8.64648L30.293 5.50048Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1704_3784">
                <rect
                  width="32"
                  height="10"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

export default SessionHeader;
