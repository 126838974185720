import {
  IS_INTRODUCTION,
  RECEIVE_3D_SETTINGS,
  RECEIVE_PAGES_SETTINGS,
  RECEIVE_HOTSPOTS_LIST,
  RECEIVE_ASSETS_LIST,
  RECEIVE_FBX_FILES,
  RECEIVE_HOTSPOT_TEXTURES,
  SET_IS_LOADING,
  SET_IS_LOADING_MODEL,
  RECEIVE_GLTF_MODELS,
  RECEIVE_GLTF_CAMERA_HELPERS,
  RECEIVE_ENV_MAP,
  MESH_LIST,
} from './type';

const initialState = {
  isLoading: true,
  isLoadingModel: false,
  isIntroduction: true,
  // isDragging3D: false,
  settings: {},
  pagesSettings: [],
  hotspots: [],
  assets: [],
  meshes: [],
  fbxs: [],
  // for GLTF loading
  gltfModels: [],
  gltfHelpers: [],
  envMap: null,
};

export default (state = initialState, action = {}) => {
  // console.log('action', action);
  switch (action.type) {
    // case IS_DRAGGING3D:
    //   return {
    //     ...state,
    //     isDragging3D: action.payload,
    //   };
    case IS_INTRODUCTION:
      return {
        ...state,
        isIntroduction: action.data,
      };
    case RECEIVE_3D_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case RECEIVE_PAGES_SETTINGS:
      return {
        ...state,
        pagesSettings: action.payload,
      };
    case RECEIVE_HOTSPOTS_LIST:
      return {
        ...state,
        hotspots: action.payload,
      };
    case RECEIVE_HOTSPOT_TEXTURES:
      return {
        ...state,
        hstexts: action.payload,
      };
    case RECEIVE_ASSETS_LIST:
      return {
        ...state,
        assets: action.payload,
      };
    case MESH_LIST:
      return {
        ...state,
        meshes: action.data.data,
      };
    case RECEIVE_FBX_FILES:
      return {
        ...state,
        fbxs: action.payload,
      };
    case RECEIVE_GLTF_MODELS:
      return {
        ...state,
        gltfModels: action.payload,
      };
    case RECEIVE_GLTF_CAMERA_HELPERS:
      return {
        ...state,
        gltfHelpers: action.payload,
      };
    case RECEIVE_ENV_MAP:
      return {
        ...state,
        envMap: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_IS_LOADING_MODEL:
      return {
        ...state,
        isLoadingModel: action.payload,
      };
    default:
      return state;
  }
};
